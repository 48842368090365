import { Act } from './Act';
import { ALevel } from './Alevel';
import { Ap } from './Ap';
import { Etc } from './Etc';
import { Ib } from './Ib';
import { Sat } from './Sat';
export const standarized = {
    ACT: Act,
    'A-LEVEL': ALevel,
    AP: Ap,
    ETC: Etc,
    IB: Ib,
    SAT: Sat,
};
