import * as yup from 'yup';
import { SCHEMA_MESSAGE } from '../message';
export const ACTIVITY_format = {
    division: {
        in: '교내',
        out: '교외',
    },
    type: {
        autonomy: '자율',
        inquiry: '탐구',
        career: '진로',
        other: '기타',
    },
};
export const ACTIVITY_schema = yup
    .object()
    .shape({
    transcript: yup.string().required('파일을 업로드 해주세요.'),
    division: yup
        .mixed()
        .oneOf(Object.keys(ACTIVITY_format.division))
        .required('교내외 구분을 필수로 선택해주세요.'),
    type: yup
        .mixed()
        .oneOf(Object.keys(ACTIVITY_format.type))
        .required('활동분야를 필수로 선택해주세요.'),
    startDate: yup.string().typeError('시작일을 필수로 입력해주세요.'),
    endDate: yup
        .string()
        .required(SCHEMA_MESSAGE.requiredMessage())
        .typeError('종료일을 필수로 입력해주세요.'),
    content: yup
        .string()
        .required('활동내용을 필수로 입력해주세요.')
        .max(300, SCHEMA_MESSAGE.maxMessage(300)),
    publishingOrganization: yup.string().required('발급기관을 필수로 입력해주세요.'),
})
    .default(null)
    .nullable();
