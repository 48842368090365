import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-shadow */
import { NoticeCommentModal } from '@component/community/notice/NoticeCommentModal';
import { VerticalDotsIcon } from '@component/icon/VerticalDotsIcon';
import { useNoticeComments } from '@hook/useNoticeComments';
import { useSearchAndPagination } from '@hook/useSearchAndPagination';
import { Button, Card, CardBody, CardFooter, CardHeader, Divider, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Input, Pagination, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, useDisclosure, } from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { notice } from '@simppl/repository/notice';
import { Search } from 'lucide-react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
const columns = [
    {
        key: 'title',
        label: '댓글 내용',
    },
    {
        key: 'setting',
        label: '비고',
    },
];
export default function NoticeCommentPage() {
    const { id } = useParams();
    if (!id)
        return null;
    const comments = useNoticeComments({
        id,
        page: 0,
        size: 0,
    });
    const { filterValue, filteredItems, items, page, setPage, onSearchChange, onClear } = useSearchAndPagination(comments.comments, (notice, searchValue) => notice.content.includes(searchValue));
    const [modalState, setModalState] = useState(null);
    const { isOpen, onOpenChange, onOpen, onClose } = useDisclosure();
    const deleteNoticeHook = useMutation(notice.delete);
    const queryClient = useQueryClient();
    return (_jsx("div", { className: 'flex min-h-[80%] flex-col', children: _jsxs(Card, { className: 'grow', children: [isOpen && modalState !== null && (_jsx(NoticeCommentModal, { isOpen: isOpen, onOpenChange: onOpenChange, ...modalState, onClose: () => {
                        onClose();
                        setModalState(null);
                    } })), _jsx(CardHeader, { children: _jsx("h1", { className: 'text-2xl', children: "\uB313\uAE00 \uAD00\uB9AC" }) }), _jsx(Divider, {}), _jsx(CardBody, { className: 'flex flex-col gap-4', children: _jsxs(Table, { layout: 'auto', topContent: _jsx("div", { className: 'flex flex-col gap-4', children: _jsxs("div", { className: 'flex w-full flex-row justify-between', children: [_jsx("div", { className: 'flex max-w-sm flex-1 flex-row items-center', children: _jsx(Input, { labelPlacement: 'outside', startContent: _jsx(Search, {}), value: filterValue, onClear: () => onClear(), onValueChange: onSearchChange }) }), _jsx(Button, { color: 'primary', onPress: () => {
                                            setModalState({
                                                mode: 'add',
                                            });
                                            onOpen();
                                        }, children: "\uB313\uAE00 \uCD94\uAC00" })] }) }), topContentPlacement: 'outside', children: [_jsx(TableHeader, { columns: columns, children: (column) => _jsx(TableColumn, { children: column.label }, column.key) }), _jsx(TableBody, { items: items, children: (notice) => (_jsxs(TableRow, { children: [_jsx(TableCell, { children: notice.content }), _jsx(TableCell, { children: _jsxs(Dropdown, { children: [_jsx(DropdownTrigger, { children: _jsx(Button, { isIconOnly: true, size: 'sm', variant: 'light', children: _jsx(VerticalDotsIcon, { className: 'text-default-300' }) }) }), _jsxs(DropdownMenu, { children: [_jsx(DropdownItem, { onPress: () => {
                                                                    setModalState({
                                                                        mode: 'edit',
                                                                        commentId: notice.id.toString(),
                                                                    });
                                                                    onOpen();
                                                                }, children: "\uC218\uC815" }), _jsx(DropdownItem, { className: 'text-red-500', onPress: () => {
                                                                    // eslint-disable-next-line no-restricted-globals
                                                                    if (!confirm('정말 삭제하시겠습니까?'))
                                                                        return;
                                                                    deleteNoticeHook.mutate({
                                                                        id: notice.id.toString(),
                                                                    }, {
                                                                        onSuccess: () => queryClient.invalidateQueries(['getNotices']),
                                                                    });
                                                                }, children: "\uC0AD\uC81C" })] })] }) })] }, notice.id)) })] }) }), _jsx(CardFooter, { children: _jsx("div", { className: 'flex w-full flex-col items-center', children: _jsx(Pagination, { isCompact: true, showControls: true, showShadow: true, page: page, onChange: setPage, total: Math.max(Math.ceil(filteredItems.length / 10), 1) }) }) })] }) }));
}
