import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

type SuspenseResult<T> = Omit<UseQueryResult<T>, 'data'> & ApiResType<UnWrappedApiRes<T>>;
type SuspenseOption<T extends QueryFunction<any>> = Omit<
  UseQueryOptions<UnWrappedPromiseFn<T>>,
  'queryKey' | 'queryFn' | 'suspense' | 'enabled'
>;

const useSuspenseQuery = <QueryFn extends QueryFunction<any>>({
  queryKey,
  queryFn,
  queryOptions,
}: {
  queryKey: QueryKey;
  queryFn: QueryFn;
  queryOptions?: SuspenseOption<QueryFn>;
}): SuspenseResult<UnWrappedPromiseFn<QueryFn>> => {
  const { data, ...rest } = useQuery({ queryKey, queryFn, suspense: true, ...queryOptions });
  return { ...rest, ...data } as SuspenseResult<UnWrappedPromiseFn<QueryFn>>;
};

export default useSuspenseQuery;
