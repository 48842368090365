import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Select } from '@component/score/common/form';
import { Minus } from '@component/score/common/icon/Minus';
import { ErrorMessage } from '@hookform/error-message';
import Text from '@simppl/component/Text';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { deleteTestById } from '@simppl/repository/test';
import { useFormContext } from 'react-hook-form';
import { HightSchoolForm } from '.';
const highSchoolTest = {
    AP: 'AP',
    IB: 'IB',
    'A-LEVEL': 'A-LEVEL',
    ETC: 'ETC',
    KOREA: 'KOREA',
};
export function HighSchoolInputForm({ name, removeSemester }) {
    const methods = useFormContext();
    const test = methods.watch(`${name}.subject`);
    const queryClient = useQueryClient();
    const deleteTestByIdHook = useMutation((reportCardId) => deleteTestById('report-cards', reportCardId));
    return (_jsxs("div", { className: 'border-Blue-Ct-2 flex w-auto flex-col items-center gap-6 px-[8px] py-[48px]', children: [_jsxs("div", { className: 'flex gap-[24px]', children: [_jsx(Select, { placeholder: '\uCEE4\uB9AC\uD058\uB7FC', content: methods.watch(`${name}.subject`), type: 'select', category: highSchoolTest, name: `${name}.subject` }), _jsx(GradeInput, { name: `${name}.grade` }), _jsx(SemesterInput, { name: `${name}.semester` }), _jsx(GpaInput, { scoreName: `${name}.gpa`, totalName: `${name}.perfectScore` }), _jsx(DeleteExamButton, { onClick: () => {
                            const reportCardId = methods.watch(`${name}.reportCardId`);
                            if (reportCardId) {
                                deleteTestByIdHook.mutate(reportCardId, {
                                    onSuccess: () => {
                                        queryClient.invalidateQueries(['report-cards-testByUserId']);
                                        removeSemester();
                                    },
                                });
                            }
                            else {
                                removeSemester();
                            }
                        } })] }), _jsx("div", { className: 'flex w-[809px] flex-col items-start gap-6', children: _jsx(HightSchoolForm, { testName: test, content: name }) })] }));
}
function GradeInput({ name }) {
    const methods = useFormContext();
    return (_jsxs("div", { className: 'relative flex w-[155px] items-center whitespace-nowrap rounded-[4px] border-[1px] border-blue-600 px-[16px] py-[8px]', children: [_jsx(Text, { color: 'text-blue-600', weight: 'Regular', as: 'Label', size: 1, children: "\uD559\uB144" }), _jsxs("div", { className: 'flex items-center', children: [_jsx("input", { className: 'line-[24px] w-full text-right text-[16px] outline-none', type: 'number', ...methods.register(name) }), _jsx(Text, { color: 'text-Base-Black', weight: 'Regular', as: 'Label', size: 1, children: "\uD559\uB144" })] }), _jsx(ErrorMessage, { errors: methods.formState.errors, name: name, render: ({ message }) => (_jsx("p", { className: 'absolute bottom-[-20px] text-[12px] text-red-600', children: message })) })] }));
}
function SemesterInput({ name }) {
    const methods = useFormContext();
    return (_jsxs("div", { className: 'relative flex w-[155px] items-center whitespace-nowrap rounded-[4px] border-[1px] border-blue-600 px-[16px] py-[8px]', children: [_jsx(Text, { color: 'text-blue-600', weight: 'Regular', as: 'Label', size: 1, children: "\uD559\uAE30" }), _jsxs("div", { className: 'flex items-center', children: [_jsx("input", { className: 'line-[24px] w-full text-right text-[16px] outline-none', type: 'number', ...methods.register(name) }), _jsx(Text, { color: 'text-Base-Black', weight: 'Regular', as: 'Label', size: 1, children: "\uD559\uAE30" })] }), _jsx(ErrorMessage, { errors: methods.formState.errors, name: name, render: ({ message }) => (_jsx("p", { className: 'absolute bottom-[-20px] text-[12px] text-red-600', children: message })) })] }));
}
function GpaInput({ scoreName, totalName }) {
    const methods = useFormContext();
    return (_jsxs("div", { className: 'relative flex w-[155px] items-center whitespace-nowrap rounded-[4px] border-[1px] border-blue-600 p-1', children: [_jsx(Text, { color: 'text-blue-600', weight: 'Regular', as: 'Label', size: 1, children: "GPA" }), _jsxs("div", { className: 'flex items-center', children: [_jsx("input", { className: 'line-[24px] w-full text-right text-[16px] outline-none', placeholder: '\uD3C9\uC810', type: 'number', step: '0.01', ...methods.register(scoreName) }), _jsx(Text, { color: 'text-Black', weight: 'Medium', as: 'Label', size: 1, children: "/" }), _jsx("input", { className: 'line-[24px] w-full text-right text-[16px] outline-none', type: 'number', placeholder: '\uB9CC\uC810', step: '0.01', ...methods.register(totalName) })] }), _jsxs("div", { className: 'absolute bottom-[-20px] left-0 flex text-[12px] text-red-600', children: [_jsx(ErrorMessage, { errors: methods.formState.errors, name: scoreName, render: ({ message }) => _jsx("p", { children: message }) }), _jsx(ErrorMessage, { errors: methods.formState.errors, name: totalName, render: ({ message }) => _jsx("p", { children: message }) })] })] }));
}
function DeleteExamButton({ onClick }) {
    return (_jsxs("button", { type: 'button', className: ' flex   w-[124px]   items-center justify-center gap-[8px] whitespace-nowrap rounded-[4px] border-[1px] border-blue-700 text-blue-700', onClick: onClick, children: [_jsx(Minus, {}), "\uC131\uC801 \uC0AD\uC81C"] }));
}
