import axios from '../..';
import type {
  DeleteAdmissionsInterestReqType,
  DeleteUserAdmissionsReqType,
  GetAdmissionsInterestReqType,
  GetUserAdmissionsIdReqType,
  GetUserAdmissionsReqType,
  PostAdmissionsInterestReqType,
} from './req';
import type {
  DeleteAdmissionsInterestResType,
  GetAdmissionsInterestResType,
  GetUserAdmissionsIdResType,
  GetUserAdmissionsResType,
  PostAdmissionsInterestResType,
} from './res';

export const userAdmissionsId = {
  get: ({ userId, userAdmissionId, sort }: GetUserAdmissionsIdReqType) =>
    axios
      .get<GetUserAdmissionsIdResType>(`/users/${userId}/admissions/${userAdmissionId}`, {
        params: { sort },
      })
      .then((res) => res.data),
  delete: ({ userId }: DeleteUserAdmissionsReqType) => axios.delete(`/users/${userId}/admissions`),
};

export const userAdmissions = {
  get: (params: GetUserAdmissionsReqType) =>
    axios.get<GetUserAdmissionsResType>(`/users/${params.userId}/admissions`).then((res) => {
      return res.data;
    }),
};

export const getUniversityStatus = (): Promise<{
  data: Array<GetUserAdmissionsResType['data'][number] & { num: number }>;
}> =>
  Promise.resolve({
    data: [
      {
        userAdmissionId: '0',
        image: 'https://simpple-backend.s3.ap-northeast-2.amazonaws.com/university/seoul.svg',
        name: '서울대학교',
        recruitName: '경제학과',
        majorName: '경제학과',
        num: 721,
        status: 'low',
        type: '3Y',
        admissionId: '',
        isApproved: true,
        rejectReason: '',
      },
      {
        userAdmissionId: '1',
        image: 'https://simpple-backend.s3.ap-northeast-2.amazonaws.com/university/korea.svg',
        name: '고려대학교',
        recruitName: '경제학과',
        majorName: '경제학과',
        num: 1259,
        status: 'middle',
        type: '3Y',
        admissionId: '',
        isApproved: true,
        rejectReason: '',
      },
      {
        userAdmissionId: '2',
        image: 'https://simpple-backend.s3.ap-northeast-2.amazonaws.com/university/yonsei.svg',
        name: '연세대학교',
        recruitName: '경제학과',
        majorName: '경제학과',
        num: 1876,
        status: 'high',
        type: '3Y',
        admissionId: '',
        isApproved: true,
        rejectReason: '',
      },
      {
        userAdmissionId: '3',
        image: 'https://simpple-backend.s3.ap-northeast-2.amazonaws.com/university/sogang.svg',
        name: '서강대학교',
        recruitName: '경제학과',
        majorName: '경제학과',
        num: 811,
        status: 'low',
        type: '3Y',
        admissionId: '',
        isApproved: true,
        rejectReason: '',
      },
      {
        userAdmissionId: '4',
        image: 'https://simpple-backend.s3.ap-northeast-2.amazonaws.com/university/skku.svg',
        name: '성균관대학교',
        recruitName: '경제학과',
        majorName: '경제학과',
        num: 1728,
        status: 'middle',
        type: '3Y',
        admissionId: '',
        isApproved: true,
        rejectReason: '',
      },
      {
        userAdmissionId: '5',
        image: 'https://simpple-backend.s3.ap-northeast-2.amazonaws.com/university/hanyang.svg',
        name: '한양대학교',
        recruitName: '경제학과',
        majorName: '경제학과',
        num: 1453,
        status: 'middle',
        type: '3Y',
        admissionId: '',
        isApproved: true,
        rejectReason: '',
      },
    ],
  });

export const userInterestAdmissions = {
  get: ({ userId }: GetAdmissionsInterestReqType) =>
    axios
      .get<GetAdmissionsInterestResType>(`/users/${userId}/interest-admissions`)
      .then((res) => res.data),

  post: ({ userId, ...body }: PostAdmissionsInterestReqType) =>
    axios
      .post<PostAdmissionsInterestResType>(`/users/${userId}/interest-admissions`, body)
      .then((res) => res.data),

  delete: ({ userId, interestAdmissionId }: DeleteAdmissionsInterestReqType) =>
    axios
      .delete<DeleteAdmissionsInterestResType>(
        `/users/${userId}/interest-admissions/${interestAdmissionId}`,
      )
      .then((res) => res.data),
};
