import axios from '..';
import type { DeleteFaqReqType, GetFaqsReqType, PatchFaqsReqType, PostFaqsReqType } from './req';
import type { DeleteFaqsResType, GetFaqsResType, PatchFaqsResType, PostFaqsResType } from './res';

export const faq = {
  get: (params: GetFaqsReqType) =>
    axios.get<GetFaqsResType>('/faqs', { params }).then((res) => res.data),
  post: (body: PostFaqsReqType) =>
    axios.post<PostFaqsResType>('/faqs', body).then((res) => res.data),
  patch: ({ id, ...body }: PatchFaqsReqType) =>
    axios.patch<PatchFaqsResType>(`/faqs/${id}`, body).then((res) => res.data),
  delete: ({ id }: DeleteFaqReqType) =>
    axios.delete<DeleteFaqsResType>(`/faqs/${id}`).then((res) => res.data),
};
