import { useCallback, useMemo, useState } from 'react';
export const useSearchAndPagination = (data, filterFunc, statusFilter) => {
    const [filterValue, setFilterValue] = useState('');
    const [page, setPage] = useState(1);
    const hasSearchFilter = Boolean(filterValue);
    const filteredItems = useMemo(() => {
        if (hasSearchFilter) {
            return data.filter((_) => filterFunc(_, filterValue));
        }
        if (statusFilter) {
            return data.filter((_) => statusFilter(_, filterValue));
        }
        return data;
    }, [hasSearchFilter, data, filterFunc, filterValue, statusFilter]);
    const items = useMemo(() => {
        const start = (page - 1) * 10;
        const end = page * 10;
        return filteredItems.slice(start, end);
    }, [page, filteredItems]);
    const onSearchChange = useCallback((value) => {
        if (value) {
            setFilterValue(value);
            setPage(1);
        }
        else {
            setFilterValue('');
        }
    }, []);
    const onClear = useCallback(() => {
        setFilterValue('');
        setPage(1);
    }, []);
    return {
        filteredItems,
        filterValue,
        page,
        setPage,
        items,
        onSearchChange,
        onClear,
    };
};
