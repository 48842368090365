import useQuery from '@simppl/react-query/useQuery';
import { universitiesMajors } from '@simppl/repository/admins';
export const useUniversitiesMajors = (body) => {
    const { data, refetch } = useQuery(['unversitiesMajors', body.universityId], () => universitiesMajors.get(body), {
        enabled: body.universityId !== undefined,
    });
    return {
        data: data?.data ?? [],
        refetch,
    };
};
