import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AddItemModal } from '@component/database/item/AddItemModal';
import { ItemModal } from '@component/database/item/ItemModal';
import { VerticalDotsIcon } from '@component/icon/VerticalDotsIcon';
import { useItem } from '@hook/useItem';
import { useSearchAndPagination } from '@hook/useSearchAndPagination';
import { Button, Card, CardBody, CardFooter, CardHeader, Divider, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Input, Pagination, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, useDisclosure, } from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { items as itemsApi } from '@simppl/repository/items';
import { Search } from 'lucide-react';
import { useState } from 'react';
export default function ItemPage() {
    const { data } = useItem();
    const { filterValue, filteredItems, items, page, setPage, onSearchChange, onClear } = useSearchAndPagination(data, (university, value) => university.name.includes(value));
    const [modalState, setModalState] = useState(null);
    const { isOpen, onOpenChange, onOpen, onClose } = useDisclosure();
    const [addModalState, setAddModalState] = useState(null);
    const { isOpen: isAddModalOpen, onOpenChange: onAddModalOpenChange, onOpen: onAddModalOpen, onClose: onAddModalClose, } = useDisclosure();
    const deleteItemsHook = useMutation(itemsApi.delete);
    const queryClient = useQueryClient();
    return (_jsx("div", { className: 'flex min-h-[80%] flex-col', children: _jsxs(Card, { className: 'grow', children: [isAddModalOpen && addModalState !== null && (_jsx(AddItemModal, { isOpen: isAddModalOpen, onOpenChange: onAddModalOpenChange, onClose: onAddModalClose, itemId: addModalState })), isOpen && modalState !== null && (_jsx(ItemModal, { isOpen: isOpen, onOpenChange: onOpenChange, ...modalState, onClose: () => {
                        onClose();
                        setModalState(null);
                    } })), _jsx(CardHeader, { children: _jsx("h1", { className: 'text-2xl', children: "\uC0C1\uD488 \uAD00\uB9AC" }) }), _jsx(Divider, {}), _jsx(CardBody, { className: 'flex flex-col gap-4', children: _jsxs(Table, { layout: 'auto', topContent: _jsx("div", { className: 'flex flex-col gap-4', children: _jsxs("div", { className: 'flex w-full flex-row justify-between', children: [_jsx("div", { className: 'flex max-w-sm flex-1 flex-row items-center', children: _jsx(Input, { labelPlacement: 'outside', startContent: _jsx(Search, {}), value: filterValue, onClear: () => onClear(), onValueChange: onSearchChange }) }), _jsx(Button, { color: 'primary', onPress: () => {
                                            setModalState({ mode: 'add' });
                                            onOpen();
                                        }, children: "\uC0C1\uD488 \uCD94\uAC00" })] }) }), topContentPlacement: 'outside', children: [_jsxs(TableHeader, { children: [_jsx(TableColumn, { children: "\uC0C1\uD488 \uC774\uB984" }, 'name'), _jsx(TableColumn, { children: "\uC0C1\uD488 \uC124\uBA85" }, 'description'), _jsx(TableColumn, { children: "\uAE30\uAC04 \uC124\uBA85" }, 'periodDescription'), _jsx(TableColumn, { children: "\uAC00\uACA9" }, 'price'), _jsx(TableColumn, { children: "\uAE30\uC874 \uAC00\uACA9" }, 'originalPrice'), _jsx(TableColumn, { children: "\uBAA8\uC758 \uC9C0\uC6D0 \uD69F\uC218" }, 'admissionCount'), _jsx(TableColumn, { children: "\uB178\uCD9C \uC2DC\uC791\uC77C" }, 'exposeStartAt'), _jsx(TableColumn, { children: "\uB178\uCD9C \uC885\uB8CC\uC77C" }, 'exposeEndAt'), _jsx(TableColumn, { children: "\uAD6C\uB9E4\uAC00\uB2A5 \uC2DC\uC791\uC77C" }, 'availableStartAt'), _jsx(TableColumn, { children: "\uAD6C\uB9E4\uAC00\uB2A5 \uC885\uB8CC\uC77C" }, 'availableEndAt'), _jsx(TableColumn, { children: "\uBE44\uACE0" }, 'setting')] }), _jsx(TableBody, { items: items, children: (item) => (_jsxs(TableRow, { className: 'relative cursor-pointer', children: [_jsx(TableCell, { children: item.name }), _jsx(TableCell, { children: item.description }), _jsx(TableCell, { children: item.periodDescription }), _jsx(TableCell, { children: item.price }), _jsx(TableCell, { children: item.originalPrice }), _jsx(TableCell, { children: item.admissionCount }), _jsx(TableCell, { children: item.exposeStartAt }), _jsx(TableCell, { children: item.exposeEndAt }), _jsx(TableCell, { children: item.availableStartAt }), _jsx(TableCell, { children: item.availableEndAt }), _jsx(TableCell, { children: _jsxs(Dropdown, { children: [_jsx(DropdownTrigger, { children: _jsx(Button, { isIconOnly: true, size: 'sm', variant: 'light', children: _jsx(VerticalDotsIcon, { className: 'text-default-300' }) }) }), _jsxs(DropdownMenu, { children: [_jsx(DropdownItem, { onPress: () => {
                                                                    setModalState({ mode: 'edit', itemId: item.itemId });
                                                                    onOpen();
                                                                }, children: "\uC218\uC815" }), _jsx(DropdownItem, { onPress: () => {
                                                                    setAddModalState(item.itemId);
                                                                    onAddModalOpen();
                                                                }, children: "\uC720\uC800\uC5D0\uAC8C \uC0C1\uD488 \uCD94\uAC00" }), _jsx(DropdownItem, { onPress: () => {
                                                                    deleteItemsHook.mutate({ itemId: item.itemId }, {
                                                                        onSuccess: () => {
                                                                            queryClient.invalidateQueries(['items']);
                                                                        },
                                                                    });
                                                                }, children: "\uC0AD\uC81C" })] })] }) })] }, item.itemId)) })] }) }), _jsx(CardFooter, { children: _jsx("div", { className: 'flex w-full flex-col items-center', children: _jsx(Pagination, { isCompact: true, showControls: true, showShadow: true, page: page, onChange: setPage, total: Math.max(Math.ceil(filteredItems.length / 10), 1) }) }) })] }) }));
}
