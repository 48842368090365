import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CountryManageModal } from '@component/database/country/CountryManageModal';
import { VerticalDotsIcon } from '@component/icon/VerticalDotsIcon';
import { useCountries } from '@hook/useCountries';
import { Button, Card, CardBody, CardFooter, CardHeader, Divider, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Input, Pagination, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, useDisclosure, } from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { deleteCountries } from '@simppl/repository/countries';
import { Search } from 'lucide-react';
import { useCallback, useMemo, useState } from 'react';
const columns = [
    {
        key: 'name',
        label: '고등학교 이름',
    },
    {
        key: 'isApprove',
        label: '승인여부',
    },
    {
        key: 'setting',
        label: '비고',
    },
];
const useCountry = (countries) => {
    const [filterValue, setFilterValue] = useState('');
    const [page, setPage] = useState(1);
    const hasSearchFilter = Boolean(filterValue);
    const filteredItems = useMemo(() => {
        if (hasSearchFilter) {
            return countries.filter((country) => country.name.toLowerCase().includes(filterValue.toLowerCase()));
        }
        return countries;
    }, [filterValue, hasSearchFilter, countries]);
    const items = useMemo(() => {
        const start = (page - 1) * 10;
        const end = page * 10;
        return filteredItems.slice(start, end);
    }, [page, filteredItems]);
    const onSearchChange = useCallback((value) => {
        if (value) {
            setFilterValue(value);
            setPage(1);
        }
        else {
            setFilterValue('');
        }
    }, []);
    const onClear = useCallback(() => {
        setFilterValue('');
        setPage(1);
    }, []);
    return {
        filteredItems,
        filterValue,
        page,
        setPage,
        items,
        onSearchChange,
        onClear,
    };
};
export default function CountryPage() {
    //   const [isApprove, setIsApprove] = useState(false);
    const [countryData, setCountryData] = useState(null);
    const { countries } = useCountries();
    const { page, setPage, items, filteredItems, filterValue, onClear, onSearchChange } = useCountry(countries);
    const queryClient = useQueryClient();
    const deleteCountryHook = useMutation((id) => deleteCountries(id));
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    return (_jsxs("div", { className: 'flex min-h-[80%] flex-col', children: [countryData && (_jsx(CountryManageModal, { countryId: countryData.countryId, name: countryData.countryName, isAprroved: countryData.isAprroved, isOpen: isOpen, onOpenChange: (value) => {
                    if (!value) {
                        setCountryData(null);
                        queryClient.invalidateQueries(['countries']);
                    }
                    onOpenChange();
                } })), _jsxs(Card, { className: 'grow', children: [_jsx(CardHeader, { children: _jsx("h1", { className: 'text-2xl', children: "\uAD6D\uAC00 \uAD00\uB9AC" }) }), _jsx(Divider, {}), _jsxs(CardBody, { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'flex flex-col gap-4', children: _jsxs("div", { className: 'flex w-full flex-row gap-4', children: [_jsx("div", { className: 'flex max-w-sm flex-1 flex-row items-center', children: _jsx(Input, { labelPlacement: 'outside', startContent: _jsx(Search, {}), value: filterValue, onClear: () => onClear(), onValueChange: onSearchChange }) }), _jsx(Button, { color: 'primary', onPress: () => {
                                                /**
                                                 * TODO 검색
                                                 */
                                                console.log('검색');
                                            }, children: "\uAC80\uC0C9" })] }) }), _jsxs(Table, { children: [_jsx(TableHeader, { columns: columns, children: (column) => (_jsx(TableColumn, { className: 'text-center', children: column.label }, column.key)) }), _jsx(TableBody, { items: items, children: (country) => (_jsxs(TableRow, { className: 'relative', children: [_jsx(TableCell, { children: country.name }), _jsx(TableCell, { className: 'text-center', children: country.isApprove ? '승인' : '반려' }), _jsx(TableCell, { className: 'text-center', children: _jsxs(Dropdown, { children: [_jsx(DropdownTrigger, { children: _jsx(Button, { isIconOnly: true, size: 'sm', variant: 'light', children: _jsx(VerticalDotsIcon, { className: 'text-default-300' }) }) }), _jsxs(DropdownMenu, { children: [_jsx(DropdownItem, { onPress: () => {
                                                                            setCountryData({
                                                                                countryId: country.id,
                                                                                isAprroved: country.isApprove,
                                                                                countryName: country.name,
                                                                            });
                                                                            onOpen();
                                                                        }, children: "\uC2B9\uC778 \uAD00\uB9AC" }), _jsx(DropdownItem, { className: 'text-red-500', onPress: () => {
                                                                            // eslint-disable-next-line no-restricted-globals
                                                                            if (!confirm('정말 삭제하시겠습니까?'))
                                                                                return;
                                                                            deleteCountryHook.mutate(country.id, {
                                                                                onSuccess: () => {
                                                                                    queryClient.invalidateQueries(['countries']);
                                                                                },
                                                                            });
                                                                        }, children: "\uC0AD\uC81C" })] })] }) })] }, country.id)) })] })] }), _jsx(CardFooter, { children: _jsx("div", { className: 'flex w-full flex-col items-center', children: _jsx(Pagination, { isCompact: true, showControls: true, showShadow: true, page: page, onChange: setPage, total: Math.max(Math.ceil(filteredItems.length / 10), 1) }) }) })] })] }));
}
