import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/destructuring-assignment */
import { useNoticeComments } from '@hook/useNoticeComments';
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, } from '@nextui-org/react';
import Editor from '@simppl/component/Editor';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { noticeComment } from '@simppl/repository/notice';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
export function NoticeCommentModal(props) {
    const { id } = useParams();
    if (!id)
        return null;
    const comment = props.mode === 'edit'
        ? useNoticeComments({
            id,
            page: 0,
            size: 0,
        }).comments.find((comment) => comment.id.toString() === props.commentId)
        : null;
    const [content, setContent] = useState('');
    const queryClient = useQueryClient();
    const postItemHook = useMutation(noticeComment.post);
    const patchItemHook = useMutation(noticeComment.patch);
    useEffect(() => {
        if (props.mode === 'edit' && comment) {
            setContent(comment.content);
        }
    }, [props, comment]);
    return (_jsx(Modal, { isOpen: props.isOpen, onOpenChange: props.onOpenChange, children: _jsx(ModalContent, { children: () => (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { className: 'flex flex-col gap-1', children: props.mode === 'add' ? '댓글 추가' : '댓글 수정' }), _jsx(ModalBody, { children: _jsx(Editor, { initialData: content, onChange: setContent }) }), _jsxs(ModalFooter, { children: [_jsx(Button, { color: 'danger', variant: 'light', onPress: props.onClose, children: "\uCDE8\uC18C" }), _jsx(Button, { type: 'submit', form: 'authorization', color: 'primary', onPress: () => {
                                    if (props.mode === 'add')
                                        postItemHook.mutate({
                                            id,
                                            comment: content,
                                        }, {
                                            onSuccess: () => {
                                                queryClient.invalidateQueries(['getNoticeComments']);
                                                props.onClose();
                                            },
                                        });
                                    else if (props.mode === 'edit' && comment?.id)
                                        patchItemHook.mutate({
                                            id,
                                            commentId: props.commentId,
                                            comment: content,
                                        }, {
                                            onSuccess: () => {
                                                queryClient.invalidateQueries(['getNoticeComments']);
                                                props.onClose();
                                            },
                                        });
                                }, children: "\uC800\uC7A5" })] })] })) }) }));
}
