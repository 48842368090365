import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { VerticalDotsIcon } from '@component/icon/VerticalDotsIcon';
import { useAdmissionsInterestByUserId } from '@hook/useAdmissionsInterestByUserId';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, } from '@nextui-org/react';
import { UserIdContext } from '@page/user/UserDetailPage';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { userInterestAdmissions } from '@simppl/repository/user';
import { useContext } from 'react';
const columns = [
    {
        key: 'type',
        label: '전형',
    },
    {
        key: 'name',
        label: '대학',
    },
    {
        key: 'recruitName',
        label: '학부',
    },
    {
        key: 'majorName',
        label: '전공',
    },
    {
        key: 'setting',
        label: '비고',
    },
];
export const UserAdmissionInterestTab = () => {
    const { userId } = useContext(UserIdContext);
    const userAdmissions = useAdmissionsInterestByUserId(userId);
    const queryClient = useQueryClient();
    const deleteAdmissionInterestByUserIdHook = useMutation((interestAdmissionId) => userInterestAdmissions.delete({ userId, interestAdmissionId }));
    return (_jsxs(Table, { layout: 'auto', children: [_jsx(TableHeader, { columns: columns, children: (column) => _jsx(TableColumn, { children: column.label }, column.key) }), _jsx(TableBody, { items: userAdmissions.resultList, children: (admission) => (_jsxs(TableRow, { children: [_jsx(TableCell, { children: admission.type }), _jsx(TableCell, { children: admission.name }), _jsx(TableCell, { children: admission.recruitName }), _jsx(TableCell, { children: admission.majorName }), _jsx(TableCell, { children: _jsxs(Dropdown, { children: [_jsx(DropdownTrigger, { children: _jsx(Button, { isIconOnly: true, size: 'sm', variant: 'light', children: _jsx(VerticalDotsIcon, { className: 'text-default-300' }) }) }), _jsxs(DropdownMenu, { children: [_jsx(DropdownItem, { children: "\uC218\uC815" }), _jsx(DropdownItem, { className: 'text-red-500', onPress: () => {
                                                    // eslint-disable-next-line no-restricted-globals
                                                    if (!confirm('정말 삭제하시겠습니까?'))
                                                        return;
                                                    deleteAdmissionInterestByUserIdHook.mutate(admission.interestAdmissionId, {
                                                        onSuccess: () => {
                                                            queryClient.invalidateQueries(['useAdmissionsInterestByUserId']);
                                                        },
                                                    });
                                                }, children: "\uC0AD\uC81C" })] })] }) })] }, admission.interestAdmissionId)) })] }));
};
