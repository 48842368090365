import c from '@simppl/util/c';
import type { PropsWithChildren } from 'react';

const Text = ({
  color,
  weight,
  size,
  children,
  as,
  truncate,
  whiteSpace,
  align,
  wordBreak,
  className,
  cancelLine = false,
  clamp,
}: PropsWithChildren<{
  align?: keyof typeof TEXT_ALIGN;
  color: `text-${string}`;
  weight: 'Bold' | 'Medium' | 'Regular' | number;
  as: 'Display' | 'Headline' | 'Title' | 'Body' | 'Label' | 'Caption';
  size: 1 | 2;
  truncate?: boolean;
  whiteSpace?: keyof typeof WHITE_SPACE;
  wordBreak?: keyof typeof WORD_BREAK;
  className?: string;
  cancelLine?: boolean;
  clamp?: 1 | 2 | 3 | 4 | 5 | 6;
}>) => {
  const Component = getComponent({ as, size });
  return (
    <Component
      className={c(
        color,
        className,
        align && TEXT_ALIGN[align],
        typeof weight === 'number' ? `font-[${weight}]` : WEIGHT_CLASSNAME[weight],
        SIZE_CLASSNAME[as][size],
        truncate && 'w-full truncate',
        whiteSpace && WHITE_SPACE[whiteSpace],
        wordBreak && WORD_BREAK[wordBreak],
        cancelLine && 'line-through',
        clamp && LINE_CLAMP[clamp],
      )}
      title={typeof children === 'string' ? children : ''}
    >
      {children}
    </Component>
  );
};

export default Text;

const WEIGHT_CLASSNAME = {
  Bold: 'font-Pretendard-Bold',
  Medium: 'font-Pretendard-Medium',
  Regular: 'font-Pretendard-Regular',
};

const SIZE_CLASSNAME = {
  Display: {
    1: 'text-5xl leading-[64px]',
    2: 'text-4xl leading-[48px]',
  },
  Headline: {
    1: 'text-3xl leading-[40px]',
    2: 'text-2xl leading-[36px]',
  },
  Title: {
    1: 'text-xl leading-[26px]',
    2: 'text-lg leading-[24px]',
  },
  Body: {
    1: 'text-base leading-[26px]',
    2: 'text-sm leading-[24px]',
  },
  Label: {
    1: 'text-base leading-[24px]',
    2: 'text-sm leading-[20px]',
  },
  Caption: {
    1: 'text-xs leading-[16px]',
    2: 'text-[0.6875rem] leading-[14px]',
  },
};
const WHITE_SPACE = {
  normal: 'whitespace-normal',
  nowrap: 'whitespace-nowrap',
  pre: 'whitespace-pre',
  preLine: 'whitespace-pre-line',
  preWrap: 'whitespace-pre-wrap',
  breakSpaces: 'whitespace-break-spaces',
};

const TEXT_ALIGN = {
  justify: 'text-justify',
  left: 'text-left',
  right: 'text-right',
  center: 'text-center',
  end: 'text-end',
  start: 'text-start',
};

const getComponent = ({ as, size }: Pick<React.ComponentProps<typeof Text>, 'as' | 'size'>) => {
  return 'span';
  if (as === 'Headline') {
    return size === 1 ? 'h1' : 'h2';
  }
  switch (as) {
    case 'Label':
      return 'label';
    default:
      return 'span';
  }
};

const WORD_BREAK = {
  normal: 'break-normal',
  words: 'break-words',
  all: 'break-all',
  keep: 'break-keep',
};

const LINE_CLAMP = {
  1: 'line-clamp-1 break-all',
  2: 'line-clamp-2 break-all w-full',
  3: 'line-clamp-3 break-all w-full',
  4: 'line-clamp-4 break-all w-full',
  5: 'line-clamp-5 break-all w-full',
  6: 'line-clamp-6 break-all w-full',
};
