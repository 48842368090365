/* eslint-disable react-hooks/rules-of-hooks */
export const convertToETCAndotherData = (data) => {
    return Object.fromEntries(Object.entries(data)
        .filter(([testType, value]) => value !== null &&
        value !== undefined &&
        testType !== 'testType' &&
        testType !== 'edit' &&
        Object.keys(value).length > 0)
        ?.reduce((acc, [test, value]) => {
        if (test === 'ETC') {
            delete value['uploadFile'];
            const subjectList = value.subjectList.map((subject) => {
                delete subject['uploadFile'];
                subject['totalScore'] = subject['totalScore'].toString();
                subject['score'] = subject['score'].toString();
                return subject;
            });
            acc.push([test, subjectList]);
        }
        else {
            if (test === 'IELTS') {
                if (value.score === undefined) {
                    value.score = value.totalScore;
                }
            }
            if (test === 'HSK') {
                value.speaking = 0;
                value.grade = parseInt(value.grade, 10);
            }
            if (test === 'A-LEVEL') {
                value.subjectList = value.subjectList.map((subject) => {
                    // if (subject.id === undefined) subject.id = subject.standarizedALevelId;
                    return subject;
                });
            }
            if (test === 'AP') {
                value.subjectList = value.subjectList.map((subject) => {
                    subject.id = subject.standarizedApId;
                    return subject;
                });
            }
            if (test === 'IB') {
                value.subjectList = value.subjectList.map((subject) => {
                    subject.id = subject.standarizedIbId;
                    return subject;
                });
            }
            delete value['uploadFile'];
            acc.push([test, value]);
        }
        return acc;
    }, []));
};
export const convertToETCStandardData = (A_LEVEL, AP, IB, data) => {
    return Object.fromEntries(Object.entries(data)
        .filter(([testType, value]) => value !== null &&
        value !== undefined &&
        testType !== 'testType' &&
        testType !== 'edit' &&
        Object.keys(value).length > 0)
        .reduce((acc, [test, value]) => {
        if (test === 'ETC') {
            delete value['uploadFile'];
            const subjectList = value.subjectList.map((subject) => {
                delete subject['uploadFile'];
                subject['totalScore'] = subject['totalScore'].toString();
                subject['score'] = subject['score'].toString();
                return subject;
            });
            acc.push([test, subjectList]);
        }
        else {
            if (test === 'A-LEVEL') {
                value.subjectList = value.subjectList.map((subject) => {
                    subject.id = A_LEVEL[subject.name];
                    return subject;
                });
            }
            if (test === 'AP') {
                value.subjectList = value.subjectList.map((subject) => {
                    subject.id = AP[subject.name];
                    return subject;
                });
            }
            if (test === 'IB') {
                value.subjectList = value.subjectList.map((subject) => {
                    subject.id = IB[subject.name];
                    return subject;
                });
            }
            delete value['uploadFile'];
            acc.push([test, value]);
        }
        return acc;
    }, []));
};
