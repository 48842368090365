import {
  captureMessage,
  handleSentry401AuthError,
  setSentryContextApi,
} from '@simppl/react-sentry';
import { generateStorage } from '@simppl/util/storage';
import type { AxiosResponse, InternalAxiosRequestConfig, Method } from 'axios';
import _axios from 'axios';

import ApiError from './AxiosError';
import type ResType from './type';
import { postUsersIssueToken, postUsersLogout } from './user';
import LoginStorage from './user/LoginStorage';

const storage = generateStorage();
const isAdmin = window.location.host.startsWith('admin');
const devMode = storage.get('devMode') === 'true';

const axios = _axios.create({
  baseURL:
    isAdmin && devMode
      ? import.meta.env.VITE_SIMPPL_DEV_API_URL
      : import.meta.env.VITE_SIMPPL_API_URL,
  withCredentials: true,
});

export default axios;

type RequestInterceptor = (
  config: InternalAxiosRequestConfig,
) => InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig>;

const 토큰재발급API_URL = '/users/issue-token';
const tokenRequestInterceptor: RequestInterceptor = async (config) => {
  const token = LoginStorage.getToken({ type: 'access' });
  if (!token) return config;
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${token}`;
  return config;
};

const SERVER_ERROR_CODE = {
  '500': 'SERVER_ERROR',
};

const ResponseInterceptor = async <T>(value: AxiosResponse<ResType<T>>) => {
  const { resultCode, ...rest } = value.data;
  const error = SERVER_ERROR_CODE[String(resultCode) as keyof typeof SERVER_ERROR_CODE];
  if (error) throw new Error(error);
  return {
    ...value,
    data: {
      resultCode,
      data: rest?.data,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...(rest?.meta !== undefined ? { meta: rest.meta } : {}),
    },
  };
};

const DUPLICATE_LOGIN_ERROR_MESSAGE = '중복 로그인입니다.';
const DUPLICATE_LOGIN_ALERT_MESSAGE = '중복 로그인으로 로그아웃됩니다.';
const EXPRIED_TOKEN_ALERT_MESSAGE = '토큰 만료로 로그아웃됩니다.';
const IGNORE_SENTRY_ERROR_MESSAGE_LIST = ['Request aborted', 'Network Error'];

const ResponseInterceptorError = async (error: any) => {
  if (IGNORE_SENTRY_ERROR_MESSAGE_LIST.includes(error.message)) throw error;
  if (error.response?.status !== 401) {
    const apiError = new ApiError(error);
    setSentryContextApi({ error: apiError });
    throw apiError;
  }
  // 로그인 하지 않은 경우
  if (!LoginStorage.isLogin()) {
    handleNotUserOccurAuthError();
    captureMessage('비 로그인 유저의 401 error가 발생했어요.');
    return null;
  }
  if (error.config.url === 토큰재발급API_URL) {
    const isDuplicateLogin = error.response.data.message === DUPLICATE_LOGIN_ERROR_MESSAGE;
    handleSentry401AuthError({
      accessToken: LoginStorage.getToken({ type: 'access' }),
      refreshToken: LoginStorage.getToken({ type: 'refresh' }),
      message: isDuplicateLogin
        ? '중복로그인으로 에러가 발생했어요'
        : '토큰 재발급 과정에서 에러가 발생했어요.',
    });
    alert(isDuplicateLogin ? DUPLICATE_LOGIN_ALERT_MESSAGE : EXPRIED_TOKEN_ALERT_MESSAGE);
    await postUsersLogout();
    return null;
  }
  const res = await refetchApi(error);
  return res;
};

axios.interceptors.request.use(tokenRequestInterceptor);
axios.interceptors.response.use(ResponseInterceptor, ResponseInterceptorError);

let tryNum = 0;
const refetchApi = async (error: any) => {
  if (tryNum === 0) {
    tryNum += 1; // lock
    await postUsersIssueToken();
    tryNum -= 1;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const res = await axios[error.config.method as Lowercase<Method>](
      error.config.url,
      error.config.data, // 확인해봐야함
    );
    return res;
  }
  throw error;
};

const handleNotUserOccurAuthError = () => {
  LoginStorage.removeToken({ type: 'access' });
  LoginStorage.removeToken({ type: 'refresh' });
  const { pathname, search } = window.location;
  window.location.replace(`/login?redirectUrl=${pathname}${search}`);
};
