import axios from '..';
import type {
  DeleteMockUserAdmissionsReqType,
  GetBoardsBoardIdPostsReqType,
  GetUserInfoByIdReqType,
  PatchAdmissionNewsReqType,
  PatchMockUserAdmissionsReqType,
  PostAdmissionNewsReqType,
  PostMockUserAdmissionsReqType,
  PutUserInfoByAdminReqType,
} from './req';
import type {
  DeleteMockUserAdmissionsResType,
  GetBoardsBoardIdPostsResType,
  GetMockUserAdmissionsResType,
  GetUserInfoResType,
  GetUsersResType,
  PatchMockUserAdmissionsResType,
  PostAdmissionNewsResType,
  PostMockUserAdmissionsResType,
  PutUserInfoByAdminResType,
} from './res';
import type {
  DeleteUniversitiesMajorsReqType,
  DeleteUniversitiesReqType,
  GetUniversitiesMajorsReqType, PatchUniversitiesMajorsReqType,
  PatchUniversitiesReqType, PostUniversitiesMajorsReqType,
  PostUniversitiesReqType,
} from '../universities/req';
import type {
  DeleteUniversitiesMajorsResType,
  DeleteUniversitiesResType,
  GetUniversitiesMajorsResType,
  PatchUniversitiesResType, PostUniversitiesMajorsResType,
  PostUniversitiesResType,
} from '../universities/res';

export const postMockUserAdmissions = (body: PostMockUserAdmissionsReqType) => {
  return axios
    .post<PostMockUserAdmissionsResType>('/admins/mock-user-admissions', body)
    .then((res) => res.data);
};

export const getMockUserAdmissions = () => {
  return axios
    .get<GetMockUserAdmissionsResType>('/admins/mock-user-admissions')
    .then((res) => res.data);
};

export const mockUserAdmissions = {
  get: () =>
    axios.get<GetMockUserAdmissionsResType>('/admins/mock-user-admissions').then((res) => res.data),
  post: (body: PostMockUserAdmissionsReqType) =>
    axios
      .post<PostMockUserAdmissionsResType>('/admins/mock-user-admissions', body)
      .then((res) => res.data),
  patch: ({ userAdmissionId, ...body }: PatchMockUserAdmissionsReqType) =>
    axios
      .patch<PatchMockUserAdmissionsResType>(
        `/admins/mock-user-admissions/${userAdmissionId}`,
        body,
      )
      .then((res) => res.data),
  delete: ({ userAdmissionId }: DeleteMockUserAdmissionsReqType) =>
    axios
      .delete<DeleteMockUserAdmissionsResType>(`/admins/mock-user-admissions/${userAdmissionId}`)
      .then((res) => res.data),
};

export const createAllAdmissionsBySimulatedApplicationId = (simulatedApplicationId: number) => {
  return axios
    .post(`/admins/admissions`, {
      simulatedApplicationId,
    })
    .then((res) => res.data);
};

export const createAllCompetitiveUnitsBySimulatedApplicationId = (
  simulatedApplicationId: number,
) => {
  return axios
    .post(`/admins/competitive-units`, {
      simulatedApplicationId,
    })
    .then((res) => res.data);
};

export const getBoardsByBoardIds = async ({
  boardIds,
  ...params
}: GetBoardsBoardIdPostsReqType) => {
  const queryParamString = boardIds.map((n) => `boardIds[]=${n}`).join('&');
  return (
    await axios.get<GetBoardsBoardIdPostsResType>(`/admins/boards?${queryParamString}`, { params })
  ).data;
};

export const postAdmissionNews = async (body: PostAdmissionNewsReqType) => {
  return (await axios.post<PostAdmissionNewsResType>(`/admins/admission-news`, body)).data;
};

export const patchADmissionNews = async ({
  admissionNewsId,
  ...body
}: PatchAdmissionNewsReqType) => {
  return (
    await axios.patch<PostAdmissionNewsResType>(`/admins/admission-news/${admissionNewsId}`, body)
  ).data;
};

// users
export const getUsers = () => axios.get<GetUsersResType>('/admins/users').then((res) => res.data);

export const getUser = ({ userId }: GetUserInfoByIdReqType) =>
  axios.get<GetUserInfoResType>(`/admins/users/${userId}`).then((res) => res.data);

export const putUserInfo = (userId: string, body: PutUserInfoByAdminReqType) =>
  axios.put<PutUserInfoByAdminResType>(`/admins/users/${userId}`, body).then((res) => res.data);


// universities
export const postUniversity = (body: PostUniversitiesReqType) =>
  axios.post<PostUniversitiesResType>('/admins/universities', { ...body }).then((res) => res.data)

export const patchUniversity =({ universityId, ...body }: PatchUniversitiesReqType) =>
  axios.patch<PatchUniversitiesResType>(`/admins/universities/${universityId}`, body)
    .then((res) => res.data)

export const deleteUniversity = ({ universityId }: DeleteUniversitiesReqType) =>
  axios.delete<DeleteUniversitiesResType>(`/admins/universities/${universityId}`)
    .then((res) => res.data)

// majors(department(학과), faculty(학부))
export const universitiesMajors = {
  get: ({ universityId }: GetUniversitiesMajorsReqType) =>
    axios
      .get<GetUniversitiesMajorsResType>(`/admins/universities/${universityId}/majors`)
      .then((res) => res.data),

  post: ({ universityId, ...body }: PostUniversitiesMajorsReqType) =>
    axios
      .post<PostUniversitiesMajorsResType>(`/admins/universities/${universityId}/majors`, body)
      .then((res) => res.data),
  delete: ({ universityId, universityMajorId }: DeleteUniversitiesMajorsReqType) =>
    axios
      .delete<DeleteUniversitiesMajorsResType>(
        `/admins/universities/${universityId}/majors/${universityMajorId}`,
      )
      .then((res) => res.data),

  patch: ({ universityId, universityMajorId, ...body }: PatchUniversitiesMajorsReqType) =>
    axios
      .patch<PatchUniversitiesResType>(
        `/admins/universities/${universityId}/majors/${universityMajorId}`,
        body,
      )
      .then((res) => res.data),
};

