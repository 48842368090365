import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ErrorMessage } from '@hookform/error-message';
import Text from '@simppl/component/Text';
import getImgUrl from '@simppl/util/getImgUrl';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { UploadFile } from './UploadFile';
import { UploaderPopUp } from './UploaderPopUp';
export function UploaderButton({ name, testName, testType, }) {
    const [open, setOpen] = useState(false);
    const methods = useFormContext();
    return (_jsxs("div", { className: 'border-Blue-Ct-2 relative flex h-[118px] w-full flex-col items-center justify-center gap-[16px] rounded-[16px] border-[1px] shadow-[0px_1px_2px_0px_rgba(20,_25,_97,_0.10),_2px_2px_3px_0px_rgba(20,_25,_97,_0.09),_4px_5px_4px_0px_rgba(20,_25,_97,_0.05),_8px_9px_5px_0px_rgba(20,_25,_97,_0.01),_12px_14px_5px_0px_rgba(20,_25,_97,_0.00)]', children: [_jsx(ErrorMessage, { errors: methods.formState.errors, name: `${name}.transcript`, render: ({ message }) => (_jsx("p", { className: 'absolute bottom-[-20px] text-[12px] text-red-600', children: message })) }), _jsxs("div", { className: ' flex cursor-pointer gap-[8px]', onClick: () => setOpen(true), children: [_jsx("img", { src: getImgUrl('/asset/Button/Upload.svg'), alt: 'upload' }), methods.watch(`${name}.transcript`) ? (_jsx(Text, { color: 'text-blue-500', weight: 'Medium', as: 'Title', size: 1, children: methods.watch(`${name}.transcript`)?.length > 0
                            ? decodeURIComponent(methods.watch(`${name}.transcript`).split('/').at(-1))
                            : '고교성적 증명서.pdf' })) : (_jsx(Text, { color: 'text-blue-500', weight: 'Medium', as: 'Title', size: 1, children: "\uD30C\uC77C \uCCA8\uBD80" }))] }), !methods.watch(`${name}.transcript`) && (_jsx(Text, { color: 'text-blue-500', weight: 'Medium', as: 'Body', size: 2, children: "\uC99D\uBE59\uC11C\uB958\uB85C \uC131\uC801\uD45C \uD30C\uC77C\uC774 \uBC18\uB4DC\uC2DC \uCCA8\uBD80\uB418\uC5B4\uC57C \uD558\uBA70, 8MB \uC774\uD558\uC758 \uB2E8\uC77C pdf \uD30C\uC77C\uB85C \uD569\uCCD0\uC11C \uC81C\uCD9C\uD574\uC8FC\uC138\uC694." })), _jsx(UploaderPopUp, { name: name, open: open, onClose: () => setOpen(false) }), _jsx(UploadFile, { name: name, testName: testName, testType: testType, onClose: () => setOpen(false) })] }));
}
