import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-shadow */
import ShadowDOM from '@component/ShadowDOM';
import { FaqModal } from '@component/community/faq/FaqModal';
import { VerticalDotsIcon } from '@component/icon/VerticalDotsIcon';
import { useFaqs } from '@hook/useFaqs';
import { Button, Card, CardBody, CardFooter, CardHeader, Divider, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Input, Pagination, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, useDisclosure, } from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { faq } from '@simppl/repository/faq';
import { Search } from 'lucide-react';
import { useCallback, useState } from 'react';
const columns = [
    {
        key: 'category',
        label: '카테고리',
    },
    {
        key: 'question',
        label: '질문',
    },
    {
        key: 'answer',
        label: '답변',
    },
    {
        key: 'setting',
        label: '비고',
    },
];
export default function FaqPage() {
    const [page, setPage] = useState(1);
    const [filterValue, setFilterValue] = useState('');
    const faqs = useFaqs({
        searchType: 'title',
        search: filterValue,
        page,
        size: 10,
    });
    const onSearchChange = useCallback((value) => {
        if (value) {
            setFilterValue(value);
            setPage(1);
        }
        else {
            setFilterValue('');
        }
    }, []);
    const onClear = useCallback(() => {
        setFilterValue('');
        setPage(1);
    }, []);
    const [modalState, setModalState] = useState(null);
    const { isOpen, onOpenChange, onOpen, onClose } = useDisclosure();
    const deleteFaqHook = useMutation(faq.delete);
    const queryClient = useQueryClient();
    return (_jsx("div", { className: 'flex min-h-[80%] flex-col', children: _jsxs(Card, { className: 'grow', children: [isOpen && modalState !== null && (_jsx(FaqModal, { isOpen: isOpen, onOpenChange: onOpenChange, ...modalState, onClose: () => {
                        onClose();
                        setModalState(null);
                    } })), _jsx(CardHeader, { children: _jsx("h1", { className: 'text-2xl', children: "FaQ \uAD00\uB9AC" }) }), _jsx(Divider, {}), _jsx(CardBody, { className: 'flex flex-col gap-4', children: _jsxs(Table, { layout: 'auto', topContent: _jsx("div", { className: 'flex flex-col gap-4', children: _jsxs("div", { className: 'flex w-full flex-row justify-between', children: [_jsx("div", { className: 'flex max-w-sm flex-1 flex-row items-center', children: _jsx(Input, { labelPlacement: 'outside', startContent: _jsx(Search, {}), value: filterValue, onClear: () => onClear(), onValueChange: onSearchChange }) }), _jsx(Button, { color: 'primary', onPress: () => {
                                            setModalState({
                                                mode: 'add',
                                            });
                                            onOpen();
                                        }, children: "FaQ \uCD94\uAC00" })] }) }), topContentPlacement: 'outside', children: [_jsx(TableHeader, { columns: columns, children: (column) => _jsx(TableColumn, { children: column.label }, column.key) }), _jsx(TableBody, { items: faqs.posts, children: (notice) => (_jsxs(TableRow, { children: [_jsx(TableCell, { children: notice.category.title }), _jsx(TableCell, { children: _jsx(ShadowDOM, { html: notice.question }) }), _jsx(TableCell, { children: _jsx(ShadowDOM, { html: notice.answer }) }), _jsx(TableCell, { children: _jsxs(Dropdown, { children: [_jsx(DropdownTrigger, { children: _jsx(Button, { isIconOnly: true, size: 'sm', variant: 'light', children: _jsx(VerticalDotsIcon, { className: 'text-default-300' }) }) }), _jsxs(DropdownMenu, { children: [_jsx(DropdownItem, { onPress: () => {
                                                                    setModalState({
                                                                        mode: 'edit',
                                                                        id: notice.id.toString(),
                                                                        page,
                                                                    });
                                                                    onOpen();
                                                                }, children: "\uC218\uC815" }), _jsx(DropdownItem, { className: 'text-red-500', onPress: () => {
                                                                    // eslint-disable-next-line no-restricted-globals
                                                                    if (!confirm('정말 삭제하시겠습니까?'))
                                                                        return;
                                                                    deleteFaqHook.mutate({
                                                                        id: notice.id.toString(),
                                                                    }, {
                                                                        onSuccess: () => queryClient.invalidateQueries(['getFaqs']),
                                                                    });
                                                                }, children: "\uC0AD\uC81C" })] })] }) })] }, notice.id)) })] }) }), _jsx(CardFooter, { children: _jsx("div", { className: 'flex w-full flex-col items-center', children: _jsx(Pagination, { isCompact: true, showControls: true, showShadow: true, page: page, onChange: setPage, total: Math.max(Math.ceil(faqs.total / 10), 1) }) }) })] }) }));
}
