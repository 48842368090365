import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable react/no-array-index-key */
import { Card, List } from '@component/score/common';
import { AddSubjectView } from '@component/score/common/AddSubjectView';
import { CardView } from '@component/score/common/CardView';
import { ListView } from '@component/score/common/ListView';
import { scoreBlockContext } from '@component/score/test';
import { StandardrizedListView } from '@component/score/test/standarized/listView';
import Spacing from '@simppl/component/Spacing';
import Text from '@simppl/component/Text';
import c from '@simppl/util/c';
import { useContext, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { StandardTestScoreModeContext } from './Provider';
import { subjectToScore } from './util';
export function ViewTest() {
    const { category } = useContext(scoreBlockContext);
    const { changeMode } = useContext(StandardTestScoreModeContext);
    const [mode, setMode] = useState('card');
    const methods = useFormContext();
    // eslint-disable-next-line no-shadow
    const handleChangeMode = (mode) => {
        setMode(mode);
    };
    return (_jsx(_Fragment, { children: _jsxs("div", { className: 'flex flex-col gap-[32px]', children: [_jsxs("div", { className: 'border-Blue-Ct-2 flex w-[886px] flex-col rounded-[16px] border-[1px] px-[51px] py-[32px] shadow-[0px_1px_2px_0px_rgba(20,_25,_97,_0.10),_2px_2px_3px_0px_rgba(20,_25,_97,_0.09),_4px_5px_4px_0px_rgba(20,_25,_97,_0.05),_8px_9px_5px_0px_rgba(20,_25,_97,_0.01),_12px_14px_5px_0px_rgba(20,_25,_97,_0.00)]', children: [_jsx("div", { className: 'flex w-full items-center justify-center gap-[15px]', children: _jsx(Text, { color: 'text-Blue-4', weight: 'Bold', as: 'Label', size: 1, children: "\uD45C\uC900\uD654 \uC131\uC801" }) }), _jsx(Spacing, { height: '32px' }), _jsxs("div", { className: 'flex w-full justify-center gap-[22px]', children: [_jsx("button", { type: 'button', onClick: () => handleChangeMode('card'), children: _jsx(Card, { disabled: mode !== 'card' }) }), _jsx("button", { type: 'button', onClick: () => handleChangeMode('list'), children: _jsx(List, { disabled: mode !== 'list' }) })] }), _jsx(Spacing, { height: '32px' }), _jsxs("div", { className: c('flex w-[784px] flex-wrap gap-[32px]', mode === 'list' && 'flex-col justify-center'), children: [Array.from(category.keys())?.map((testName, index) => {
                                    const data = methods.watch(testName);
                                    return mode === 'card' ? (_jsx(CardView, { type: 'etc', onClick: () => {
                                            changeMode('editScore', {
                                                formName: testName,
                                            });
                                        }, semester: '', testName: testName, score: subjectToScore(testName, data), countOfSubjects: data?.subjectList?.length || 1 }, `${testName}-${index}`)) : (_jsx(ListView, { type: 'etc', onClick: () => {
                                            changeMode('editScore', {
                                                formName: testName,
                                            });
                                        }, semester: '', testName: testName, score: subjectToScore(testName, data), countOfSubjects: data?.subjectList?.length || 1, children: _jsx(StandardrizedListView, { testName: testName, content: testName }) }, `${testName}-${index}`));
                                }), _jsx(AddSubjectView, { title: '\uC131\uC801 \uCD94\uAC00', mode: mode, onClick: () => {
                                        // 학기 추가시 마지막 커리큘럼을 추가하기 위한 로직
                                        changeMode('editScore', {
                                            formName: `test.${methods.watch(`test`)?.length}`,
                                        });
                                    } })] })] }), _jsx(Spacing, { height: '32px' })] }) }));
}
