import axios from '..';
import type {
  DeleteSimulatedApplicationsReqType,
  GetSimulatedApplicationsDetailReqType,
  PatchSimulatedApplicationsReqType,
  PostSimulatedApplicationsReqType,
} from './req';
import type {
  DeleteSimulatedApplicationsResType,
  GetSimulatedApplicationsDetailResType,
  GetSimulatedApplicationsResType,
  PatchSimulatedApplicationsResType,
  PostSimulatedApplicationsResType,
} from './res';

export const simulatedApplications = {
  get: () =>
    axios.get<GetSimulatedApplicationsResType>('/simulated-applications').then((res) => res.data),
  getDetail: ({ simulatedApplicationId }: GetSimulatedApplicationsDetailReqType) =>
    axios
      .get<GetSimulatedApplicationsDetailResType>(
        `/simulated-applications/${simulatedApplicationId}`,
      )
      .then((res) => res.data),
  post: (body: PostSimulatedApplicationsReqType) =>
    axios
      .post<PostSimulatedApplicationsResType>('/simulated-applications', body)
      .then((res) => res.data),
  patch: ({ simulatedApplicationId, ...body }: PatchSimulatedApplicationsReqType) =>
    axios
      .patch<PatchSimulatedApplicationsResType>(
        `/simulated-applications/${simulatedApplicationId}`,
        body,
      )
      .then((res) => res.data),
  delete: ({ simulatedApplicationId }: DeleteSimulatedApplicationsReqType) =>
    axios
      .delete<DeleteSimulatedApplicationsResType>(
        `/simulated-applications/${simulatedApplicationId}`,
      )
      .then((res) => res.data),
};
