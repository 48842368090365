import axios from '..';
import type { DeleteItemsReqType, PatchItemsReqType, PostItemsReqType } from './req';
import type {
  DeleteItemsResType,
  GetItemsResType,
  PatchItemsResType,
  PostItemsResType,
} from './res';

export const items = {
  get: () => axios.get<GetItemsResType>('/items').then((res) => res.data),
  post: (body: PostItemsReqType) =>
    axios.post<PostItemsResType>('/items', body).then((res) => res.data),
  patch: ({ itemId, ...body }: PatchItemsReqType) =>
    axios.patch<PatchItemsResType>(`/items/${itemId}`, body).then((res) => res.data),
  delete: ({ itemId }: DeleteItemsReqType) =>
    axios.delete<DeleteItemsResType>(`/items/${itemId}`).then((res) => res.data),
};
