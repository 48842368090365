import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
import { BackButton, Input, UploaderInput } from '@component/score/common';
import { Minus } from '@component/score/common/icon/Minus';
import { LanguageForm, convertToETCAndotherData, scoreBlockContext } from '@component/score/test';
import { ErrorMessage } from '@hookform/error-message';
import { languageTestSchemaMap } from '@schema/language';
import SelectBox from '@simppl/component/SelectBox';
import Text from '@simppl/component/Text';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { postTest, deleteTestById } from '@simppl/repository/test';
import getImgUrl from '@simppl/util/getImgUrl';
import { useContext, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { LanguageScoreModeContext } from './Provider';
export function EditScore() {
    const { modeState, changeMode } = useContext(LanguageScoreModeContext);
    const methods = useFormContext();
    const [examName, setExamName] = useState('TOEIC');
    const { id } = useParams();
    const queryClient = useQueryClient();
    const postTestHook = useMutation((props) => postTest('language-tests', props));
    const deleteTestHook = useMutation((testId) => deleteTestById('language-tests', testId));
    useEffect(() => {
        if (modeState.formName) {
            methods.setValue(`edit`, methods.watch(modeState.formName));
            setExamName(modeState.formName);
        }
    }, []);
    const onSave = async () => {
        const data = methods.getValues();
        const edit = methods.watch(`edit`);
        try {
            await languageTestSchemaMap.validateSync({
                [examName]: edit,
            });
            const languageTest = convertToETCAndotherData({ ...data, [examName]: { ...edit } });
            const body = {
                userId: id,
                languageTest,
            };
            if (Object.keys(languageTest).length === 0) {
                // 성적을 입력하지 않으면 다음으로 이동하도록 허용해야 합니다.
            }
            else if (Object.keys(data).length > 0) {
                postTestHook.mutate(body, {
                    onSuccess: () => {
                        queryClient.invalidateQueries(['language-tests-testByUserId']);
                        changeMode('viewTest');
                    },
                    onError: (error) => {
                        alert('저장중에 에러가 발생했습니다. 성적을 다시한번 확인해주세요.');
                        if (error?.response?.data?.message.length > 0) {
                            // alert(error?.response?.data?.message);
                        }
                    },
                });
            }
        }
        catch (e) {
            alert(e.message);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: 'border-Blue-Ct-2 relative flex w-full flex-col rounded-[16px] border-[1px] px-[40px] py-[32px] shadow-[0px_1px_2px_0px_rgba(20,_25,_97,_0.10),_2px_2px_3px_0px_rgba(20,_25,_97,_0.09),_4px_5px_4px_0px_rgba(20,_25,_97,_0.05),_8px_9px_5px_0px_rgba(20,_25,_97,_0.01),_12px_14px_5px_0px_rgba(20,_25,_97,_0.00)]', children: [_jsx("div", { className: 'absolute left-[42px] top-[32px]', children: _jsx(BackButton, { onClick: () => {
                                queryClient.invalidateQueries(['language-tests-testByUserId']);
                                changeMode('viewTest');
                                methods.resetField('edit');
                            } }) }), _jsxs("div", { className: 'flex w-full flex-col items-center gap-[48px]', children: [_jsx(Text, { color: 'text-blue-500', weight: 'Bold', as: 'Title', size: 1, children: "\uC5B4\uD559 \uC131\uC801 \uC785\uB825" }), _jsx("div", { className: 'flex w-auto flex-col items-center gap-6 px-[107px] py-[48px]', children: examName === 'ETC' ? (_jsxs(_Fragment, { children: [_jsxs("div", { className: 'flex w-full justify-between gap-[24px]', children: [_jsx(ExamTitle, { onChange: setExamName, name: examName }), _jsx(DeleteExamButton, { onClick: () => {
                                                        // 성적 삭제시 해당 시험 폼 데이터 초기화
                                                        methods.watch(`edit.subjectList`).forEach((_, index) => {
                                                            const testId = methods.watch(`${examName}.subjectList.${index}.languageTestId`);
                                                            if (testId) {
                                                                deleteTestHook.mutate(testId, {
                                                                    onSuccess: () => {
                                                                        methods.resetField('edit');
                                                                        methods.resetField(examName);
                                                                        queryClient.invalidateQueries(['language-tests-testByUserId']);
                                                                        changeMode('viewTest');
                                                                    },
                                                                });
                                                            }
                                                            else {
                                                                methods.resetField('edit');
                                                                methods.resetField(examName);
                                                                queryClient.invalidateQueries(['language-tests-testByUserId']);
                                                                changeMode('viewTest');
                                                            }
                                                        });
                                                    } })] }), _jsxs("div", { className: 'flex w-[809px] flex-col items-start gap-6 ', children: [_jsxs("div", { className: 'flex w-full flex-col gap-2', children: [_jsx(ErrorMessage, { errors: methods.formState.errors, name: 'edit.subjectList', render: ({ message }) => (_jsx("p", { className: 'text-[12px] text-red-600', children: message })) }), methods.watch(`edit.subjectList`)?.map((_, index) => (_jsx(Controller, { name: `edit.subjectList.${index}`, control: methods.control, render: ({ field }) => (_jsxs("div", { className: 'flex flex-col gap-[24px]', children: [_jsxs("div", { className: 'flex items-center gap-[24px] pl-[146px]', children: [_jsx(ExamDateInput, { name: `edit.subjectList.${index}.examDate` }), _jsx(PublishingOrganizationInput, { name: `edit.subjectList.${index}.publishingOrganization` }), _jsx(UploaderInput, { name: `edit.subjectList.${index}`, testName: examName, testType: methods.watch('testType'), alterText: '\uAE30\uD0C0\uC131\uC801.pdf' }), _jsx(DeleteSubjectButton, { onClick: () => {
                                                                                    const testId = methods.watch(`${field.name}.languageTestId`);
                                                                                    if (testId) {
                                                                                        deleteTestHook.mutate(testId, {
                                                                                            onSuccess: () => {
                                                                                                methods.reset((prev) => {
                                                                                                    delete prev.edit.subjectList[index];
                                                                                                    return prev;
                                                                                                });
                                                                                            },
                                                                                        });
                                                                                    }
                                                                                    else {
                                                                                        methods.reset((prev) => {
                                                                                            delete prev.edit.subjectList[index];
                                                                                            return prev;
                                                                                        });
                                                                                    }
                                                                                } })] }), _jsx("div", { className: 'flex justify-start gap-[24px] pl-[146px]', children: _jsxs("div", { className: 'flex justify-end gap-[9px]', children: [_jsx(Input, { type: 'input', ...field, content: 'name', alterText: '\uC2DC\uD5D8\uBA85', inputType: 'stringInput', size: 135, name: `edit.subjectList.${index}.name` }), _jsx(Input, { type: 'input', ...field, content: 'score', alterText: '\uC810\uC218', size: 135, defaultValue: 0, name: `edit.subjectList.${index}.score` }), _jsx(Input, { type: 'input', ...field, content: 'totalScore', alterText: '\uB9CC\uC810', size: 135, defaultValue: 0, name: `edit.subjectList.${index}.totalScore` })] }) })] })) }, index)))] }), _jsx("div", { className: 'flex w-full justify-center', children: _jsx("button", { type: 'button', className: 'w-[513px] rounded-md border-[1px] border-blue-500 bg-blue-500 px-2 py-1 text-[white]', onClick: () => {
                                                            methods.setValue(`edit.subjectList.${methods.watch(`edit.subjectList`)?.length ?? 0}`, {
                                                                name: null,
                                                                score: 0,
                                                                totalScore: 0,
                                                            });
                                                        }, children: "\uC2DC\uD5D8\uCD94\uAC00" }) })] })] })) : (_jsxs(_Fragment, { children: [_jsxs("div", { className: 'flex items-center gap-[24px]', children: [_jsx(ExamTitle, { onChange: setExamName, name: examName }), _jsx(ExamDateInput, { name: 'edit.examDate' }, examName), _jsx(PublishingOrganizationInput, { name: 'edit.publishingOrganization' }), _jsx(UploaderInput, { name: 'edit', testName: examName, testType: methods.watch('testType'), alterText: `${examName} 성적.pdf` }), _jsx(DeleteExamButton, { onClick: () => {
                                                        // 성적 삭제시 해당 시험 폼 데이터 초기화
                                                        const testId = methods.watch(`edit.languageTestId`);
                                                        if (testId) {
                                                            deleteTestHook.mutate(testId, {
                                                                onSuccess: () => {
                                                                    methods.reset((prev) => {
                                                                        delete prev[examName];
                                                                        return prev;
                                                                    });
                                                                    queryClient.invalidateQueries(['language-tests-testByUserId']);
                                                                    changeMode('viewTest');
                                                                },
                                                            });
                                                        }
                                                        else {
                                                            methods.reset((prev) => {
                                                                // eslint-disable-next-line no-param-reassign
                                                                delete prev[examName];
                                                                return prev;
                                                            });
                                                            queryClient.invalidateQueries(['language-tests-testByUserId']);
                                                            changeMode('viewTest');
                                                        }
                                                    } })] }), _jsx("div", { className: 'flex w-[809px] flex-col items-start gap-6', children: _jsx(LanguageForm, { testName: examName }) })] })) })] })] }), _jsx("button", { type: 'button', className: 'h-[40px] w-full rounded-md border-[1px] border-blue-500 bg-blue-500 text-[white]', onClick: onSave, children: "\uC800\uC7A5" })] }));
}
function ExamTitle({ name, onChange }) {
    const { category, list, handleChange } = useContext(scoreBlockContext);
    const methods = useFormContext();
    return (_jsx(SelectBox, { className: 'h-[40px] w-[124px] border-blue-500 text-blue-500', selectedColor: 'text-blue-500', currentValue: name ?? '시험 입력', onClick: (value) => {
            handleChange(name, value);
            methods.resetField(name);
            methods.setValue('edit.examName', value);
            onChange(value);
        }, options: list, disables: Array.from(category.keys()) }));
}
function ExamDateInput({ name }) {
    const methods = useFormContext();
    return (_jsxs("div", { className: 'relative', children: [_jsx("input", { type: 'date', placeholder: '\uC751\uC2DC\uC77C', className: 'h-[40px] w-[155px] rounded-[4px] border-[1px] border-blue-600 p-1', ...methods.register(name) }), _jsx(ErrorMessage, { errors: methods.formState.errors, name: name, render: ({ message }) => (_jsx("p", { className: 'absolute bottom-[-20px] text-[12px] text-red-600', children: message })) })] }));
}
function PublishingOrganizationInput({ name }) {
    const methods = useFormContext();
    return (_jsxs("div", { className: 'relative h-[40px] w-[155px] rounded-[4px]', children: [_jsx("input", { placeholder: '\uBC1C\uAE09\uAE30\uAD00', className: 'h-[40px] w-full rounded-[4px] border-[1px] border-blue-600 p-1 placeholder:text-blue-600', ...methods.register(name) }), _jsx("img", { className: 'absolute right-[8px] top-[50%] translate-y-[-50%]', src: getImgUrl('/asset/Button/PublishingOrganization.svg'), alt: '\uBC1C\uAE09\uAE30\uAD00' }), _jsx(ErrorMessage, { errors: methods.formState.errors, name: name, render: ({ message }) => (_jsx("p", { className: 'absolute bottom-[-20px] text-[12px] text-red-600', children: message })) })] }));
}
function DeleteExamButton({ onClick }) {
    return (_jsxs("button", { type: 'button', className: ' flex   w-[124px]   items-center justify-center gap-[8px] whitespace-nowrap rounded-[4px] border-[1px] border-blue-700 px-[22px] py-[8px] text-blue-700', onClick: onClick, children: [_jsx(Minus, {}), "\uC131\uC801 \uC0AD\uC81C"] }));
}
function DeleteSubjectButton({ onClick }) {
    return (_jsxs("button", { type: 'button', className: ' flex   w-[124px]   items-center justify-center gap-[8px] whitespace-nowrap rounded-[4px] border-[1px] border-blue-700 px-[22px] py-[8px] text-blue-700', onClick: onClick, children: [_jsx(Minus, {}), "\uC2DC\uD5D8 \uC0AD\uC81C"] }));
}
