import useSuspenseQuery from '@simppl/react-query/useSuspenseQuery';
import { getTestSubjectIds } from '@simppl/repository/test';
export function useStandarizedTestIds() {
    const ib = useSuspenseQuery({
        queryKey: ['ib-subjects'],
        queryFn: () => getTestSubjectIds('IB'),
    });
    const ap = useSuspenseQuery({
        queryKey: ['ap-subjects'],
        queryFn: () => getTestSubjectIds('AP'),
    });
    const aLevel = useSuspenseQuery({
        queryKey: ['alevel-subjects'],
        queryFn: () => getTestSubjectIds('A-LEVEL'),
    });
    return {
        IB: ib.data.reduce((acc, { id, name }) => ({ ...acc, [id]: name, [name]: id }), {}) ?? {},
        AP: ap.data.reduce((acc, { id, name }) => ({ ...acc, [id]: name, [name]: id }), {}) ?? {},
        A_LEVEL: aLevel.data.reduce((acc, { id, name }) => ({ ...acc, [id]: name, [name]: id }), {}) ?? {},
    };
}
