import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { VerticalDotsIcon } from '@component/icon/VerticalDotsIcon';
import { StandarizedSubjectModal } from '@component/subject/StandarizedSubjectModal';
import { useSearchAndPagination } from '@hook/useSearchAndPagination';
import { useStandarizedSubjects } from '@hook/useStandarizedSubjects';
import { Button, Card, CardBody, CardFooter, CardHeader, Divider, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Input, Pagination, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, useDisclosure, } from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { standarizedSubjects } from '@simppl/repository/test';
import { ChevronDownIcon, Search } from 'lucide-react';
import { useState } from 'react';
const statusOptions = [
    { name: 'AP', uid: 'AP' },
    { name: 'A-LEVEL', uid: 'A-LEVEL' },
    { name: 'IB', uid: 'IB' },
];
const columns = [
    {
        key: 'subject',
        label: '과목 타입',
    },
    {
        key: 'name',
        label: '이름',
    },
    {
        key: 'settings',
        label: '비고',
    },
];
export function StandarizedSubjectPage() {
    const { IB, AP, 'A-LEVEL': A_LEVEL } = useStandarizedSubjects();
    const [statusFilter, setStatusFilter] = useState('all');
    const { filterValue, filteredItems, items, page, setPage, onSearchChange, onClear } = useSearchAndPagination([...AP, ...A_LEVEL, ...IB], (subject, value) => subject.name.includes(value), (subject) => {
        if (statusFilter !== 'all' && Array.from(statusFilter).length !== statusOptions.length) {
            return Array.from(statusFilter).includes(subject.subject);
        }
        return true;
    });
    const [modalState, setModalState] = useState(null);
    const deleteSubjectHook = useMutation(standarizedSubjects.delete);
    const queryClient = useQueryClient();
    const { isOpen, onClose, onOpen, onOpenChange } = useDisclosure();
    return (_jsxs("div", { className: 'flex min-h-[80%] flex-col', children: [isOpen && modalState !== null && (_jsx(StandarizedSubjectModal, { isOpen: isOpen, onOpenChange: onOpenChange, ...modalState, onClose: () => {
                    onClose();
                    setModalState(null);
                } })), _jsxs(Card, { className: 'grow', children: [_jsx(CardHeader, { children: _jsx("h1", { className: 'text-2xl', children: "\uD45C\uC900\uD654 \uACFC\uBAA9 \uAD00\uB9AC" }) }), _jsx(Divider, {}), _jsxs(CardBody, { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'flex flex-col gap-4', children: _jsxs("div", { className: 'flex w-full flex-row gap-4', children: [_jsxs("div", { className: 'flex max-w-sm flex-1 flex-row items-center gap-4', children: [_jsx(Input, { labelPlacement: 'outside', startContent: _jsx(Search, {}), value: filterValue, onClear: () => onClear(), onValueChange: onSearchChange }), _jsxs(Dropdown, { children: [_jsx(DropdownTrigger, { className: 'hidden sm:flex', children: _jsx(Button, { endContent: _jsx(ChevronDownIcon, { className: 'text-small' }), variant: 'flat', children: "\uC804\uD615 \uAD6C\uBD84" }) }), _jsx(DropdownMenu, { disallowEmptySelection: true, "aria-label": 'Table Columns', closeOnSelect: false, selectedKeys: statusFilter, selectionMode: 'multiple', onSelectionChange: setStatusFilter, children: statusOptions.map((status) => (_jsx(DropdownItem, { className: 'capitalize', children: status.name }, status.uid))) })] })] }), _jsx(Button, { color: 'primary', onPress: () => {
                                                setModalState({ mode: 'add' });
                                                onOpen();
                                            }, children: "\uC138\uBD80 \uACFC\uBAA9 \uC785\uB825 (\uC120\uD0DD)" })] }) }), _jsxs(Table, { children: [_jsx(TableHeader, { columns: columns, children: (column) => _jsx(TableColumn, { children: column.label }, column.key) }), _jsx(TableBody, { items: items, children: (subject) => (_jsxs(TableRow, { className: 'relative', children: [_jsx(TableCell, { children: subject.subject }), _jsx(TableCell, { children: subject.name }), _jsx(TableCell, { className: 'text-center', children: _jsxs(Dropdown, { children: [_jsx(DropdownTrigger, { children: _jsx(Button, { isIconOnly: true, size: 'sm', variant: 'light', children: _jsx(VerticalDotsIcon, { className: 'text-default-300' }) }) }), _jsxs(DropdownMenu, { children: [_jsx(DropdownItem, { onPress: () => {
                                                                            setModalState({
                                                                                mode: 'edit',
                                                                                subjectId: subject.id,
                                                                                type: subject.subject,
                                                                            });
                                                                            onOpen();
                                                                        }, children: "\uD3B8\uC9D1" }), _jsx(DropdownItem, { className: 'text-red-500', onPress: () => {
                                                                            // eslint-disable-next-line no-restricted-globals
                                                                            if (!confirm('정말 삭제하시겠습니까?'))
                                                                                return;
                                                                            deleteSubjectHook.mutate({
                                                                                subject: subject.subject,
                                                                                subjectId: subject.id,
                                                                            }, {
                                                                                onSuccess: () => {
                                                                                    queryClient.invalidateQueries(['standarizedSubjects']);
                                                                                },
                                                                            });
                                                                        }, children: "\uC0AD\uC81C" })] })] }) })] }, subject.id)) })] })] }), _jsx(CardFooter, { children: _jsx("div", { className: 'flex w-full flex-col items-center', children: _jsx(Pagination, { isCompact: true, showControls: true, showShadow: true, page: page, onChange: setPage, total: Math.max(Math.ceil(filteredItems.length / 10), 1) }) }) })] })] }));
}
