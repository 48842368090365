import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CompetitiveUnitModal } from '@component/admission/CompetitiveUnitModal';
import { VerticalDotsIcon } from '@component/icon/VerticalDotsIcon';
import { useCompetitiveUnits } from '@hook/useCompetitiveUnits';
import { useSearchAndPagination } from '@hook/useSearchAndPagination';
import { useSimulatedApplication } from '@hook/useSimulatedApplication';
import { Button, Card, CardBody, CardFooter, CardHeader, Divider, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Input, Link, Pagination, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, useDisclosure, } from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { competitiveUnits } from '@simppl/repository/competitiveUnits';
import { Search } from 'lucide-react';
import { useState } from 'react';
export function CompetitiveUnitPage() {
    const { data } = useCompetitiveUnits();
    const simulatedApplicationMap = useSimulatedApplication().data.reduce((acc, item) => ({ ...acc, [item.simulatedApplicationId]: item.name }), {});
    const { filterValue, filteredItems, items, page, setPage, onSearchChange, onClear } = useSearchAndPagination(data, (userPayment, value) => userPayment.name?.includes(value));
    const [modalState, setModalState] = useState(null);
    const { isOpen, onOpenChange, onOpen, onClose } = useDisclosure();
    const deleteUserPaymentsHook = useMutation(competitiveUnits.delete);
    const queryClient = useQueryClient();
    return (_jsx("div", { className: 'flex min-h-[80%] flex-col', children: _jsxs(Card, { className: 'grow', children: [isOpen && modalState !== null && (_jsx(CompetitiveUnitModal, { isOpen: isOpen, onOpenChange: onOpenChange, ...modalState, onClose: () => {
                        onClose();
                        setModalState(null);
                    } })), _jsx(CardHeader, { children: _jsx("h1", { className: 'text-2xl', children: "\uACBD\uC7C1\uB2E8\uC704 \uAD00\uB9AC" }) }), _jsx(Divider, {}), _jsx(CardBody, { className: 'flex flex-col gap-4', children: _jsxs(Table, { layout: 'auto', topContent: _jsx("div", { className: 'flex flex-col gap-4', children: _jsxs("div", { className: 'flex w-full flex-row justify-between', children: [_jsx("div", { className: 'flex max-w-sm flex-1 flex-row items-center', children: _jsx(Input, { labelPlacement: 'outside', startContent: _jsx(Search, {}), value: filterValue, onClear: () => onClear(), onValueChange: onSearchChange }) }), _jsx(Button, { color: 'primary', onPress: () => {
                                            setModalState({ mode: 'add' });
                                            onOpen();
                                        }, children: "\uACBD\uC7C1\uB2E8\uC704 \uCD94\uAC00" })] }) }), topContentPlacement: 'outside', children: [_jsxs(TableHeader, { children: [_jsx(TableColumn, { children: "\uC774\uB984" }, 'userName'), _jsx(TableColumn, { children: "\uC804\uD615 \uC774\uB984" }, 'userName'), _jsx(TableColumn, { children: "\uBAA8\uC9D1 \uC778\uC6D0" }, 'userName'), _jsx(TableColumn, { children: "high \uBE44\uC728" }, 'userName'), _jsx(TableColumn, { children: "middle \uBE44\uC728" }, 'userName'), _jsx(TableColumn, { children: "low \uBE44\uC728" }, 'userName'), _jsx(TableColumn, { children: "\uBE44\uACE0" }, 'setting')] }), _jsx(TableBody, { items: items, children: (item) => (_jsxs(TableRow, { as: Link, href: `/admission/competitiveUnit/${item.competitiveUnitId}`, className: 'relative cursor-pointer', children: [_jsx(TableCell, { children: item.name }), _jsx(TableCell, { children: simulatedApplicationMap[item.simulatedApplicationId] }), _jsx(TableCell, { children: item.admissionCapacity }), _jsx(TableCell, { children: item.high }), _jsx(TableCell, { children: item.middle }), _jsx(TableCell, { children: item.low }), _jsx(TableCell, { children: _jsxs(Dropdown, { children: [_jsx(DropdownTrigger, { children: _jsx(Button, { isIconOnly: true, size: 'sm', variant: 'light', children: _jsx(VerticalDotsIcon, { className: 'text-default-300' }) }) }), _jsxs(DropdownMenu, { children: [_jsx(DropdownItem, { onPress: () => {
                                                                    setModalState({
                                                                        mode: 'edit',
                                                                        competitiveUnitId: item.competitiveUnitId,
                                                                    });
                                                                    onOpen();
                                                                }, children: "\uC218\uC815" }), _jsx(DropdownItem, { className: 'text-red-500', onPress: () => {
                                                                    deleteUserPaymentsHook.mutate({ competitiveUnitId: item.competitiveUnitId }, {
                                                                        onSuccess: () => {
                                                                            queryClient.invalidateQueries(['getCompetitiveUnits']);
                                                                        },
                                                                    });
                                                                }, children: "\uC0AD\uC81C" })] })] }) })] }, item.competitiveUnitId)) })] }) }), _jsx(CardFooter, { children: _jsx("div", { className: 'flex w-full flex-col items-center', children: _jsx(Pagination, { isCompact: true, showControls: true, showShadow: true, page: page, onChange: setPage, total: Math.max(Math.ceil(filteredItems.length / 10), 1) }) }) })] }) }));
}
