export function Sat(data) {
    return [
        {
            title: '업로드 url',
            type: 'link',
            content: data.transcript,
        },
        {
            title: '응시일자',
            content: data.examDate,
        },
        {
            title: '발행기관',
            content: data.publishingOrganization,
        },
        {
            title: 'ebrw',
            content: data.ebrw,
        },
        {
            title: 'math',
            content: data.math,
        },
    ];
}
