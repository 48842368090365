import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useUserInfo } from '@hook/useUserInfo';
import { Button, useDisclosure } from '@nextui-org/react';
import { UserIdContext } from '@page/user/UserDetailPage';
import { Pencil } from 'lucide-react';
import { useContext } from 'react';
import { EditUserInfoModal } from './modal/EditUserInfoModal';
export const UserDefaultTab = () => {
    const { userId } = useContext(UserIdContext);
    const userInfo = useUserInfo({ userId });
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    return (_jsxs("div", { className: 'flex flex-col gap-4', children: [isOpen && (_jsx(EditUserInfoModal, { isOpen: isOpen, onOpenChange: onOpenChange, userId: userId })), _jsxs("div", { className: 'flex items-center gap-4', children: [_jsx(Button, { onPress: onOpen, isIconOnly: true, children: _jsx(Pencil, {}) }), _jsx("div", { children: "\uAE30\uBCF8 \uC815\uBCF4" })] }), _jsxs("div", { className: 'flex flex-col gap-2', children: [_jsxs("div", { className: 'flex gap-4', children: [_jsx("span", { children: "\uC774\uB984 :" }), _jsx("span", { children: String(userInfo?.name) })] }), _jsxs("div", { className: 'flex gap-4', children: [_jsx("span", { children: "\uB2C9\uB124\uC784 :" }), _jsx("span", { children: String(userInfo?.nickname) })] }), _jsxs("div", { className: 'flex gap-2', children: [_jsx("span", { children: "\uC774\uBA54\uC77C :" }), _jsx("span", { children: String(userInfo?.email) })] }), _jsxs("div", { className: 'flex gap-2', children: [_jsx("span", { children: "\uACE0\uB4F1\uD559\uAD50 :" }), _jsx("span", { children: String(userInfo?.highSchool) })] }), _jsxs("div", { className: 'flex gap-2', children: [_jsx("span", { children: "\uAD6D\uAC00 :" }), _jsx("span", { children: String(userInfo?.country) })] }), _jsxs("div", { className: 'flex gap-2', children: [_jsx("span", { children: "\uC751\uC2DC \uD69F\uC218 :" }), _jsx("span", { children: userInfo?.admissionCount ?? 0 })] })] })] }));
};
