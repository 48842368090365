import * as yup from 'yup';
import { SCHEMA_MESSAGE } from '../message';
export const HSK_format = [
    {
        subject: 'reading',
        property: [
            {
                content: 'reading',
                alterText: '독해',
                type: 'title',
            },
            {
                content: '점수',
                maxValue: 100,
                type: 'input',
                defaultValue: 0,
            },
        ],
    },
    {
        subject: 'listening',
        property: [
            {
                content: 'listening',
                alterText: '듣기',
                type: 'title',
            },
            {
                content: '점수',
                maxValue: 100,
                type: 'input',
                defaultValue: 0,
            },
        ],
    },
    {
        subject: 'writing',
        property: [
            {
                content: 'writing',
                alterText: '작문',
                type: 'title',
            },
            {
                content: '점수',
                maxValue: 100,
                type: 'input',
                defaultValue: 0,
            },
        ],
    },
    {
        subject: 'totalScore',
        property: [
            {
                content: 'totalScore',
                alterText: '총점',
                type: 'title',
            },
            {
                content: '점수',
                maxValue: 300,
                type: 'input',
                defaultValue: 0,
            },
        ],
    },
    {
        subject: 'speaking',
        property: [
            {
                content: 'speaking',
                alterText: '회화 (선택)',
                type: 'title',
            },
            {
                content: '점수',
                maxValue: 100,
                type: 'input',
                defaultValue: 0,
            },
        ],
    },
];
export const HSK_schema = yup
    .object()
    .shape({
    transcript: yup
        .string()
        .required(SCHEMA_MESSAGE.scoreMessage('HSK 시험', '파일을').customMessage('업로드 해주세요.')),
    examDate: yup
        .string()
        .required(SCHEMA_MESSAGE.scoreMessage('HSK 시험', '시험일자를').requiredMessage())
        .typeError(SCHEMA_MESSAGE.scoreMessage('HSK 시험', '시험일자를').requiredMessage()),
    publishingOrganization: yup
        .string()
        .required(SCHEMA_MESSAGE.scoreMessage('HSK 시험', '발급기관을').requiredMessage()),
    reading: yup
        .number()
        .min(0, SCHEMA_MESSAGE.scoreMessage('HSK 시험', '독해점수는').minMessage(0))
        .max(100, SCHEMA_MESSAGE.scoreMessage('HSK 시험', '독해점수는').maxMessage(100))
        .required(SCHEMA_MESSAGE.scoreMessage('HSK 시험', '독해점수를').requiredMessage())
        .typeError(SCHEMA_MESSAGE.scoreMessage('HSK 시험', '독해점수를').requiredMessage()),
    listening: yup
        .number()
        .min(0, SCHEMA_MESSAGE.scoreMessage('HSK 시험', '듣기점수는').minMessage(0))
        .max(100, SCHEMA_MESSAGE.scoreMessage('HSK 시험', '듣기점수는').maxMessage(100))
        .required(SCHEMA_MESSAGE.scoreMessage('HSK 시험', '듣기점수를').requiredMessage())
        .typeError(SCHEMA_MESSAGE.scoreMessage('HSK 시험', '듣기점수를').requiredMessage()),
    writing: yup
        .number()
        .min(0, SCHEMA_MESSAGE.scoreMessage('HSK 시험', '작문점수는').minMessage(0))
        .max(100, SCHEMA_MESSAGE.scoreMessage('HSK 시험', '작문점수는').maxMessage(100))
        .required(SCHEMA_MESSAGE.scoreMessage('HSK 시험', '작문점수를').requiredMessage())
        .typeError(SCHEMA_MESSAGE.scoreMessage('HSK 시험', '작문점수를').requiredMessage()),
    grade: yup
        .mixed()
        .oneOf([1, 2, 3, 4, 5, 6], '')
        .required(SCHEMA_MESSAGE.scoreMessage('HSK 시험', '등급을').requiredMessage('선택')),
    totalScore: yup
        .number()
        .min(0, SCHEMA_MESSAGE.scoreMessage('HSK 시험', '총점은').minMessage(0))
        .max(300, SCHEMA_MESSAGE.scoreMessage('HSK 시험', '총점은').maxMessage(300))
        .required(SCHEMA_MESSAGE.scoreMessage('HSK 시험', '총점을').requiredMessage())
        .typeError(SCHEMA_MESSAGE.scoreMessage('HSK 시험', '총점을').requiredMessage()),
})
    .default(null)
    .nullable();
