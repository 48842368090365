import useSuspenseQuery from '@simppl/react-query/useSuspenseQuery';
import { faq } from '@simppl/repository/faq';
export const useFaqs = (body) => {
    const { data } = useSuspenseQuery({
        queryKey: ['getFaqs', body.page, body.search],
        queryFn: () => faq.get(body),
        queryOptions: {
            keepPreviousData: true,
        }
    });
    return { total: data.total, posts: data.posts };
};
