import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import { Input, Select, Title } from '@component/score/common/form';
import { DELE_format } from '@schema/language/dele';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
export function DELEForm({ content }) {
    const methods = useFormContext();
    useEffect(() => {
        const languageTestId = methods.watch(`${content}.languageTestId`);
        if (languageTestId)
            methods.setValue(`${content}.testId`, languageTestId);
    }, []);
    return (_jsxs("div", { className: 'grid gap-[24px] [grid-template-areas:"grade_others"]', children: [_jsx("div", { className: '[grid-area:grade]', children: _jsx(Select, { category: {
                        A1: 'A1',
                        A2: 'A2',
                        B1: 'B1',
                        B2: 'B2',
                        C1: 'C1',
                        C2: 'C2',
                    }, content: '\uB4F1\uAE09', name: `${content}.grade`, type: 'select' }) }), _jsx("div", { className: 'flex flex-col gap-[24px] [grid-area:others]', children: DELE_format.map(({ subject, property }, index) => (_jsx("div", { className: 'flex gap-[24px]', children: property.map((propertyItem) => (_jsxs(_Fragment, { children: [propertyItem.type === 'input' && (_jsx(Input, { ...propertyItem, name: `${content}.${subject}` }, propertyItem.content)), propertyItem.type === 'title' && (_jsx(Title, { ...propertyItem }, propertyItem.content))] }))) }, index))) })] }));
}
