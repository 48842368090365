import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-shadow */
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Switch, Textarea, } from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import { pathHighschools } from '@simppl/repository/highschools';
import { useState } from 'react';
export const HighSchoolManageModal = ({ highSchoolId, name, isAprroved, isOpen, onOpenChange, }) => {
    const [isApprove, setIsApprove] = useState(isAprroved);
    const [highSchoolName, setHighSchoolName] = useState(name);
    const patchHighSchoolHook = useMutation((body) => pathHighschools(body.highSchoolId, body));
    return (_jsx(Modal, { isOpen: isOpen, onOpenChange: onOpenChange, children: _jsx(ModalContent, { children: (onClose) => (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { className: 'flex flex-col gap-1', children: "\uACE0\uB4F1\uD559\uAD50 \uC2B9\uC778 \uAD00\uB9AC" }), _jsx(ModalBody, { children: _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsxs("div", { className: 'flex gap-4', children: [_jsx("p", { children: "\uC2B9\uC778 \uC5EC\uBD80" }), _jsx(Switch, { defaultSelected: false, color: 'default', isSelected: isApprove, onValueChange: setIsApprove })] }), _jsxs("div", { className: 'flex flex-col', children: [_jsx("p", { children: "\uD559\uAD50 \uC774\uB984" }), _jsx(Textarea, { placeholder: '\uD559\uAD50 \uC774\uB984\uC744 \uC801\uC5B4\uC8FC\uC138\uC694.', value: highSchoolName, onValueChange: setHighSchoolName })] })] }) }), _jsxs(ModalFooter, { children: [_jsx(Button, { color: 'danger', variant: 'light', onPress: onClose, children: "\uCDE8\uC18C" }), _jsx(Button, { type: 'submit', form: 'authorization', color: 'primary', onPress: () => {
                                    patchHighSchoolHook.mutate({
                                        highSchoolId,
                                        isApprove,
                                        name: highSchoolName,
                                    }, {
                                        onSuccess: () => onClose(),
                                    });
                                }, children: "\uC800\uC7A5" })] })] })) }) }));
};
