import { generateSessionStorage, generateStorage } from '@simppl/util/storage';

const getStorage = () =>
  LoginStorage.getAutoLoginState() ? safeLoginStorage : safeLoginSessionStorage;
const safeLoginStorage = generateStorage();
const safeLoginSessionStorage = generateSessionStorage();

const key = 'simppl-refresh-token';
const accessTokenKey = 'simppl-access-token';
const AUTO_LOGIN_KEY = 'simppl-auto-login';

type TokenType = 'access' | 'refresh';

const LoginStorage = {
  getAutoLoginState: () => safeLoginStorage.get(AUTO_LOGIN_KEY) === 'true',
  setAutoLoginState: (value: boolean) => safeLoginStorage.set(AUTO_LOGIN_KEY, String(value)),
  getToken: ({ type }: { type: TokenType }) => {
    const storage = getStorage();
    if (type === 'access') return storage.get(accessTokenKey);
    return storage.get(key);
  },
  setToken: ({ value, type }: { value: string; type: TokenType }) => {
    const storage = getStorage();
    type === 'access' ? storage.set(accessTokenKey, value) : storage.set(key, value);
  },
  removeToken: ({ type }: { type?: TokenType }) => {
    const storage = getStorage();
    if (type === undefined) {
      storage.remove(key);
      storage.remove(accessTokenKey);
    }
    if (type === 'access') storage.remove(accessTokenKey);
    if (type === 'refresh') storage.remove(key);
  },
  isLogin: () => {
    const refreshToken = LoginStorage.getToken({ type: 'refresh' });
    const _accessToken = LoginStorage.getToken({ type: 'access' });
    return !!(_accessToken || refreshToken);
  },
};

export default LoginStorage;
