import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-shadow */
import { CategoryModal } from '@component/community/category/CategoryModal';
import { VerticalDotsIcon } from '@component/icon/VerticalDotsIcon';
import { useCategories } from '@hook/useCategories';
import { useSearchAndPagination } from '@hook/useSearchAndPagination';
import { Button, Card, CardBody, CardFooter, CardHeader, Divider, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Input, Pagination, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, useDisclosure, } from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { category } from '@simppl/repository/categories';
import { ChevronDownIcon, Search } from 'lucide-react';
import { useMemo, useState } from 'react';
const columns = [
    {
        key: 'title',
        label: '카테고리',
    },
    {
        key: 'setting',
        label: '비고',
    },
];
const categoryMap = {
    NOTICE: '공지사항',
    FAQ: '자주 묻는 질문',
    INQUIRY: '1:1문의',
};
export default function CategoryPage() {
    const [selectedKeys, setSelectedKeys] = useState(new Set(['NOTICE']));
    const selectedValue = useMemo(() => Array.from(selectedKeys).join(', ').replace(/_/g, ' '), [selectedKeys]);
    const categories = useCategories({
        type: Array.from(selectedKeys)[0],
    });
    const { filterValue, filteredItems, items, page, setPage, onSearchChange, onClear } = useSearchAndPagination(categories.categories.children, (category, searchValue) => category.title.includes(searchValue));
    const [modalState, setModalState] = useState(null);
    const { isOpen, onOpenChange, onOpen, onClose } = useDisclosure();
    const deleteNoticeHook = useMutation(category.delete);
    const queryClient = useQueryClient();
    return (_jsx("div", { className: 'flex min-h-[80%] flex-col', children: _jsxs(Card, { className: 'grow', children: [isOpen && modalState !== null && (_jsx(CategoryModal, { isOpen: isOpen, onOpenChange: onOpenChange, ...modalState, onClose: () => {
                        onClose();
                        setModalState(null);
                    } })), _jsx(CardHeader, { children: _jsx("h1", { className: 'text-2xl', children: "\uCE74\uD14C\uACE0\uB9AC \uAD00\uB9AC" }) }), _jsx(Divider, {}), _jsx(CardBody, { className: 'flex flex-col gap-4', children: _jsxs(Table, { layout: 'auto', topContent: _jsx("div", { className: 'flex flex-col gap-4', children: _jsxs("div", { className: 'flex w-full flex-row justify-between', children: [_jsxs("div", { className: 'flex max-w-sm flex-1 flex-row items-center', children: [_jsx(Input, { labelPlacement: 'outside', startContent: _jsx(Search, {}), value: filterValue, onClear: () => onClear(), onValueChange: onSearchChange }), _jsxs(Dropdown, { children: [_jsx(DropdownTrigger, { className: 'hidden sm:flex', children: _jsx(Button, { endContent: _jsx(ChevronDownIcon, { className: 'text-small' }), children: categoryMap[selectedValue] }) }), _jsx(DropdownMenu, { disallowEmptySelection: true, "aria-label": 'Table Columns', closeOnSelect: false, selectionMode: 'single', selectedKeys: selectedKeys, onSelectionChange: setSelectedKeys, children: ['NOTICE', 'FAQ', 'INQUIRY'].map((categoryItem) => (_jsx(DropdownItem, { className: 'capitalize', children: categoryMap[categoryItem] }, categoryItem))) })] })] }), _jsx(Button, { color: 'primary', onPress: () => {
                                            setModalState({
                                                mode: 'add',
                                            });
                                            onOpen();
                                        }, children: "\uCE74\uD14C\uACE0\uB9AC \uCD94\uAC00" })] }) }), topContentPlacement: 'outside', children: [_jsx(TableHeader, { columns: columns, children: (column) => _jsx(TableColumn, { children: column.label }, column.key) }), _jsx(TableBody, { items: items, children: (notice) => (_jsxs(TableRow, { children: [_jsx(TableCell, { children: notice.title }), _jsx(TableCell, { children: _jsxs(Dropdown, { children: [_jsx(DropdownTrigger, { children: _jsx(Button, { isIconOnly: true, size: 'sm', variant: 'light', children: _jsx(VerticalDotsIcon, { className: 'text-default-300' }) }) }), _jsxs(DropdownMenu, { children: [_jsx(DropdownItem, { onPress: () => {
                                                                    setModalState({
                                                                        mode: 'edit',
                                                                        id: notice.id.toString(),
                                                                        type: Array.from(selectedKeys)[0],
                                                                    });
                                                                    onOpen();
                                                                }, children: "\uC218\uC815" }), _jsx(DropdownItem, { className: 'text-red-500', onPress: () => {
                                                                    // eslint-disable-next-line no-restricted-globals
                                                                    if (!confirm('정말 삭제하시겠습니까?'))
                                                                        return;
                                                                    deleteNoticeHook.mutate({
                                                                        id: notice.id.toString(),
                                                                    }, {
                                                                        onSuccess: () => queryClient.invalidateQueries(['getCategories']),
                                                                    });
                                                                }, children: "\uC0AD\uC81C" })] })] }) })] }, notice.id)) })] }) }), _jsx(CardFooter, { children: _jsx("div", { className: 'flex w-full flex-col items-center', children: _jsx(Pagination, { isCompact: true, showControls: true, showShadow: true, page: page, onChange: setPage, total: Math.max(Math.ceil(filteredItems.length / 10), 1) }) }) })] }) }));
}
