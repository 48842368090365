import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-shadow */
import useAdminUserInfo from '@hook/useAdminUserInfo';
import useMutation from '@simppl/react-query/useMutation';
import { postFiles } from '@simppl/repository/files';
export function UploadFile({ name, testType, testName, accept, onClose, setName, }) {
    const postFilesHook = useMutation((props) => postFiles(props));
    const userInfo = useAdminUserInfo();
    return (_jsxs(_Fragment, { children: [_jsx("label", { htmlFor: `${name}.uploadFile`, className: 'bg-default-100 text-small m-auto flex h-[40px] w-full items-center overflow-hidden whitespace-nowrap rounded-[12px] px-3', children: _jsx("p", { children: name === '' ? '파일 첨부하기' : name }) }), _jsx("input", { type: 'file', id: `${name}.uploadFile`, accept: accept, multiple: false, placeholder: '\uD30C\uC77C \uCCA8\uBD80', className: 'hidden', onClick: () => {
                    onClose?.();
                }, onChange: (e) => {
                    if (!e.target.files || e.target.files?.length === 0) {
                        onClose?.();
                        return;
                    }
                    if (testName === '') {
                        alert('이름을 입력해주세요.');
                        return;
                    }
                    const file = e.target.files[0];
                    if (file.size > 0) {
                        if (file.size > 8 * 1024 * 1024) {
                            alert('8MB 이하의 파일만 첨부 가능합니다.');
                            return;
                        }
                        const formData = new FormData();
                        formData.append('file', file);
                        formData.append('userId', userInfo?.id || '');
                        formData.append('testType', testType);
                        formData.append('testName', testName);
                        formData.append('fileName', file.name);
                        postFilesHook.mutate(formData, {
                            onSuccess: (data) => {
                                setName(data.fileUri);
                                onClose?.();
                            },
                        });
                    }
                    else {
                        alert('파일이 업로드되지 않았습니다. 고객센터로 문의부탁드립니다.');
                    }
                } })] }));
}
