import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ErrorMessage } from '@hookform/error-message';
import Text from '@simppl/component/Text';
import getImgUrl from '@simppl/util/getImgUrl';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { UploadFile } from './UploadFile';
import { UploaderPopUp } from './UploaderPopUp';
export function UploaderInput({ name, testName, testType, alterText, }) {
    const [open, setOpen] = useState(false);
    const methods = useFormContext();
    return (_jsxs("div", { className: 'relative', children: [_jsxs("div", { className: ' flex h-[40px] w-[155px] cursor-pointer items-center justify-between overflow-hidden whitespace-nowrap rounded-[4px] border-[1px] border-blue-600 px-[8px]', onClick: () => setOpen(true), children: [methods.watch(`${name}.transcript`) ? (_jsx(Text, { color: 'text-blue-500', weight: 'Regular', as: 'Label', size: 1, children: methods.watch(`${name}.transcript`)?.length > 0
                            ? decodeURIComponent(methods.watch(`${name}.transcript`).split('/').at(-1))
                            : alterText })) : (_jsx(Text, { color: 'text-blue-500', weight: 'Regular', as: 'Label', size: 1, children: "\uD30C\uC77C \uCCA8\uBD80" })), _jsx("img", { src: getImgUrl('/asset/Button/Upload.svg'), alt: 'upload' })] }), _jsx(UploaderPopUp, { name: name, open: open, onClose: () => setOpen(false) }), _jsx(UploadFile, { name: name, testName: testName, testType: testType, onClose: () => setOpen(false) }), _jsx(ErrorMessage, { errors: methods.formState.errors, name: `${name}.transcript`, render: ({ message }) => (_jsx("p", { className: 'absolute bottom-[-20px] text-[12px] text-red-600', children: message })) })] }));
}
