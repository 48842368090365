import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/destructuring-assignment */
import { useSimulatedApplication } from '@hook/useSimulatedApplication';
import { useUniversitiesMajors } from '@hook/useUniversitiesMajors';
import { Autocomplete, AutocompleteItem, Button, Checkbox, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Select, SelectItem, } from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { admission } from '@simppl/repository/admission';
import { useState } from 'react';
export function AddAdmissionModal(props) {
    const [majorKeys, setMajorKeys] = useState(new Set(['']));
    const [selectedKeys, setSelectedKeys] = useState('');
    const [isDupplication, setIsDupplication] = useState(false);
    const [name, setName] = useState('');
    const [appliedCount, setAppliedCount] = useState(0);
    const simulatedApplication = useSimulatedApplication();
    const simulatedApplicationMap = simulatedApplication.data.reduce((acc, item) => ({ ...acc, [item.simulatedApplicationId]: item }), {});
    const majors = useUniversitiesMajors({
        universityId: simulatedApplicationMap[selectedKeys]?.universityId,
    }).data;
    const queryClient = useQueryClient();
    const postUniversitiesHook = useMutation(admission.post);
    return (_jsx(Modal, { isOpen: props.isOpen, onOpenChange: props.onOpenChange, children: _jsx(ModalContent, { children: () => (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { className: 'flex flex-col gap-1', children: "\uBAA8\uC9D1\uB2E8\uC704 \uCD94\uAC00" }), _jsxs(ModalBody, { children: [_jsx(Input, { type: 'text', value: name, onChange: (e) => setName(e.target.value), label: '\uC774\uB984', labelPlacement: 'outside' }), _jsx(Input, { type: 'number', value: appliedCount, onChange: (e) => setAppliedCount(parseInt(e.target.value, 10)), label: '\uC9C0\uC6D0\uC778\uC6D0', labelPlacement: 'outside' }), _jsx(Checkbox, { isSelected: isDupplication, onValueChange: setIsDupplication, children: "\uC911\uBCF5 \uC9C0\uC6D0 \uC5EC\uBD80" }), _jsx(Autocomplete, { selectedKey: selectedKeys, onSelectionChange: setSelectedKeys, children: simulatedApplication.data.map((categoryItem) => (_jsx(AutocompleteItem, { value: categoryItem.simulatedApplicationId, className: 'capitalize', children: categoryItem.name }, categoryItem.simulatedApplicationId))) }), _jsx(Select, { disallowEmptySelection: true, "aria-label": 'Table Columns', selectionMode: 'multiple', selectedKeys: majorKeys, onSelectionChange: setMajorKeys, children: majors?.map((categoryItem) => (_jsx(SelectItem, { value: categoryItem.universityMajorId, className: 'capitalize', children: categoryItem.name }, categoryItem.universityMajorId))) })] }), _jsxs(ModalFooter, { children: [_jsx(Button, { color: 'danger', variant: 'light', onPress: props.onClose, children: "\uCDE8\uC18C" }), _jsx(Button, { type: 'submit', form: 'authorization', color: 'primary', onPress: () => {
                                    postUniversitiesHook.mutate({
                                        type: 'medical',
                                        universityMajorIdList: Array.from(majorKeys).filter((item) => item !== ''),
                                        simulatedApplicationId: selectedKeys,
                                        isDupplication: false,
                                        appliedCount,
                                        name,
                                    }, {
                                        onSuccess: () => {
                                            queryClient.invalidateQueries(['getCompetitiveUnits']);
                                            props.onClose();
                                        },
                                    });
                                }, children: "\uC800\uC7A5" })] })] })) }) }));
}
