import axios from '..';
import type {
  DeleteNoticesCommentsReqType,
  DeleteNoticesReqType,
  GetNoticesCommentsReqType,
  GetNoticesIdAdjacentReqType,
  GetNoticesIdReqType,
  GetNoticesReqType,
  PatchNoticesCommentsReqType,
  PatchNoticesReqType,
  PostNoticesCommentsReqType,
  PostNoticesReqType,
} from './req';
import type {
  DeleteNoticesCommentsResType,
  DeleteNoticesResType,
  GetFixedNoticesResType,
  GetNoticesIdAdjacentResType,
  GetNoticesIdCommentsResType,
  GetNoticesIdResType,
  GetNoticesResType,
  PatchNoticesCommentsResType,
  PatchNoticesResType,
  PostNoticesCommentsResType,
  PostNoticesResType,
} from './res';

export const getNoticesId = ({ id }: GetNoticesIdReqType) =>
  axios.get<GetNoticesIdResType>(`/notices/${id}`).then((res) => res.data);

export const getNoticesIdAdjacent = ({ id }: GetNoticesIdAdjacentReqType) =>
  axios.get<GetNoticesIdAdjacentResType>(`/notices/${id}/adjacent`).then((res) => res.data);

export const notice = {
  fixedGet: () => axios.get<GetFixedNoticesResType>('/fixed-notices').then((res) => res.data),
  get: (params: GetNoticesReqType) =>
    axios.get<GetNoticesResType>('/notices', { params }).then((res) => res.data),
  post: (body: PostNoticesReqType) =>
    axios.post<PostNoticesResType>('/notices', body).then((res) => res.data),
  patch: ({ id, ...body }: PatchNoticesReqType) =>
    axios.patch<PatchNoticesResType>(`/notices/${id}`, body).then((res) => res.data),
  delete: ({ id }: DeleteNoticesReqType) =>
    axios.delete<DeleteNoticesResType>(`/notices/${id}`).then((res) => res.data),
  getAdmin: (params: GetNoticesReqType) =>
    axios.get<GetNoticesResType>('/admins/notices', { params }).then((res) => res.data),
};

export const noticeComment = {
  get: ({ id, ...params }: GetNoticesCommentsReqType) =>
    axios
      .get<GetNoticesIdCommentsResType>(`/notices/${id}/comments`, { params })
      .then((res) => res.data),
  post: ({ id, ...body }: PostNoticesCommentsReqType) =>
    axios.post<PostNoticesCommentsResType>(`/notices/${id}/comments`, body).then((res) => res.data),
  patch: ({ id, commentId, ...body }: PatchNoticesCommentsReqType) =>
    axios
      .patch<PatchNoticesCommentsResType>(`/notices/${id}/comments/${commentId}`, body)
      .then((res) => res.data),
  delete: ({ id, commentId }: DeleteNoticesCommentsReqType) =>
    axios
      .delete<DeleteNoticesCommentsResType>(`/notices/${id}/comments/${commentId}`)
      .then((res) => res.data),
};
