import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import { Plus, UploaderButton } from '@component/score/common';
import { useCountries } from '@hook/useCountries';
import { useReportCardSubjectsIds } from '@hook/useReportCardSubjectsIds';
import useTestByUserId from '@hook/useTestByUserId';
import Spacing from '@simppl/component/Spacing';
import Text from '@simppl/component/Text';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { EditHighSchool } from './EditHighSchool';
import { EditScore } from './EditScore';
import { HighSchoolModeContext, HighSchoolModeProvider } from './Provider';
import { ViewTest } from './ViewTest';
const HighSchoolPage = () => {
    const [mode, setMode] = useState('viewTest');
    const [highSchools, setHighSchools] = useState([]);
    const { countryIdNameMap } = useCountries({});
    const subjectIds = useReportCardSubjectsIds();
    const { id } = useParams();
    const userData = useTestByUserId('report-cards', id);
    const highSchoolTestMap = userData.reportCardList.reduce((acc, reportCard) => {
        if (acc[reportCard.highschoolId]) {
            acc[reportCard.highschoolId].push(reportCard);
        }
        else {
            acc[reportCard.highschoolId] = [reportCard];
        }
        return acc;
    }, {});
    const methods = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
    });
    useEffect(() => {
        if (userData) {
            methods.setValue('transcript', userData.transcript);
        }
        if (highSchoolTestMap && countryIdNameMap) {
            setHighSchools(Object.keys(highSchoolTestMap).map((id) => ({
                highschoolId: id,
                countryId: highSchoolTestMap[id][0].countryId,
                countryName: countryIdNameMap[highSchoolTestMap[id][0].countryId],
                schoolName: highSchoolTestMap[id][0].highschoolName,
            })));
        }
    }, [userData]);
    const appendHighSchool = (data) => {
        setHighSchools([...highSchools, data]);
    };
    const updateHighSchool = (index, data) => {
        const newHighSchools = [...highSchools];
        newHighSchools[index] = data;
        setHighSchools(newHighSchools);
    };
    const removeHighSchool = (highschoolId) => {
        setHighSchools((prev) => prev.filter((data) => data.highschoolId !== highschoolId));
    };
    return (_jsx(HighSchoolModeProvider, { mode: mode, setMode: setMode, children: _jsx(FormProvider, { ...methods, children: _jsxs("form", { id: 'test', 
                // onSubmit={methods.handleSubmit(onSubmit, (errors) =>
                //   console.log(errors, methods.getValues()),
                // )} // 에러 확인을 위해 달았습니다.
                className: 'flex w-[915px] flex-col items-center', children: [_jsx(UploaderButton, { name: '', testName: 'high-school-tests', testType: 'high-school-tests' }), _jsx(Spacing, { height: '32px' }), mode === 'noData' && _jsx(CreateSchoolButton, { appendHighSchool: appendHighSchool }), mode === 'editSchool' && (_jsx(EditHighSchool, { removeHighSchool: removeHighSchool, updateHighSchool: updateHighSchool })), mode === 'viewTest' && (_jsx(ViewTest, { highSchools: highSchools.map((data) => ({
                            ...data,
                            reportCardList: highSchoolTestMap[data.highschoolId] ?? [],
                        })), appendHighSchool: appendHighSchool, removeHighSchool: removeHighSchool })), mode === 'editScore' && _jsx(EditScore, {}), _jsx(Spacing, { height: '80px' })] }) }) }));
};
function CreateSchoolButton({ appendHighSchool }) {
    const { changeMode } = useContext(HighSchoolModeContext);
    return (_jsxs("button", { type: 'button', className: 'flex h-[58px] w-full items-center justify-center gap-[8px] rounded-[8px] border-[1px] border-blue-500 text-blue-500 ', onClick: () => {
            changeMode('editSchool', {
                appendHighSchool,
                mode: 'new',
            });
        }, children: [_jsx(Plus, {}), _jsx(Text, { color: 'text-blue-500', as: 'Title', size: 2, weight: 'Medium', children: "\uD559\uAD50\uB97C \uCD94\uAC00\uD558\uC5EC \uC131\uC801 \uC785\uB825 \uC2DC\uC791" })] }));
}
export default HighSchoolPage;
