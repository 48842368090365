import { AxiosError } from 'axios';
import type { AxiosResponse, InternalAxiosRequestConfig } from 'axios';

export default class ApiError<T = unknown> extends AxiosError<T> {
  config?: InternalAxiosRequestConfig;

  code?: string;

  request?: any;

  response?: AxiosResponse<T>;

  isAxiosError: boolean;

  toJSON: () => object;

  constructor(error: AxiosError<T>, message?: string) {
    super(message ?? error.message);
    this.name = `[ ${error.config?.method?.toUpperCase()} ]  ${error.config?.url ?? 'API'} [ ${error
      .response?.status} ]`;
    this.stack = error.stack;
    this.config = error.config;
    this.code = error.code;
    this.request = error.request;
    this.response = error.response;
    this.isAxiosError = error.isAxiosError;
    this.toJSON = error.toJSON;
  }
}
