import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Text from '@simppl/component/Text';
import { useFormContext } from 'react-hook-form';
export function DeleView({ content }) {
    const methods = useFormContext();
    const data = methods.watch(`${content}`);
    return (_jsxs("div", { className: 'relative flex flex-col justify-between', children: [_jsx("div", { className: 'absolute left-[-40px] top-[0px] flex h-[40px] flex-col justify-center', children: _jsx(Text, { color: 'text-blue-700', weight: 'Medium', as: 'Label', size: 1, children: data.grade }) }), Object.entries({
                듣기: data.listening,
                독해: data.reading,
                쓰기: data.writing,
                회화: data.speaking,
            }).map(([key, value]) => (_jsxs("div", { className: 'flex justify-between', children: [_jsx("div", { className: 'w-[240px] px-[16px] py-[8px]', children: _jsx(Text, { color: 'text-blue-700', weight: 'Medium', as: 'Label', size: 1, children: key }) }), _jsxs("div", { className: 'flex w-[112px] justify-between px-[16px] py-[8px]', children: [_jsx(Text, { color: 'text-blue-500', weight: 'Regular', as: 'Label', size: 1, children: "\uC810\uC218" }), _jsx(Text, { color: 'text-Blue-5', weight: 'Regular', as: 'Label', size: 1, children: value })] })] }, key)))] }));
}
