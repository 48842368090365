import useQuery from '@simppl/react-query/useQuery';
import { universities } from '@simppl/repository/universities';
export const useAdmissionsUniversities = (body) => {
    const { data, refetch } = useQuery(['useAdmissionsUniversities', body.name, body.page, body.size, body.type], () => universities.get(body), {
        keepPreviousData: true,
    });
    return {
        data: data?.data,
        refetch,
    };
};
