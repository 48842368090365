import type { CSSProperties, PropsWithChildren } from 'react';

import Box from './Box';

type SpacingType = PropsWithChildren<Pick<CSSProperties, 'width' | 'height'>>;
const Spacing = ({ children, ...styles }: SpacingType) => (
  <Box
    width='100%'
    {...styles}
  >
    {children}
  </Box>
);

export default Spacing;
