import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/destructuring-assignment */
import { useCompetitiveUnits } from '@hook/useCompetitiveUnits';
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Select, SelectItem, } from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { admissionCompetitiveUnit } from '@simppl/repository/admission';
import { useMemo, useState } from 'react';
export function AddAdmissionToCompetitiveUnitModal(props) {
    const [simulatedKeys, setSimulatedKeys] = useState(new Set(['']));
    const selectedSimulatedValue = useMemo(() => Array.from(simulatedKeys).join(', ').replace(/_/g, ' '), [simulatedKeys]);
    const competitiveUnit = useCompetitiveUnits();
    const queryClient = useQueryClient();
    const postUniversitiesHook = useMutation(admissionCompetitiveUnit.patch);
    return (_jsx(Modal, { isOpen: props.isOpen, onOpenChange: props.onOpenChange, children: _jsx(ModalContent, { children: () => (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { className: 'flex flex-col gap-1', children: "\uACBD\uC7C1\uB2E8\uC704\uC5D0 \uBAA8\uC9D1\uB2E8\uC704 \uCD94\uAC00" }), _jsx(ModalBody, { children: _jsx(Select, { disallowEmptySelection: true, "aria-label": 'Table Columns', selectionMode: 'single', selectedKeys: simulatedKeys, onSelectionChange: setSimulatedKeys, children: competitiveUnit.data.map((categoryItem) => (_jsx(SelectItem, { value: categoryItem.competitiveUnitId, className: 'capitalize', children: categoryItem.name }, categoryItem.competitiveUnitId))) }) }), _jsxs(ModalFooter, { children: [_jsx(Button, { color: 'danger', variant: 'light', onPress: props.onClose, children: "\uCDE8\uC18C" }), _jsx(Button, { type: 'submit', form: 'authorization', color: 'primary', onPress: () => {
                                    postUniversitiesHook.mutate({
                                        admissionId: props.admissionId,
                                        competitiveUnitId: selectedSimulatedValue,
                                    }, {
                                        onSuccess: () => {
                                            queryClient.invalidateQueries(['getAdmissions']);
                                            props.onClose();
                                        },
                                    });
                                }, children: "\uC800\uC7A5" })] })] })) }) }));
}
