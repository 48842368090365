import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react-hooks/exhaustive-deps */
import Text from '@simppl/component/Text';
import { useEffect, useRef } from 'react';
export function UploaderPopUp({ name, open, onClose }) {
    const dialogRef = useRef(null);
    useEffect(() => {
        if (dialogRef.current === null)
            return;
        if (open) {
            dialogRef.current.showModal();
        }
        else {
            dialogRef.current.close();
        }
    }, [open]);
    useEffect(() => {
        const handler = (event) => {
            if (!dialogRef.current) {
                return;
            }
            const rect = dialogRef.current.getBoundingClientRect();
            const isInDialog = rect.top <= event.clientY &&
                event.clientY <= rect.top + rect.height &&
                rect.left <= event.clientX &&
                event.clientX <= rect.left + rect.width;
            if (!isInDialog) {
                onClose();
            }
        };
        document.addEventListener('click', handler, true);
        return () => {
            document.removeEventListener('click', handler);
        };
    }, []);
    useEffect(() => {
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                onClose();
            }
        });
    }, []);
    return (open && (_jsxs("dialog", { ref: dialogRef, className: 'border-Blue-Ct-2 flex h-[164px] w-[359px] flex-col items-center justify-between rounded-[16px] border-[0.5px] px-[40px] py-[32px] shadow-[0px_1px_2px_0px_rgba(20,_25,_97,_0.10),_2px_2px_3px_0px_rgba(20,_25,_97,_0.09),_4px_5px_4px_0px_rgba(20,_25,_97,_0.05),_8px_9px_5px_0px_rgba(20,_25,_97,_0.01),_12px_14px_5px_0px_rgba(20,_25,_97,_0.00)] outline-none', children: [_jsx(Text, { color: 'text-blue-500', weight: 'Medium', as: 'Body', size: 2, className: 'whitespace-pre text-center', children: `증빙서류로 성적표 파일이 반드시 첨부되어야 하며, \n8MB 이하의 단일 pdf 파일로 합쳐서 제출해주세요.` }), _jsx("label", { htmlFor: `${name}.uploadFile`, className: 'font-Pretendard-Medium flex h-[28px] w-[121px] cursor-pointer items-center justify-center whitespace-nowrap rounded-[6px] border-[1px] border-blue-600 bg-blue-500 px-[22px] py-[4px] text-white', children: "\uD30C\uC77C \uCCA8\uBD80\uD558\uAE30" })] })));
}
