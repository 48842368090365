import axios from '..';
import type {
  DeletePaymentsHistoriesReqType,
  PatchPaymentsHistoriesReqType,
  PostPaymentsHistoriesReqType,
} from './req';
import type {
  DeletePaymentsHistoriesResType,
  GetPaymentsHistoriesResType,
  PatchPaymentsHistoriesResType,
  PostPaymentsHistoriesResType,
} from './res';

export const paymentsHistories = {
  get: () => axios.get<GetPaymentsHistoriesResType>('/payment-histories').then((res) => res.data),
  post: (body: PostPaymentsHistoriesReqType) =>
    axios.post<PostPaymentsHistoriesResType>('/payment-histories', body).then((res) => res.data),
  patch: ({ paymentHistoryId, ...body }: PatchPaymentsHistoriesReqType) =>
    axios
      .patch<PatchPaymentsHistoriesResType>(`/payment-histories/${paymentHistoryId}`, body)
      .then((res) => res.data),
  delete: ({ paymentHistoryId }: DeletePaymentsHistoriesReqType) =>
    axios
      .delete<DeletePaymentsHistoriesResType>(`/payment-histories/${paymentHistoryId}`)
      .then((res) => res.data),
};
