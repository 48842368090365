import axios from '..';
import type {
  DeleteBoardsBoardIdPostsPostIdCommentsCommentIdReactionsReactionIdReqType,
  DeleteBoardsBoardIdPostsPostIdCommentsCommentIdReqType,
  DeleteBoardsBoardIdPostsPostIdReactionsReactionIdReqType,
  DeleteBoardsBoardIdPostsPostIdReqType,
  GetBestBoardsReqType,
  GetBoardsBoardIdPostsPostIdAdjacentReqType,
  GetBoardsBoardIdPostsPostIdCommentsReqType,
  GetBoardsBoardIdPostsPostIdReqType,
  GetBoardsBoardIdPostsPostIdTopLikedReqType,
  GetBoardsBoardIdPostsReqType,
  GetBoardsCategoryIdPopularReqType,
  GetBoardsCategoryIdTopCommentedReqType,
  GetBoardsCategoryIdTopLikedReqType,
  GetRecommendBoardsReqType,
  GetSlugPostsReqType,
  PatchBoardsBoardIdPostsPostIdReqType,
  PostBoardsBoardIdPostsPostIdCommentsCommentIdReactionsReqType,
  PostBoardsBoardIdPostsPostIdCommentsReqType,
  PostBoardsBoardIdPostsPostIdReactionsReqType,
  PostBoardsBoardIdPostsReqType,
  PutBoardsBoardIdPostsPostIdCommentsCommentIdReqType,
} from './req';
import type {
  DeleteBoardsBoardIdPostsPostIdCommentsCommentIdReactionsReactionIdResType,
  DeleteBoardsBoardIdPostsPostIdCommentsCommentIdResType,
  DeleteBoardsBoardIdPostsPostIdReactionsReactionIdResType,
  DeleteBoardsBoardIdPostsPostIdResType,
  GetBestBoardsResType,
  GetBoardsBoardIdPostsPostIdAdjacentResType,
  GetBoardsBoardIdPostsPostIdCommentsResType,
  GetBoardsBoardIdPostsPostIdResType,
  GetBoardsBoardIdPostsPostIdTopLikedResType,
  GetBoardsBoardIdPostsResType,
  GetBoardsCategoryIdPopularResType,
  GetBoardsCategoryIdTopCommentedResType,
  GetBoardsCategoryIdTopLikedResType,
  GetRecommendBoardsResType,
  GetSlugPostsResType,
  PatchBoardsBoardIdPostsPostIdResType,
  PostBoardsBoardIdPostsPostIdCommentsCommentIdReactionsResType,
  PostBoardsBoardIdPostsPostIdReactionsResType,
  PostBoardsBoardIdPostsResType,
  PutBoardsBoardIdPostsPostIdCommentsCommentIdResType,
  postBoardsBoardIdPostsPostIdCommentsResType,
} from './res';

const boardsApi = {
  boardId: {
    posts: {
      postId: {
        get: async ({ postId }: GetBoardsBoardIdPostsPostIdReqType) =>
          (await axios.get<GetBoardsBoardIdPostsPostIdResType>(`/posts/${postId}`)).data,
        patch: async ({ postId, ...body }: PatchBoardsBoardIdPostsPostIdReqType) =>
          (await axios.patch<PatchBoardsBoardIdPostsPostIdResType>(`/posts/${postId}`, body)).data,
        delete: async ({ postId }: DeleteBoardsBoardIdPostsPostIdReqType) =>
          (await axios.delete<DeleteBoardsBoardIdPostsPostIdResType>(`/posts/${postId}`)).data,
        topLiked: {
          get: async ({ postId, ...params }: GetBoardsBoardIdPostsPostIdTopLikedReqType) =>
            (
              await axios.get<GetBoardsBoardIdPostsPostIdTopLikedResType>(
                `/posts/${postId}/top-liked-comments`,
                { params },
              )
            ).data,
        },
        reactions: {
          reactionId: {
            delete: async ({
              postId,
              reactionId,
            }: DeleteBoardsBoardIdPostsPostIdReactionsReactionIdReqType) =>
              (
                await axios.delete<DeleteBoardsBoardIdPostsPostIdReactionsReactionIdResType>(
                  `/posts/${postId}/reactions/${reactionId}`,
                )
              ).data,
          },
          post: async ({ postId, ...body }: PostBoardsBoardIdPostsPostIdReactionsReqType) =>
            (
              await axios.post<PostBoardsBoardIdPostsPostIdReactionsResType>(
                `/posts/${postId}/reactions`,
                body,
              )
            ).data,
        },
        comments: {
          commentId: {
            put: async ({
              postId,
              commentId,
              ...body
            }: PutBoardsBoardIdPostsPostIdCommentsCommentIdReqType) =>
              (
                await axios.put<PutBoardsBoardIdPostsPostIdCommentsCommentIdResType>(
                  `/posts/${postId}/comments/${commentId}`,
                  body,
                )
              ).data,
            delete: async ({ commentId }: DeleteBoardsBoardIdPostsPostIdCommentsCommentIdReqType) =>
              (
                await axios.delete<DeleteBoardsBoardIdPostsPostIdCommentsCommentIdResType>(
                  `/comments/${commentId}`,
                )
              ).data,
            reactions: {
              reactionId: {
                delete: async ({
                  commentId,
                  reactionId,
                }: DeleteBoardsBoardIdPostsPostIdCommentsCommentIdReactionsReactionIdReqType) =>
                  (
                    await axios.delete<DeleteBoardsBoardIdPostsPostIdCommentsCommentIdReactionsReactionIdResType>(
                      `/comments/${commentId}/reactions/${reactionId}`,
                    )
                  ).data,
              },
              post: async ({
                commentId,
              }: PostBoardsBoardIdPostsPostIdCommentsCommentIdReactionsReqType) =>
                (
                  await axios.post<PostBoardsBoardIdPostsPostIdCommentsCommentIdReactionsResType>(
                    `/comments/${commentId}/reactions`,
                    { type: 'LIKE' },
                  )
                ).data,
            },
          },
          post: async ({ postId, ...body }: PostBoardsBoardIdPostsPostIdCommentsReqType) =>
            (
              await axios.post<postBoardsBoardIdPostsPostIdCommentsResType>(
                `/posts/${postId}/comments`,
                body,
              )
            ).data,
          get: async ({ postId, isAsc }: GetBoardsBoardIdPostsPostIdCommentsReqType) =>
            (
              await axios.get<GetBoardsBoardIdPostsPostIdCommentsResType>(
                `/posts/${postId}/comments`,
                {
                  params: {
                    sort: isAsc ? 'createdAt:asc' : 'createdAt:desc',
                  },
                },
              )
            ).data,
        },
        adjacent: {
          get: async ({ boardId, postId }: GetBoardsBoardIdPostsPostIdAdjacentReqType) =>
            (
              await axios.get<GetBoardsBoardIdPostsPostIdAdjacentResType>(
                `/boards/${boardId}/posts/${postId}/adjacent`,
              )
            ).data,
        },
      },
      get: async ({ boardId, ...params }: GetBoardsBoardIdPostsReqType) =>
        (await axios.get<GetBoardsBoardIdPostsResType>(`/boards/${boardId}/posts`, { params }))
          .data,
      post: async ({ boardId, ...body }: PostBoardsBoardIdPostsReqType) =>
        (await axios.post<PostBoardsBoardIdPostsResType>(`/boards/${boardId}/posts`, body)).data,
    },
  },
  categoryId: {
    topLiked: {
      get: async ({ categoryId, ...params }: GetBoardsCategoryIdTopLikedReqType) =>
        (
          await axios.get<GetBoardsCategoryIdTopLikedResType>(`/boards/${categoryId}/top-liked`, {
            params,
          })
        ).data,
    },
    popular: {
      get: async ({ categoryId, ...params }: GetBoardsCategoryIdPopularReqType) =>
        (
          await axios.get<GetBoardsCategoryIdPopularResType>(`/boards/${categoryId}/popular`, {
            params,
          })
        ).data,
    },
    topCommented: {
      get: async ({ categoryId, ...params }: GetBoardsCategoryIdTopCommentedReqType) =>
        (
          await axios.get<GetBoardsCategoryIdTopCommentedResType>(
            `/boards/${categoryId}/top-commented`,
            {
              params,
            },
          )
        ).data,
    },
  },

  Slug: {
    get: ({ slug, ...params }: GetSlugPostsReqType) =>
      axios.get<GetSlugPostsResType>(`/${slug}/posts`, { params }).then((res) => res.data),
  },
};
export const getBestBoards = async (params: GetBestBoardsReqType) =>
  (await axios.get<GetBestBoardsResType>('/live-best-posts', { params })).data;

export const getRecommendBoards = async (params: GetRecommendBoardsReqType) =>
  (await axios.get<GetRecommendBoardsResType>('/live-recommend-posts', { params })).data;

export default boardsApi;
