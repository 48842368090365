/* eslint-disable no-unsafe-optional-chaining */
export function subjectToScore(testName, data) {
    switch (testName) {
        case 'TOEIC':
            return data?.reading + data?.listening;
        case 'TOEFL':
            return data?.reading + data?.listening + data?.speaking + data?.writing;
        case 'TEPS':
            return data?.listening + data?.reading + data?.voca + data?.grammer;
        case 'DELE':
            return data?.grade;
        case 'HSK':
            return data?.grade;
        case 'DELF':
            return data?.grade;
        case 'IELTS':
            return data?.totalScore;
        case 'ETC':
            return (data.subjectList.reduce((acc, subject) => acc + parseInt(subject.totalScore, 10), 0) / data.subjectList.length);
        default:
            return null;
    }
}
