import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ErrorMessage } from '@hookform/error-message';
import SelectBox from '@simppl/component/SelectBox';
import c from '@simppl/util/c';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
export function Select({ defaultValue, content, category, name, placeholder, size = 'short', className, onChange, disabled = false, }) {
    const methods = useFormContext();
    useEffect(() => {
        methods.register(name);
        if (defaultValue && !methods.watch(name)) {
            methods.setValue(name, defaultValue);
        }
    }, [methods, defaultValue, name]);
    return (_jsxs("div", { className: 'relative', children: [_jsx(SelectBox, { disabled: disabled, className: c('border-blue-500 text-blue-500', size === 'short' ? '' : 'w-[252px]', className), selectedColor: 'text-blue-500', currentValue: methods.watch(name) ?? content ?? placeholder, options: category, onClick: (value) => {
                    methods.setValue(name, value);
                    onChange?.(value);
                } }), _jsx(ErrorMessage, { errors: methods.formState.errors, name: name, render: ({ message }) => (_jsx("p", { className: 'absolute top-[40px] text-[12px] text-red-600', children: message })) })] }));
}
