import useQuery from '@simppl/react-query/useQuery';
import { admission } from '@simppl/repository/admission';
export const useAdmissions = (params) => {
    const { data, refetch } = useQuery(['getAdmissions', params?.name, params?.universityId, params?.page, params?.type], () => admission.get(params ??
        {
            size: 999999,
            page: 1,
        }), {
        keepPreviousData: true,
    });
    return {
        data: data?.data ?? { totalCount: 0, admissionList: [] },
        refetch,
    };
};
