import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-shadow */
import { SimulatedApplicationModal } from '@component/admission/SimulatedApplicationModal';
import { VerticalDotsIcon } from '@component/icon/VerticalDotsIcon';
import { useSearchAndPagination } from '@hook/useSearchAndPagination';
import { useSimulatedApplication } from '@hook/useSimulatedApplication';
import { Button, Card, CardBody, CardFooter, CardHeader, Divider, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Input, Pagination, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, useDisclosure, } from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { createAllAdmissionsBySimulatedApplicationId, createAllCompetitiveUnitsBySimulatedApplicationId, } from '@simppl/repository/admins';
import { simulatedApplications } from '@simppl/repository/simulatedApplications';
import { ChevronDownIcon, Search } from 'lucide-react';
import { useState } from 'react';
const statusOptions = [
    { name: '3특', uid: '3Y' },
    { name: '12특', uid: '12Y' },
];
export function SimulatedApplicationPage() {
    const { data } = useSimulatedApplication();
    const [statusFilter, setStatusFilter] = useState('all');
    const { filterValue, filteredItems, items, page, setPage, onSearchChange, onClear } = useSearchAndPagination(data, (userPayment, value) => userPayment.name.includes(value), (userPayment) => {
        if (statusFilter !== 'all' && Array.from(statusFilter).length !== statusOptions.length) {
            return Array.from(statusFilter).includes(userPayment.sort);
        }
        return true;
    });
    const [modalState, setModalState] = useState(null);
    const { isOpen, onOpenChange, onOpen, onClose } = useDisclosure();
    const deleteUserPaymentsHook = useMutation(simulatedApplications.delete);
    const queryClient = useQueryClient();
    const createAdmissionsBulkHook = useMutation(createAllAdmissionsBySimulatedApplicationId);
    const createCompetitiveUnitsBulkHook = useMutation(createAllCompetitiveUnitsBySimulatedApplicationId);
    return (_jsx("div", { className: 'flex min-h-[80%] flex-col', children: _jsxs(Card, { className: 'grow', children: [isOpen && modalState !== null && (_jsx(SimulatedApplicationModal, { isOpen: isOpen, onOpenChange: onOpenChange, ...modalState, onClose: () => {
                        onClose();
                        setModalState(null);
                    } })), _jsx(CardHeader, { children: _jsx("h1", { className: 'text-2xl', children: "\uC804\uD615 \uC0DD\uC131" }) }), _jsx(Divider, {}), _jsx(CardBody, { className: 'flex flex-col gap-4', children: _jsxs(Table, { layout: 'auto', topContent: _jsx("div", { className: 'flex flex-col gap-4', children: _jsxs("div", { className: 'flex w-full flex-row justify-between', children: [_jsxs("div", { className: 'flex max-w-sm flex-1 flex-row items-center gap-4', children: [_jsx(Input, { labelPlacement: 'outside', startContent: _jsx(Search, {}), value: filterValue, onClear: () => onClear(), onValueChange: onSearchChange }), _jsxs(Dropdown, { children: [_jsx(DropdownTrigger, { className: 'hidden sm:flex', children: _jsx(Button, { endContent: _jsx(ChevronDownIcon, { className: 'text-small' }), variant: 'flat', children: "\uC804\uD615 \uAD6C\uBD84" }) }), _jsx(DropdownMenu, { disallowEmptySelection: true, "aria-label": 'Table Columns', closeOnSelect: false, selectedKeys: statusFilter, selectionMode: 'multiple', onSelectionChange: setStatusFilter, children: statusOptions.map((status) => (_jsx(DropdownItem, { className: 'capitalize', children: status.name }, status.uid))) })] })] }), _jsx(Button, { color: 'primary', onPress: () => {
                                            setModalState({ mode: 'add' });
                                            onOpen();
                                        }, children: "\uC804\uD615 \uCD94\uAC00" })] }) }), topContentPlacement: 'outside', children: [_jsxs(TableHeader, { children: [_jsx(TableColumn, { children: "\uC804\uD615 \uC774\uB984" }, 'userName'), _jsx(TableColumn, { children: "\uB300\uD559\uC815\uBCF4" }, 'itemName'), _jsx(TableColumn, { children: "\uC2DC\uC791\uC77C" }, 'itemPrice'), _jsx(TableColumn, { children: "\uB9C8\uAC10\uC77C" }, 'price'), _jsx(TableColumn, { children: "\uC804\uD615 \uAD6C\uBD84" }, 'sort'), _jsx(TableColumn, { children: "\uC2DC\uD5D8 \uAD6C\uBD84" }, 'examSort'), _jsx(TableColumn, { children: "\uBE44\uACE0" }, 'setting')] }), _jsx(TableBody, { items: items, children: (item) => (_jsxs(TableRow, { className: 'relative cursor-pointer', children: [_jsx(TableCell, { children: item.name }), _jsx(TableCell, { children: item.universityName }), _jsx(TableCell, { children: item.startDate }), _jsx(TableCell, { children: item.endDate }), _jsx(TableCell, { children: item.sort }), _jsx(TableCell, { children: item.examSort === 'exam' ? '집필' : '서류' }), _jsx(TableCell, { children: _jsxs(Dropdown, { children: [_jsx(DropdownTrigger, { children: _jsx(Button, { isIconOnly: true, size: 'sm', variant: 'light', children: _jsx(VerticalDotsIcon, { className: 'text-default-300' }) }) }), _jsxs(DropdownMenu, { children: [_jsx(DropdownItem, { onClick: () => {
                                                                    createAdmissionsBulkHook.mutate(parseInt(item.simulatedApplicationId, 10), {
                                                                        onSuccess: () => {
                                                                            alert('모집단위가 일괄 생성되었습니다.');
                                                                            queryClient.invalidateQueries(['getAdmissions']);
                                                                        },
                                                                        onError: (error) => {
                                                                            alert(error.response.data.message);
                                                                        },
                                                                    });
                                                                }, children: "\uBAA8\uC9D1\uB2E8\uC704 \uC77C\uAD04 \uC0DD\uC131" }), _jsx(DropdownItem, { onClick: () => {
                                                                    createCompetitiveUnitsBulkHook.mutate(parseInt(item.simulatedApplicationId, 10), {
                                                                        onSuccess: () => {
                                                                            alert('경쟁단위가 일괄 생성되었습니다.');
                                                                            queryClient.invalidateQueries(['getAdmissions']);
                                                                        },
                                                                        onError: (error) => {
                                                                            alert(error.response.data.message);
                                                                        },
                                                                    });
                                                                }, children: "\uACBD\uC7C1\uB2E8\uC704 \uC77C\uAD04 \uC0DD\uC131" }), _jsx(DropdownItem, { onPress: () => {
                                                                    setModalState({
                                                                        mode: 'edit',
                                                                        simulatedApplicationId: item.simulatedApplicationId,
                                                                    });
                                                                    onOpen();
                                                                }, children: "\uC218\uC815" }), _jsx(DropdownItem, { className: 'text-red-500', onPress: () => {
                                                                    deleteUserPaymentsHook.mutate({ simulatedApplicationId: item.simulatedApplicationId }, {
                                                                        onSuccess: () => {
                                                                            queryClient.invalidateQueries(['getSimulatedApplications']);
                                                                        },
                                                                    });
                                                                }, children: "\uC0AD\uC81C" })] })] }) })] }, item.simulatedApplicationId)) })] }) }), _jsx(CardFooter, { children: _jsx("div", { className: 'flex w-full flex-col items-center', children: _jsx(Pagination, { isCompact: true, showControls: true, showShadow: true, page: page, onChange: setPage, total: Math.max(Math.ceil(filteredItems.length / 10), 1) }) }) })] }) }));
}
