import useSuspenseQuery from '@simppl/react-query/useSuspenseQuery';
import { getTestByUserId } from '@simppl/repository/test';
const useTestByUserId = (testType, userId) => {
    const { data } = useSuspenseQuery({
        queryKey: [`${testType}-testByUserId`],
        queryFn: () => getTestByUserId(testType, { userId }),
    });
    return data;
};
export default useTestByUserId;
