import * as yup from 'yup';
import { SCHEMA_MESSAGE } from '../message';
export const TOEFL_format = [
    {
        subject: 'reading',
        property: [
            {
                content: 'Reading',
                type: 'title',
            },
            {
                content: '점수',
                maxValue: 30,
                type: 'input',
                defaultValue: 0,
            },
        ],
    },
    {
        subject: 'listening',
        property: [
            {
                content: 'Listening',
                type: 'title',
            },
            {
                content: '점수',
                maxValue: 30,
                type: 'input',
                defaultValue: 0,
            },
        ],
    },
    {
        subject: 'speaking',
        property: [
            {
                content: 'Speaking',
                type: 'title',
            },
            {
                content: '점수',
                maxValue: 30,
                type: 'input',
                defaultValue: 0,
            },
        ],
    },
    {
        subject: 'writing',
        property: [
            {
                content: 'Writing',
                type: 'title',
            },
            {
                content: '점수',
                maxValue: 30,
                type: 'input',
                defaultValue: 0,
            },
        ],
    },
    {
        subject: 'total',
        property: [
            {
                content: 'total',
                alterText: '총점',
                type: 'title',
            },
            {
                content: '점수',
                maxValue: 120,
                type: 'input',
                defaultValue: 0,
            },
        ],
    },
];
export const TOEFL_schema = yup
    .object()
    .shape({
    transcript: yup
        .string()
        .required(SCHEMA_MESSAGE.scoreMessage('TOEFL 시험', '파일을').customMessage('업로드 해주세요.')),
    examDate: yup
        .string()
        .required(SCHEMA_MESSAGE.scoreMessage('TOEFL 시험', '시험일자를').requiredMessage())
        .typeError(SCHEMA_MESSAGE.scoreMessage('TOEFL 시험', '시험일자를').requiredMessage()),
    publishingOrganization: yup
        .string()
        .required(SCHEMA_MESSAGE.scoreMessage('TOEFL 시험', '발급기관을').requiredMessage()),
    reading: yup
        .number()
        .min(0, SCHEMA_MESSAGE.scoreMessage('TOEFL 시험', 'Reading 점수는').minMessage(0))
        .max(30, SCHEMA_MESSAGE.scoreMessage('TOEFL 시험', 'Reading 점수는').maxMessage(30))
        .required(SCHEMA_MESSAGE.scoreMessage('TOEFL 시험', 'Reading 점수를').requiredMessage())
        .typeError(SCHEMA_MESSAGE.scoreMessage('TOEFL 시험', 'Reading 점수를').requiredMessage()),
    listening: yup
        .number()
        .min(0, SCHEMA_MESSAGE.scoreMessage('TOEFL 시험', 'Listening 점수는').minMessage(0))
        .max(30, SCHEMA_MESSAGE.scoreMessage('TOEFL 시험', 'Listening 점수는').maxMessage(30))
        .required(SCHEMA_MESSAGE.scoreMessage('TOEFL 시험', 'Listening 점수를').requiredMessage())
        .typeError(SCHEMA_MESSAGE.scoreMessage('TOEFL 시험', 'Listening 점수를').requiredMessage()),
    speaking: yup
        .number()
        .min(0, SCHEMA_MESSAGE.scoreMessage('TOEFL 시험', 'Speaking 점수는').minMessage(0))
        .max(30, SCHEMA_MESSAGE.scoreMessage('TOEFL 시험', 'Speaking 점수는').maxMessage(30))
        .required(SCHEMA_MESSAGE.scoreMessage('TOEFL 시험', 'Speaking 점수를').requiredMessage())
        .typeError(SCHEMA_MESSAGE.scoreMessage('TOEFL 시험', 'Speaking 점수를').requiredMessage()),
    writing: yup
        .number()
        .min(0, SCHEMA_MESSAGE.scoreMessage('TOEFL 시험', 'Writing 점수는').minMessage(0))
        .max(30, SCHEMA_MESSAGE.scoreMessage('TOEFL 시험', 'Writing 점수는').maxMessage(30))
        .required(SCHEMA_MESSAGE.scoreMessage('TOEFL 시험', 'Writing 점수를').requiredMessage())
        .typeError(SCHEMA_MESSAGE.scoreMessage('TOEFL 시험', 'Writing 점수를').requiredMessage()),
    total: yup
        .number()
        .min(0, SCHEMA_MESSAGE.scoreMessage('TOEFL 시험', '총점은').minMessage(0))
        .max(120, SCHEMA_MESSAGE.scoreMessage('TOEFL 시험', '총점은').maxMessage(120))
        .required(SCHEMA_MESSAGE.scoreMessage('TOEFL 시험', '총점을').requiredMessage())
        .typeError(SCHEMA_MESSAGE.scoreMessage('TOEFL 시험', '총점을').requiredMessage()),
})
    .default(null)
    .nullable();
