import axios from '..';
import type { GetHighSchoolsReq, PatchHighSchoolsReq, PostHighschoolsReq } from './req';
import type { GetHighschoolsRes, PostHighschoolsRes } from './res';

export const getHighschools = (body: GetHighSchoolsReq) => {
  const url = new URL(`${axios.getUri()}/highschools`);
  Object.entries(body).forEach(([key, value]) => url.searchParams.append(key, String(value)));
  return axios.get<GetHighschoolsRes>(url.href).then((res) => res.data);
};

export const postHighschools = (body: PostHighschoolsReq) =>
  axios.post<PostHighschoolsRes>('/highschools', body).then((res) => res.data.data);

export const deleteHighschools = (id: string) =>
  axios.delete(`/highschools/${id}`).then((res) => res.data);

export const pathHighschools = (id: string, body: PatchHighSchoolsReq) =>
  axios.patch(`/highschools/${id}`, body).then((res) => res.data);
