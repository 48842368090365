import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import { Input, Title } from '@component/score/common/form';
import { SAT_format } from '@schema/standardized/sat';
import Spacing from '@simppl/component/Spacing';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
export function SATForm({ content }) {
    const methods = useFormContext();
    useEffect(() => {
        const standarizedTestId = methods.watch(`${content}.standarizedTestId`);
        if (standarizedTestId) {
            methods.setValue(`${content}.testId`, standarizedTestId);
            const [ebrw, math] = methods.getValues([`${content}.ebrw`, `${content}.math`]);
            methods.setValue(`${content}.total`, ebrw + math);
        }
    }, []);
    return (_jsxs("div", { className: 'grid gap-[24px] [grid-template-areas:"grade_others"]', children: [_jsx(Spacing, { width: '124px' }), _jsx("div", { className: 'flex flex-col gap-[24px] [grid-area:others]', children: SAT_format.map(({ subject, property }, index) => (_jsx("div", { className: 'flex gap-[24px]', children: property.map((propertyItem) => (_jsxs(_Fragment, { children: [propertyItem.type === 'input' && (_jsx(Input, { ...propertyItem, name: `${content}.${subject}` }, propertyItem.content)), propertyItem.type === 'title' && (_jsx(Title, { ...propertyItem }, propertyItem.content))] }))) }, index))) })] }));
}
