import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ComboBox, Select } from '@component/score/common/form';
import { Minus } from '@component/score/common/icon/Minus';
import { Plus } from '@component/score/common/icon/Plus';
import { useStandarizedTestIds } from '@hook/useStandarizedTestIds';
import { ErrorMessage } from '@hookform/error-message';
import { APSubject } from '@schema/standardized/ap';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { deleteUserStandardizedTestSubject } from '@simppl/repository/test';
import { useEffect } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
export function APForm({ content }) {
    const methods = useFormContext();
    const { fields, append, remove } = useFieldArray({
        control: methods.control,
        name: `${content}.subjectList`,
    });
    return (_jsxs(_Fragment, { children: [_jsx(ErrorMessage, { errors: methods.formState.errors, name: `${content}.subjectList`, render: ({ message }) => _jsx("p", { className: 'text-[12px] text-red-600', children: message }) }), _jsx("div", { className: 'flex w-full flex-col gap-2', children: fields.map(({ id }, index) => (_jsx(Form, { content: content, index: index, remove: remove }, id))) }), _jsx("div", { className: 'pl-[148px]', children: _jsxs("button", { type: 'button', className: 'flex w-[513px] items-center justify-center gap-[8px] rounded-md border-[1px] border-blue-500 bg-blue-500 px-2 py-1 text-[white]', onClick: () => append({
                        id: null,
                        score: 0,
                    }), children: [_jsx(Plus, { color: 'white' }), "\uACFC\uBAA9\uCD94\uAC00"] }) })] }));
}
function Form({ content, index, remove }) {
    const methods = useFormContext();
    const { AP } = useStandarizedTestIds();
    const deleteUserStandardizedTestSubjectHook = useMutation((subjectId) => deleteUserStandardizedTestSubject('AP', subjectId));
    const queryClient = useQueryClient();
    useEffect(() => {
        const id = methods.watch(`${content}.subjectList.${index}.id`) ?? null;
        const name = methods.watch(`${content}.subjectList.${index}.name`) ?? null;
        if (id === null && name !== null) {
            methods.setValue(`${content}.subjectList.${index}.id`, AP[name]);
        }
        if (id !== null && name === null) {
            methods.setValue(`${content}.subjectList.${index}.name`, AP[id]);
        }
    }, [content, index, methods, AP]);
    return (_jsx("div", { className: 'mr-[82px] flex justify-end gap-[24px]', children: _jsx(Controller, { name: `${content}.subjectList.${index}`, control: methods.control, render: ({ field }) => (_jsxs(_Fragment, { children: [_jsx("div", { className: 'flex justify-end gap-[9px]', children: _jsxs(_Fragment, { children: [_jsx(ComboBox, { type: 'combobox', placeholder: '\uACFC\uBAA9 \uAC80\uC0C9', category: APSubject, content: methods.watch(`${field.name}.name`) ?? '', name: `${field.name}.name`, onChange: (value) => {
                                        methods.setValue(`${field.name}.id`, AP[value]);
                                    } }), _jsx(Select, { defaultValue: '1', size: 'long', type: 'select', content: '\uC810\uC218', category: {
                                        '5': '5',
                                        '4': '4',
                                        '3': '3',
                                        '2': '2',
                                        '1': '1',
                                    }, name: `${field.name}.score`, onChange: (value) => {
                                        methods.setValue(`${field.name}.score`, parseInt(value, 10));
                                    } })] }) }), _jsx("button", { className: ' flex   h-[40px]   w-[40px] items-center justify-center whitespace-nowrap rounded-[4px] border-[1px] border-blue-700 text-blue-700', type: 'button', onClick: () => {
                            const subjectId = methods.watch(`${content}.subjectList.${index}.standarizedApId`);
                            if (subjectId)
                                deleteUserStandardizedTestSubjectHook.mutate(subjectId, {
                                    onSuccess: () => remove(index),
                                });
                            else {
                                remove(index);
                            }
                            queryClient.invalidateQueries(['standarized-tests-testByUserId']);
                        }, children: _jsx(Minus, {}) })] })) }) }));
}
