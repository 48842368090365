import axios from '..';
import type {
  DeleteInquiriesAnswersReqType,
  DeleteInquiriesIdReqType,
  GetInquiriesAnswersReqType,
  GetInquiriesIdReqType,
  GetInquiriesReqType,
  PatchInquiriesAnswersReqType,
  PatchInquiriesIdReqType,
  PostInquiriesAnswersReqType,
  PostInquiriesReqType,
  PutInquiriesIdReadReqType,
} from './req';
import type {
  DeleteInquiriesAnswersResType,
  DeleteInquiriesIdResType,
  GetInquiriesAnswersResType,
  GetInquiriesIdResType,
  GetInquiriesResType,
  PatchInquiriesAnswersResType,
  PatchInquiriesIdResType,
  PostInquiriesAnswersResType,
  PostInquiriesResType,
  PutInquiriesIdReadResType,
} from './res';

export const getInquiries = async (params: GetInquiriesReqType) =>
  (await axios.get<GetInquiriesResType>('/inquiries', { params })).data;

export const getAdminInquiries = async (params: GetInquiriesReqType) =>
  (await axios.get<GetInquiriesResType>('/admins/inquiries', { params })).data;

export const getInquiriesId = async ({ id }: GetInquiriesIdReqType) =>
  (await axios.get<GetInquiriesIdResType>(`/inquiries/${id}`)).data;
export const postInquiries = async (body: PostInquiriesReqType) =>
  (await axios.post<PostInquiriesResType>(`/inquiries`, body)).data;
export const PatchInquiriesId = async ({ id, ...data }: PatchInquiriesIdReqType) =>
  (await axios.patch<PatchInquiriesIdResType>(`/inquiries/${id}`, data)).data;
export const DeleteInquiriesId = async ({ id }: DeleteInquiriesIdReqType) =>
  (await axios.delete<DeleteInquiriesIdResType>(`/inquiries/${id}`)).data;
export const PutInquiriesIdRead = async ({ id }: PutInquiriesIdReadReqType) =>
  (await axios.put<PutInquiriesIdReadResType>(`/inquiries/${id}/read`)).data;

export const inquiryAnswer = {
  get: ({ id }: GetInquiriesAnswersReqType) =>
    axios.get<GetInquiriesAnswersResType>(`/inquiries/${id}`),
  post: ({ id, ...body }: PostInquiriesAnswersReqType) =>
    axios.post<PostInquiriesAnswersResType>(`/inquiries/${id}/answer`, body),
  patch: ({ id, ...body }: PatchInquiriesAnswersReqType) =>
    axios.patch<PatchInquiriesAnswersResType>(`/inquiries/${id}/answer`, body),
  delete: ({ id }: DeleteInquiriesAnswersReqType) =>
    axios.delete<DeleteInquiriesAnswersResType>(`/inquiries/${id}/answer`),
};
