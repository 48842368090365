import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from '@nextui-org/react';
export default function DownloadButton({ pdfUrl, pdfName }) {
    const handleDownload = () => {
        fetch(pdfUrl, { method: 'GET' })
            .then((res) => {
            return res.blob();
        })
            .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${pdfName}.pdf`;
            document.body.appendChild(a);
            a.click();
            setTimeout((_) => {
                window.URL.revokeObjectURL(url);
            }, 60000);
            a.remove();
        })
            .catch((err) => {
            console.error('err: ', err);
        });
    };
    return (_jsx(Button, { onClick: handleDownload, children: _jsx("div", { children: "pdf \uB2E4\uC6B4\uB85C\uB4DC" }) }));
}
