import axios from '..';
import type {
  DeleteCompetitiveUnitsReqType,
  PatchCompetitiveUnitsReqType,
  PostCompetitiveUnitsReqType,
} from './req';
import type {
  DeleteCompetitiveUnitsResType,
  GetCompetitiveUnitsResType,
  PatchCompetitiveUnitsResType,
  PostCompetitiveUnitsResType,
} from './res';

export const competitiveUnits = {
  get: () => axios.get<GetCompetitiveUnitsResType>('/competitive-units').then((res) => res.data),
  post: (body: PostCompetitiveUnitsReqType) =>
    axios.post<PostCompetitiveUnitsResType>('/competitive-units', body).then((res) => res.data),
  patch: ({ competitiveUnitId, ...body }: PatchCompetitiveUnitsReqType) =>
    axios
      .patch<PatchCompetitiveUnitsResType>(`/competitive-units/${competitiveUnitId}`, body)
      .then((res) => res.data),
  delete: ({ competitiveUnitId }: DeleteCompetitiveUnitsReqType) =>
    axios
      .delete<DeleteCompetitiveUnitsResType>(`/competitive-units/${competitiveUnitId}`)
      .then((res) => res.data),
};
