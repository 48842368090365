import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useMemo, useState } from 'react';
export const scoreBlockContext = createContext({
    category: new Map(),
    list: {},
    handleCheck: () => null,
    handleChange: () => null,
    handleRemove: () => null,
    handleReset: () => null,
});
function ScoreBlockProvider({ children, list, }) {
    const [category, setCategory] = useState(new Map());
    const handleCheck = useCallback((test) => setCategory((prev) => {
        if (prev.has(test)) {
            const newState = new Map(prev);
            newState.delete(test);
            return newState;
        }
        return new Map([...prev, [test, !prev.has(test)]]);
    }), []);
    const handleRemove = useCallback((test) => setCategory((prev) => {
        if (category.has(test)) {
            const newState = new Map(prev);
            newState.delete(test);
            return newState;
        }
        return prev;
    }), [category]);
    const handleChange = useCallback((prevTest, nextTest) => setCategory((prev) => {
        if (!category.has(prevTest))
            return prev;
        const newState = new Map(prev);
        newState.delete(prevTest);
        if (category.has(nextTest)) {
            return newState;
        }
        return new Map([...newState, [nextTest, !category.has(nextTest)]]);
    }), [category]);
    const handleReset = useCallback(() => setCategory(new Map()), []);
    const value = useMemo(() => ({
        category,
        list,
        handleCheck,
        handleChange,
        handleRemove,
        handleReset,
    }), [list, handleCheck, category, handleChange, handleRemove, handleReset]);
    return (_jsx(scoreBlockContext.Provider, { value: value, children: _jsx("div", { className: 'flex flex-col gap-20', children: children }) }));
}
export function ScoreBlock({ children, list, }) {
    return _jsx(ScoreBlockProvider, { list: list, children: children });
}
