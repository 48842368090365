import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useTestByUserId from '@hook/useTestByUserId';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { EditScore } from './EditScore';
import { ActivityAwardScoreModeProvider } from './Provider';
import { ViewTest } from './ViewTest';
const ActivityAwardPage = () => {
    const [mode, setMode] = useState('viewTest');
    const { id } = useParams();
    const userData = useTestByUserId('activities-awards', id);
    const methods = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
    });
    useEffect(() => {
        if (userData === undefined)
            return;
        methods.setValue('activityAward', userData.activityAwardList);
        userData.activityAwardList.forEach((data, index) => {
            methods.setValue(`activityAward.${index}.subject`, 'awardDate' in data ? 'AWARD' : 'ACTIVITY');
        });
    }, [userData]);
    return (_jsx(FormProvider, { ...methods, children: _jsx(ActivityAwardScoreModeProvider, { mode: mode, setMode: setMode, children: _jsxs("form", { id: 'test', className: 'flex w-[915px] flex-col items-center', children: [mode === 'viewTest' && _jsx(ViewTest, {}), mode === 'editScore' && _jsx(EditScore, {})] }) }) }));
};
export default ActivityAwardPage;
