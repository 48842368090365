import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-shadow */
import { UserAdmissionInterestTab, UserAdmissionTab, UserDefaultTab, UserScoreTab, } from '@component/user/detail';
import { useUsers } from '@hook/useUsers';
import { Card, CardBody, CardHeader, Divider, Tab, Tabs } from '@nextui-org/react';
import { createContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
export default function UserDetailPage() {
    const { id } = useParams();
    const [selected, setSelected] = useState('default');
    const users = useUsers();
    const user = users.find((user) => user.id === id);
    if (!user) {
        return _jsx("div", { children: "\uC720\uC800 \uC815\uBCF4\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4." });
    }
    return (_jsx("div", { className: 'flex min-h-[80%] flex-col', children: _jsx(UserIdProvider, { userId: id ?? '', children: _jsxs(Card, { className: 'grow', children: [_jsx(CardHeader, { className: 'flex justify-between px-8', children: _jsxs("div", { className: 'flex items-center', children: [_jsx("h1", { className: 'text-2xl', children: "\uC720\uC800 \uC815\uBCF4" }), _jsx("p", { children: "-" }), _jsx("p", { children: user.name })] }) }), _jsx(Divider, {}), _jsx(CardBody, { children: _jsxs(Tabs, { selectedKey: selected, onSelectionChange: (key) => setSelected(key), children: [_jsx(Tab, { title: '\uAE30\uBCF8 \uC815\uBCF4', children: _jsx(UserDefaultTab, {}) }, 'default'), _jsx(Tab, { title: '\uC131\uC801', children: _jsx(UserScoreTab, {}) }, 'score'), _jsx(Tab, { title: '\uAD00\uC2EC \uB300\uD559', children: _jsx(UserAdmissionInterestTab, {}) }, 'admissions'), _jsx(Tab, { title: '\uBAA8\uC758 \uC9C0\uC6D0', children: _jsx(UserAdmissionTab, {}) }, 'application')] }) })] }) }) }));
}
export const UserIdContext = createContext({
    userId: '',
});
function UserIdProvider({ children, userId }) {
    const value = useMemo(() => ({
        userId,
    }), [userId]);
    return _jsx(UserIdContext.Provider, { value: value, children: children });
}
