const minMessage = (num) => `${num} 보다 커야 합니다.`;
const maxMessage = (num) => `${num} 보다 작아야 합니다.`;
const requiredMessage = () => '필수로 작성해주세요.';
const multipleOfMessage = (num) => `${num}의 배수여야 합니다.`;
const scoreMessage = (examName, scoreName) => {
    const extraMessage = {
        maxMessage: (num) => `${examName}의 ${scoreName} ${maxMessage(num)}`,
        minMessage: (num) => `${examName}의 ${scoreName} ${minMessage(num)}`,
        requiredMessage: (type = '입력') => `${examName}의 ${scoreName} 필수로 ${type}해주세요.`,
        multipleOfMessage: (num) => `${examName}의 ${scoreName} ${multipleOfMessage(num)}`,
        customMessage: (message) => `${examName}의 ${scoreName} ${message}`,
    };
    return extraMessage;
};
export const SCHEMA_MESSAGE = {
    maxMessage,
    minMessage,
    requiredMessage,
    multipleOfMessage,
    scoreMessage,
};
