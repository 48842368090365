import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ScoreBlock, ScoreForm } from '@component/score/test';
import { yupResolver } from '@hookform/resolvers/yup';
import { languageTestSchemaMap } from '@schema/language';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { EditScore } from './EditScore';
import { LanguageScoreModeProvider } from './Provider';
import { ViewTest } from './ViewTest';
const languageTests = {
    TOEFL: 'TOEFL',
    TOEIC: 'TOEIC',
    TEPS: 'TEPS',
    IELTS: 'IELTS',
    HSK: 'HSK',
    DELF: 'DELF',
    DELE: 'DELE',
    ETC: 'ETC',
};
const LanguageProficiency = () => {
    const [mode, setMode] = useState('viewTest');
    const methods = useForm({
        resolver: yupResolver(languageTestSchemaMap),
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
    });
    return (_jsx(FormProvider, { ...methods, children: _jsx(ScoreBlock, { list: languageTests, children: _jsx(LanguageScoreModeProvider, { mode: mode, setMode: setMode, children: _jsxs(ScoreForm, { testType: 'language-tests', children: [mode === 'viewTest' && (_jsx(ViewTest, {})), mode === 'editScore' && _jsx(EditScore, {})] }) }) }) }));
};
export default LanguageProficiency;
