import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import { Input, Title } from '@component/score/common/form';
import { TEPS_format } from '@schema/language/teps';
import Spacing from '@simppl/component/Spacing';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
export function TEPSForm({ content }) {
    const methods = useFormContext();
    useEffect(() => {
        const languageTestId = methods.watch(`${content}.languageTestId`);
        if (languageTestId) {
            methods.setValue(`${content}.testId`, languageTestId);
            const [listening, grammer, reading, voca] = methods.getValues([
                `${content}.listening`,
                `${content}.grammer`,
                `${content}.reading`,
                `${content}.voca`,
            ]);
            methods.setValue(`${content}.total`, listening + grammer + reading + voca);
        }
    }, []);
    return (_jsxs("div", { className: 'grid gap-[24px] [grid-template-areas:"grade_others"]', children: [_jsx(Spacing, { width: '124px' }), _jsx("div", { className: 'flex flex-col gap-[24px] [grid-area:others]', children: TEPS_format.map(({ subject, property }, index) => (_jsx("div", { className: 'flex gap-[24px]', children: property.map((propertyItem) => (_jsxs(_Fragment, { children: [propertyItem.type === 'input' && (_jsx(Input, { ...propertyItem, name: `${content}.${subject}` }, propertyItem.content)), propertyItem.type === 'title' && (_jsx(Title, { ...propertyItem }, propertyItem.content))] }))) }, index))) })] }));
}
