import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/destructuring-assignment */
import { useReportCardSubjects } from '@hook/useReportCardSubjects';
import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Switch, } from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { reportCardSubjects } from '@simppl/repository/report-card-subjects';
import { useEffect, useState } from 'react';
const subjectMap = {
    AP: 'AP',
    'A-LEVEL': 'A-LEVEL',
    IB: 'IB',
};
export function HighSchoolSubjectModal(props) {
    const [name, setName] = useState('');
    const [isApprove, setIsApprove] = useState(false);
    const item = props.mode === 'edit' ? useReportCardSubjects().find((v) => v.id === props.subjectId) : null;
    const queryClient = useQueryClient();
    const postItemHook = useMutation(reportCardSubjects.post);
    const patchItemHook = useMutation(reportCardSubjects.patch);
    useEffect(() => {
        if (props.mode === 'edit' && item) {
            setName(item.name);
            setIsApprove(item.isApprove);
        }
    }, [props]);
    return (_jsx(Modal, { isOpen: props.isOpen, onOpenChange: props.onOpenChange, children: _jsx(ModalContent, { children: () => (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { className: 'flex flex-col gap-1', children: props.mode === 'add' ? '표준화 과목 추가' : '표준화 과목 수정' }), _jsxs(ModalBody, { children: [_jsx(Input, { value: name, onChange: (e) => setName(e.target.value), label: '\uACFC\uBAA9\uBA85', labelPlacement: 'outside' }), _jsx("div", { className: 'flex gap-4', children: props.mode === 'edit' && (_jsxs(_Fragment, { children: [_jsx("p", { children: "\uC2B9\uC778 \uC5EC\uBD80" }), _jsx(Switch, { defaultSelected: false, color: 'default', isSelected: isApprove, onValueChange: setIsApprove })] })) })] }), _jsxs(ModalFooter, { children: [_jsx(Button, { color: 'danger', variant: 'light', onPress: props.onClose, children: "\uCDE8\uC18C" }), _jsx(Button, { type: 'submit', form: 'authorization', color: 'primary', onPress: () => {
                                    if (props.mode === 'add')
                                        postItemHook.mutate({
                                            name,
                                        }, {
                                            onSuccess: () => {
                                                queryClient.invalidateQueries(['reportCardSubjects']);
                                                props.onClose();
                                            },
                                        });
                                    else if (props.mode === 'edit' && item?.id)
                                        patchItemHook.mutate({
                                            reportCardSubjectId: props.subjectId.toString(),
                                            name,
                                            isApprove,
                                        }, {
                                            onSuccess: () => {
                                                queryClient.invalidateQueries(['reportCardSubjects']);
                                                props.onClose();
                                            },
                                        });
                                }, children: "\uC800\uC7A5" })] })] })) }) }));
}
