import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/destructuring-assignment */
import { useAdmissions } from '@hook/useAdmissions';
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Select, SelectItem, } from '@nextui-org/react';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { admissionCompetitiveUnit } from '@simppl/repository/admission';
import { useMemo, useState } from 'react';
export function AddAdmissionCompetitiveUnitModal(props) {
    const [majorKeys, setMajorKeys] = useState(new Set(['']));
    const [simulatedKeys, setSimulatedKeys] = useState(new Set(['']));
    const selectedSimulatedValue = useMemo(() => Array.from(simulatedKeys).join(', ').replace(/_/g, ' '), [simulatedKeys]);
    const admissionsMap = useAdmissions().data.admissionList.reduce((acc, cur) => {
        if (cur.simulatedApplicationName in acc) {
            acc[cur.simulatedApplicationName].push(cur);
        }
        else {
            acc[cur.simulatedApplicationName] = [cur];
        }
        return acc;
    }, {});
    const queryClient = useQueryClient();
    return (_jsx(Modal, { isOpen: props.isOpen, onOpenChange: props.onOpenChange, children: _jsx(ModalContent, { children: () => (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { className: 'flex flex-col gap-1', children: "\uACBD\uC7C1\uB2E8\uC704\uC5D0 \uBAA8\uC9D1\uB2E8\uC704 \uCD94\uAC00" }), _jsxs(ModalBody, { children: [_jsx(Select, { disallowEmptySelection: true, "aria-label": 'Table Columns', selectionMode: 'single', selectedKeys: simulatedKeys, onSelectionChange: setSimulatedKeys, children: Object.keys(admissionsMap).map((categoryItem) => (_jsx(SelectItem, { value: categoryItem, className: 'capitalize', children: categoryItem }, categoryItem))) }), _jsx(Select, { disallowEmptySelection: true, "aria-label": 'Table Columns', selectionMode: 'multiple', selectedKeys: majorKeys, onSelectionChange: setMajorKeys, children: admissionsMap[selectedSimulatedValue]?.map((categoryItem) => (_jsx(SelectItem, { value: categoryItem.admissionId, className: 'capitalize', children: categoryItem.admissionName }, categoryItem.admissionId))) })] }), _jsxs(ModalFooter, { children: [_jsx(Button, { color: 'danger', variant: 'light', onPress: props.onClose, children: "\uCDE8\uC18C" }), _jsx(Button, { type: 'submit', form: 'authorization', color: 'primary', onPress: () => {
                                    Promise.all(Array.from(majorKeys)
                                        .filter((v) => v !== '')
                                        .map(async (admissionId) => {
                                        const result = await admissionCompetitiveUnit.patch({
                                            competitiveUnitId: props.competitiveUnitId,
                                            admissionId,
                                        });
                                        return result;
                                    })).then((results) => {
                                        if (results.every((result) => result.resultCode === 200)) {
                                            queryClient.invalidateQueries(['getAdmissions']);
                                            props.onClose();
                                        }
                                        else {
                                            alert('저장중에 오류가 발생했습니다. 다시 시도해주세요.');
                                        }
                                    });
                                }, children: "\uC800\uC7A5" })] })] })) }) }));
}
