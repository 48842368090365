import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
import LoginRequired from '../hoc/LoginRequired';
import { AdmissionPage } from './admission/AdmissionPage';
import { SimulatedApplicationPage } from './admission/SimulatedApplicationPage';
import { CompetitiveUnitAdmissionPage } from './admission/competitiveUnit/CompetitiveUnitAdmissionPage';
import { CompetitiveUnitPage } from './admission/competitiveUnit/CompetitiveUnitPage';
import CategoryPage from './community/CategoryPage';
import FaqPage from './community/FaqPage';
import PostPage from './community/PostPage';
import InquiryPage from './community/inquiry/InquiryPage';
import NoticeCommentPage from './community/notice/NoticeCommentPage';
import NoticePage from './community/notice/NoticePage';
import AdmissionsPage from './database/AdmissionsPage';
import CountryPage from './database/CountryPage';
import HighSchoolPage from './database/HighSchoolPage';
import ItemPage from './database/ItemPage';
import UniversityPage from './database/UniversityPage';
import UserPaymentPage from './database/UserPaymentPage';
import FakeApplicantPage from './mock/FakeApplicantPage';
import App from './root/App';
import { HighSchoolSubjectPage } from './subject/HighSchoolSubjectPage';
import { StandarizedSubjectPage } from './subject/StandarizedSubjectPage';
import UserDetailPage from './user/UserDetailPage';
const ApplicantPage = lazy(() => import('./mock/ApplicantPage'));
const LoginPage = lazy(() => import('./login/LoginPage'));
const Mainpage = lazy(() => import('./root/Mainpage'));
const SettingPage = lazy(() => import('./system/SettingPage'));
const UserPage = lazy(() => import('./user/UserPage'));
export const router = [
    {
        path: '/',
        element: _jsx(App, {}),
        errorElement: _jsx(App.Error, {}),
        children: [
            {
                path: '/',
                element: _jsx(Outlet, {}),
                children: [
                    {
                        path: '/',
                        element: (_jsx(LoginRequired, { children: _jsx(Suspense, { fallback: _jsx(_Fragment, { children: "...loading" }), children: _jsx(Mainpage, {}) }) })),
                    },
                ],
            },
            {
                path: '/user',
                element: _jsx(Outlet, {}),
                children: [
                    {
                        path: '/user',
                        element: (_jsx(LoginRequired, { children: _jsx(Suspense, { fallback: _jsx(_Fragment, { children: "...loading" }), children: _jsx(UserPage, {}) }) })),
                    },
                    {
                        path: '/user/:id',
                        element: (_jsx(LoginRequired, { children: _jsx(Suspense, { fallback: _jsx(_Fragment, { children: "...loading" }), children: _jsx(UserDetailPage, {}) }) })),
                    },
                ],
            },
            {
                path: '/admission',
                element: _jsx(Outlet, {}),
                children: [
                    {
                        path: '/admission/simulatedApplication',
                        element: (_jsx(LoginRequired, { children: _jsx(Suspense, { fallback: _jsx(_Fragment, { children: "...loading" }), children: _jsx(SimulatedApplicationPage, {}) }) })),
                    },
                    {
                        path: '/admission/admission',
                        element: (_jsx(LoginRequired, { children: _jsx(Suspense, { fallback: _jsx(_Fragment, { children: "...loading" }), children: _jsx(AdmissionPage, {}) }) })),
                    },
                    {
                        path: '/admission/competitiveUnit',
                        element: (_jsx(LoginRequired, { children: _jsx(Suspense, { fallback: _jsx(_Fragment, { children: "...loading" }), children: _jsx(CompetitiveUnitPage, {}) }) })),
                    },
                    {
                        path: '/admission/competitiveUnit/:competitiveUnitId',
                        element: (_jsx(LoginRequired, { children: _jsx(Suspense, { fallback: _jsx(_Fragment, { children: "...loading" }), children: _jsx(CompetitiveUnitAdmissionPage, {}) }) })),
                    },
                ],
            },
            {
                path: '/community',
                element: _jsx(Outlet, {}),
                children: [
                    {
                        path: '/community/notice',
                        element: (_jsx(LoginRequired, { children: _jsx(Suspense, { fallback: _jsx(_Fragment, { children: "...loading" }), children: _jsx(NoticePage, {}) }) })),
                    },
                    {
                        path: '/community/notice/:id',
                        element: (_jsx(LoginRequired, { children: _jsx(Suspense, { fallback: _jsx(_Fragment, { children: "...loading" }), children: _jsx(NoticeCommentPage, {}) }) })),
                    },
                    {
                        path: '/community/faq',
                        element: (_jsx(LoginRequired, { children: _jsx(Suspense, { fallback: _jsx(_Fragment, { children: "...loading" }), children: _jsx(FaqPage, {}) }) })),
                    },
                    {
                        path: '/community/category',
                        element: (_jsx(LoginRequired, { children: _jsx(Suspense, { fallback: _jsx(_Fragment, { children: "...loading" }), children: _jsx(CategoryPage, {}) }) })),
                    },
                    {
                        path: '/community/inquiry',
                        element: (_jsx(LoginRequired, { children: _jsx(Suspense, { fallback: _jsx(_Fragment, { children: "...loading" }), children: _jsx(InquiryPage, {}) }) })),
                    },
                    {
                        path: '/community/post',
                        element: (_jsx(LoginRequired, { children: _jsx(Suspense, { fallback: _jsx(_Fragment, { children: "...loading" }), children: _jsx(PostPage, {}) }) })),
                    },
                ],
            },
            {
                path: '/mock',
                element: _jsx(Outlet, {}),
                children: [
                    {
                        path: '/mock/fakeApplicant',
                        element: (_jsx(LoginRequired, { children: _jsx(Suspense, { fallback: _jsx(_Fragment, { children: "...loading" }), children: _jsx(FakeApplicantPage, {}) }) })),
                    },
                    {
                        path: '/mock/applicant',
                        element: (_jsx(LoginRequired, { children: _jsx(Suspense, { fallback: _jsx(_Fragment, { children: "...loading" }), children: _jsx(ApplicantPage, {}) }) })),
                    },
                ],
            },
            {
                path: '/database',
                element: _jsx(Outlet, {}),
                children: [
                    {
                        path: '/database/userPayment',
                        element: (_jsx(LoginRequired, { children: _jsx(Suspense, { fallback: _jsx(_Fragment, { children: "...loading" }), children: _jsx(UserPaymentPage, {}) }) })),
                    },
                    {
                        path: '/database/item',
                        element: (_jsx(LoginRequired, { children: _jsx(Suspense, { fallback: _jsx(_Fragment, { children: "...loading" }), children: _jsx(ItemPage, {}) }) })),
                    },
                    {
                        path: '/database/university',
                        element: (_jsx(LoginRequired, { children: _jsx(Suspense, { fallback: _jsx(_Fragment, { children: "...loading" }), children: _jsx(UniversityPage, {}) }) })),
                        children: [
                            {
                                path: '/database/university/:universityId',
                                element: (_jsx(LoginRequired, { children: _jsx(Suspense, { fallback: _jsx(_Fragment, { children: "...loading" }), children: _jsx(UserDetailPage, {}) }) })),
                            },
                        ],
                    },
                    {
                        path: '/database/highschool',
                        element: (_jsx(LoginRequired, { children: _jsx(Suspense, { fallback: _jsx(_Fragment, { children: "...loading" }), children: _jsx(HighSchoolPage, {}) }) })),
                    },
                    {
                        path: '/database/country',
                        element: (_jsx(LoginRequired, { children: _jsx(Suspense, { fallback: _jsx(_Fragment, { children: "...loading" }), children: _jsx(CountryPage, {}) }) })),
                    },
                    {
                        path: '/database/admissionUserCount',
                        element: (_jsx(LoginRequired, { children: _jsx(Suspense, { fallback: _jsx(_Fragment, { children: "...loading" }), children: _jsx(AdmissionsPage, {}) }) })),
                    },
                ],
            },
            {
                path: '/subject',
                element: _jsx(Outlet, {}),
                children: [
                    {
                        path: '/subject/standardizedSubject',
                        element: (_jsx(LoginRequired, { children: _jsx(Suspense, { fallback: _jsx(_Fragment, { children: "...loading" }), children: _jsx(StandarizedSubjectPage, {}) }) })),
                    },
                    {
                        path: '/subject/highSchoolSubject',
                        element: (_jsx(LoginRequired, { children: _jsx(Suspense, { fallback: _jsx(_Fragment, { children: "...loading" }), children: _jsx(HighSchoolSubjectPage, {}) }) })),
                    },
                ],
            },
            {
                path: '/system',
                element: _jsx(Outlet, {}),
                children: [
                    {
                        path: '/system/setting',
                        element: (_jsx(LoginRequired, { children: _jsx(Suspense, { fallback: _jsx(_Fragment, { children: "...loading" }), children: _jsx(SettingPage, {}) }) })),
                    },
                ],
            },
        ],
    },
    {
        path: '/login',
        element: (_jsx(Suspense, { fallback: _jsx(_Fragment, { children: "...loading" }), children: _jsx(LoginPage, {}) })),
    },
];
