import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-param-reassign */
import { Card, Edit, List, Minus, Plus } from '@component/score/common';
import { AddSubjectView } from '@component/score/common/AddSubjectView';
import { CardView } from '@component/score/common/CardView';
import { ListView } from '@component/score/common/ListView';
import { DeleteHighSchoolConfirm } from '@component/score/test/highSchool/DeleteHighSchoolConfirm';
import { HighSchoolListView } from '@component/score/test/highSchool/listView';
import { useReportCardSubjectsIds } from '@hook/useReportCardSubjectsIds';
import Spacing from '@simppl/component/Spacing';
import Text from '@simppl/component/Text';
import { reportCards as reportCardsApi } from '@simppl/repository/test';
import c from '@simppl/util/c';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { HighSchoolModeContext } from './Provider';
export function ViewTest({ highSchools, appendHighSchool, removeHighSchool }) {
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: 'flex w-full flex-col gap-[32px]', children: highSchools.map((reporcards, index) => (_jsx(ViewTestCard, { index: index, reportCards: reporcards, removeHighSchool: removeHighSchool }, reporcards.highschoolId))) }), _jsx(Spacing, { height: '32px' }), _jsx(CreateSchoolButton, { appendHighSchool: appendHighSchool })] }));
}
function CreateSchoolButton({ appendHighSchool }) {
    const { changeMode } = useContext(HighSchoolModeContext);
    return (_jsxs("button", { type: 'button', className: 'flex h-[58px] w-full items-center justify-center gap-[8px] rounded-[8px] border-[1px] border-blue-500 text-blue-500 ', onClick: () => {
            changeMode('editSchool', {
                appendHighSchool,
                mode: 'new',
            });
        }, children: [_jsx(Plus, {}), _jsx(Text, { color: 'text-blue-500', as: 'Title', size: 1, weight: 'Medium', children: "\uD559\uAD50 \uCD94\uAC00" })] }));
}
function ViewTestCard({ reportCards, index, removeHighSchool }) {
    const [deleteOpen, setDeleteOpen] = useState(false);
    const { changeMode } = useContext(HighSchoolModeContext);
    const [mode, setMode] = useState('card');
    const subjectIds = useReportCardSubjectsIds();
    const { id } = useParams();
    // eslint-disable-next-line no-shadow
    const handleChangeMode = (mode) => {
        setMode(mode);
    };
    return (_jsxs("div", { className: 'border-Blue-Ct-2 flex w-full flex-col rounded-[16px] border-[1px] px-[51px] py-[32px] shadow-[0px_1px_2px_0px_rgba(20,_25,_97,_0.10),_2px_2px_3px_0px_rgba(20,_25,_97,_0.09),_4px_5px_4px_0px_rgba(20,_25,_97,_0.05),_8px_9px_5px_0px_rgba(20,_25,_97,_0.01),_12px_14px_5px_0px_rgba(20,_25,_97,_0.00)]', children: [_jsxs("div", { className: 'flex w-full items-center justify-center gap-[15px]', children: [_jsx(Text, { color: 'text-Blue-4', weight: 'Bold', as: 'Label', size: 1, children: reportCards?.schoolName ?? '학교명' }), _jsx("button", { type: 'button', onClick: () => {
                            changeMode('editSchool', {
                                mode: 'edit',
                                editData: {
                                    index,
                                    highschoolId: reportCards.highschoolId,
                                    countryName: reportCards.countryName,
                                    schoolName: reportCards.schoolName,
                                    countryId: reportCards.countryId,
                                },
                            });
                        }, children: _jsx(Edit, {}) })] }), _jsx(Spacing, { height: '32px' }), _jsxs("div", { className: 'flex w-full justify-center gap-[22px]', children: [_jsx("button", { type: 'button', onClick: () => handleChangeMode('card'), children: _jsx(Card, { disabled: mode !== 'card' }) }), _jsx("button", { type: 'button', onClick: () => handleChangeMode('list'), children: _jsx(List, { disabled: mode !== 'list' }) })] }), _jsx(Spacing, { height: '32px' }), _jsxs("div", { className: c('flex w-full flex-wrap gap-[32px]', mode === 'list' && 'flex-col justify-center'), children: [reportCards.reportCardList.map((data) => {
                        return mode === 'card' ? (_jsx(CardView, { onClick: () => {
                                changeMode('editScore', {
                                    mode: 'edit',
                                    highschoolId: reportCards.highschoolId,
                                    data,
                                });
                            }, semester: `${data.grade}학년 ${data.semester}학기`, testName: data.curriculum === 'KOREA' ? '한국학교' : data.curriculum, score: data.gpa, countOfSubjects: data.reportCardDetailList?.length }, data.reportCardId)) : (_jsx(ListView, { onClick: () => {
                                changeMode('editScore', {
                                    mode: 'edit',
                                    highschoolId: reportCards.highschoolId,
                                    data,
                                });
                            }, semester: `${data.grade}학년 ${data.semester}학기`, testName: data.curriculum === 'KOREA' ? '한국학교' : data.curriculum, score: data.gpa, countOfSubjects: data.reportCardDetailList?.length, children: _jsx(HighSchoolListView, { testName: data.curriculum, reportCardDetailList: data.reportCardDetailList }) }, data.reportCardId));
                    }), _jsx(AddSubjectView, { title: '\uD559\uB144 / \uD559\uAE30 / \uC131\uC801', mode: mode, onClick: () => {
                            // 학기 추가시 마지막 커리큘럼을 추가하기 위한 로직
                            changeMode('editScore', {
                                mode: 'new',
                                highschoolId: reportCards.highschoolId,
                            });
                        } })] }), _jsx(Spacing, { height: '32px' }), _jsxs("button", { className: ' flex   h-[40px]   min-w-[40px] items-center justify-center whitespace-pre rounded-[4px] border-[1px] border-blue-500 text-blue-500', type: 'button', onClick: () => {
                    setDeleteOpen(true);
                }, children: [_jsx(Minus, {}), ` 학교 삭제`] }), _jsx(DeleteHighSchoolConfirm, { open: deleteOpen, onConfirm: async () => {
                    const res = await Promise.all(reportCards.reportCardList.map(({ reportCardId }) => reportCardsApi.delete({
                        reportCardId,
                    })));
                    if (res.filter((r) => r.status !== 200).length > 0) {
                        alert('삭제중에 에러가 발생했습니다. 다시 시도해주세요.');
                    }
                    removeHighSchool(reportCards.highschoolId);
                    setDeleteOpen(false);
                    changeMode('viewTest');
                }, onClose: () => setDeleteOpen(false) })] }));
}
