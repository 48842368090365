import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/destructuring-assignment */
import { Button, Checkbox, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Select, SelectItem, } from '@nextui-org/react';
import Editor from '@simppl/component/Editor';
import useMutation from '@simppl/react-query/useMutation';
import useQuery from '@simppl/react-query/useQuery';
import useQueryClient from '@simppl/react-query/useQueryClient';
import useSuspenseQuery from '@simppl/react-query/useSuspenseQuery';
import boardsApi from '@simppl/repository/board';
import { category } from '@simppl/repository/categories';
import { useEffect, useState } from 'react';
const BLACK_LIST = ['추천글', '베스트글'];
export function PostModal(props) {
    const { postId, isEditMode, title, setTitle, content, setContent, isAnonymous, setIsAnonymous, categoryId, setCategoryId, subCategoryId, setSubCategoryId, setEditor, } = useCommunityWriteState(props.mode === 'edit' ? { boardId: props.boardId, categoryId: props.categoryId } : null);
    const queryClient = useQueryClient();
    const patchComunityPostHook = useMutation(boardsApi.boardId.posts.postId.patch);
    const postComunityPostHook = useMutation(boardsApi.boardId.posts.post);
    const { data: { categories }, } = useSuspenseQuery({
        queryKey: ['category.community.get'],
        queryFn: () => category.community.get(),
    });
    // 게시판
    const categoryOptions = categories.reduce((acc, { id, title }) => {
        // 추천글과 베스트글은 제외
        if (BLACK_LIST.includes(title))
            return acc;
        acc[id] = title;
        return acc;
    }, {});
    // 말머리
    const caregoryIdMap = categories.reduce((acc, { id, children }) => {
        acc[id] = children?.reduce((_acc, { id: _id, title }) => {
            _acc[_id] = title;
            return _acc;
        }, {});
        return acc;
    }, {});
    const handleSave = () => {
        if ((subCategoryId || categoryId) && title && content) {
            if (isEditMode)
                patchComunityPostHook.mutate({
                    postId: postId,
                    title,
                    content,
                    isAnonymous,
                    categoryId: subCategoryId ?? categoryId,
                    isPrivate: false,
                }, {
                    onSuccess: (data) => {
                        // success
                        queryClient.invalidateQueries(['posts']);
                        /**
                         * TODO 추후 변경된 카테고리로 수정 필요
                         */
                        props.onClose();
                    },
                    onError: () => {
                        // error
                    },
                });
            else
                postComunityPostHook.mutate({
                    boardId: (subCategoryId ?? categoryId),
                    title,
                    content,
                    isAnonymous,
                    isPrivate: false,
                }, {
                    onSuccess: (data) => {
                        // success
                        queryClient.invalidateQueries(['posts']);
                        props.onClose();
                    },
                    onError: () => {
                        // error
                    },
                });
        }
    };
    return (_jsx(Modal, { isOpen: props.isOpen, onOpenChange: props.onOpenChange, children: _jsx(ModalContent, { children: () => (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { children: _jsx("p", { children: isEditMode ? '글수정' : '글쓰기' }) }), _jsxs(ModalBody, { children: [_jsxs("div", { className: 'flex flex-col gap-[16px]', children: [_jsxs("div", { className: 'flex gap-[16px]', children: [_jsx(Select, { disabled: isEditMode, className: 'border-Blue-3 text-Blue-Ct-5 w-full border-[1px]', placeholder: '\uAC8C\uC2DC\uD310', selectedKeys: [categoryId ?? ''], onChange: (e) => setCategoryId(e.target.value), children: Object.entries(categoryOptions).map(([key, value]) => (_jsx(SelectItem, { children: value }, key))) }), _jsx(Select, { className: 'border-Blue-3 text-Blue-Ct-5 w-full border-[1px]', placeholder: '\uB9D0\uBA38\uB9AC', selectedKeys: [subCategoryId ?? ''], onChange: (e) => setSubCategoryId(e.target.value), children: (categoryId
                                                    ? Object.entries(caregoryIdMap[categoryId] ?? {})
                                                    : []).map(([key, value]) => (_jsx(SelectItem, { children: value }, key))) })] }), _jsx(Input, { className: 'w-full', placeholder: '\uC81C\uBAA9', value: title, onChange: (e) => setTitle(e.target.value) })] }), _jsx(Editor, { setEditor: setEditor, initialData: content ?? '', onChange: setContent, height: 300 })] }), _jsxs(ModalFooter, { className: 'flex flex-col items-center gap-[16px]', children: [_jsx(Checkbox, { isSelected: isAnonymous, onClick: () => setIsAnonymous((prev) => !prev), children: "\uC775\uBA85\uC73C\uB85C \uB4F1\uB85D\uD558\uAE30" }), _jsx(Button, { className: 'w-full', color: 'primary', onClick: handleSave, children: isEditMode ? '수정하기' : '등록하기' })] })] })) }) }));
}
const useCommunityWriteState = (props) => {
    const [editor, setEditor] = useState(null);
    const postId = props?.boardId;
    const isEditMode = !!props;
    const { data } = useQuery(['boardsApi.boardId.posts.postId.get', postId], () => boardsApi.boardId.posts.postId.get({ postId: postId }), { enabled: !!postId });
    const postData = data?.data;
    const [isAnonymous, setIsAnonymous] = useState(false);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [categoryId, setCategoryId] = useState(null);
    const [subCategoryId, setSubCategoryId] = useState(null);
    useEffect(() => {
        if (isEditMode) {
            console.log(postData);
            setIsAnonymous(postData?.isAnonymous ?? false);
            setTitle(postData?.title ?? '');
            editor?.setData(postData?.content ?? '');
            setCategoryId(postData?.category.parent.id ?? null);
            setSubCategoryId(postData?.category.id ?? null);
        }
    }, [isEditMode, postData, editor]);
    return {
        postId,
        isEditMode,
        title,
        setTitle,
        content,
        setContent,
        isAnonymous,
        setIsAnonymous,
        categoryId,
        setCategoryId,
        subCategoryId,
        setSubCategoryId,
        setEditor,
    };
};
