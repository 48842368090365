import * as yup from 'yup';
import { SCHEMA_MESSAGE } from './message';
export const ETC_schema = yup
    .object()
    .shape({
    subjectList: yup
        .array()
        .min(1, SCHEMA_MESSAGE.scoreMessage('기타 시험', '과목을').customMessage('최소 한개 이상의 과목을 입력해주세요.'))
        .of(yup.object().shape({
        transcript: yup
            .string()
            .required(SCHEMA_MESSAGE.scoreMessage('기타 시험', '파일을').customMessage('업로드 해주세요.')),
        examDate: yup
            .string()
            .required(SCHEMA_MESSAGE.scoreMessage('기타 시험', '시험일자를').requiredMessage())
            .typeError(SCHEMA_MESSAGE.scoreMessage('기타 시험', '시험일자를').requiredMessage()),
        publishingOrganization: yup
            .string()
            .required(SCHEMA_MESSAGE.scoreMessage('기타 시험', '발급기관을').requiredMessage()),
        name: yup
            .mixed()
            .required(SCHEMA_MESSAGE.scoreMessage('기타 시험', '시험명을').requiredMessage()),
        score: yup
            .number()
            .required(SCHEMA_MESSAGE.scoreMessage('기타 시험', '점수를').requiredMessage()),
        totalScore: yup
            .number()
            .required(SCHEMA_MESSAGE.scoreMessage('기타 시험', '만점을').requiredMessage())
            .typeError(SCHEMA_MESSAGE.scoreMessage('기타 시험', '만점을').requiredMessage()),
    })),
})
    .default(null)
    .nullable();
