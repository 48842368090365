import { jsx as _jsx } from "react/jsx-runtime";
import SwitchCase from '@simppl/component/SwitchCase';
import { ALevelForm } from './ALevel';
import { APForm } from './AP';
import { ETCForm } from './ETC';
import { IBForm } from './IB';
import { KOREAForm } from './KOREA';
export function HightSchoolForm({ testName, content }) {
    return (_jsx(SwitchCase, { value: testName, caseBy: {
            'A-LEVEL': _jsx(ALevelForm, { content: content }),
            AP: _jsx(APForm, { content: content }),
            IB: _jsx(IBForm, { content: content }),
            ETC: _jsx(ETCForm, { content: content }),
            KOREA: _jsx(KOREAForm, { content: content }),
        } }));
}
