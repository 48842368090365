import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/destructuring-assignment */
import { useCompetitiveUnits } from '@hook/useCompetitiveUnits';
import { useSimulatedApplication } from '@hook/useSimulatedApplication';
import { Autocomplete, AutocompleteItem, Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, } from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { competitiveUnits } from '@simppl/repository/competitiveUnits';
import { useEffect, useState } from 'react';
export function CompetitiveUnitModal(props) {
    const [high, setHigh] = useState(0.2);
    const [middle, setMiddle] = useState(0.6);
    const [low, setLow] = useState(0.2);
    const [selectedKeys, setSelectedKeys] = useState('');
    const item = props.mode === 'edit'
        ? useCompetitiveUnits().data.find((competitiveUnit) => competitiveUnit.competitiveUnitId === props.competitiveUnitId)
        : null;
    const [input, setInput] = useState('');
    const [admissionCapacity, setAdmissionCapacity] = useState(0);
    const simulatedApplication = useSimulatedApplication();
    const queryClient = useQueryClient();
    const postUniversitiesHook = useMutation(competitiveUnits.post);
    const patchUniversitiesHook = useMutation(competitiveUnits.patch);
    useEffect(() => {
        if (props.mode === 'edit' && item) {
            setInput(item.name);
            setSelectedKeys(item.simulatedApplicationId);
            setAdmissionCapacity(item.admissionCapacity);
            setHigh(item.high);
            setMiddle(item.middle);
            setLow(item.low);
        }
    }, [props, item]);
    return (_jsx(Modal, { isOpen: props.isOpen, onOpenChange: props.onOpenChange, children: _jsx(ModalContent, { children: () => (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { className: 'flex flex-col gap-1', children: props.mode === 'add' ? '경쟁단위 추가' : '경쟁단위 수정' }), _jsxs(ModalBody, { children: [_jsx(Input, { value: input, onChange: (e) => setInput(e.target.value), label: '\uACBD\uC7C1\uB2E8\uC704 \uC774\uB984', labelPlacement: 'outside' }), _jsx(Input, { type: 'number', value: admissionCapacity, onChange: (e) => setAdmissionCapacity(parseInt(e.target.value, 10)), label: '\uBAA8\uC9D1\uC778\uC6D0', labelPlacement: 'outside' }), _jsx(Autocomplete, { selectedKey: selectedKeys, onSelectionChange: setSelectedKeys, children: simulatedApplication.data.map((categoryItem) => (_jsx(AutocompleteItem, { value: categoryItem.simulatedApplicationId, className: 'capitalize', children: categoryItem.name }, categoryItem.simulatedApplicationId))) }), _jsx(Input, { type: 'number', value: high, onChange: (e) => setHigh(parseFloat(e.target.value)), label: 'high', labelPlacement: 'outside' }), _jsx(Input, { type: 'number', value: middle, onChange: (e) => setMiddle(parseFloat(e.target.value)), label: 'middle', labelPlacement: 'outside' }), _jsx(Input, { type: 'number', value: low, onChange: (e) => setLow(parseFloat(e.target.value)), label: 'low', labelPlacement: 'outside' })] }), _jsxs(ModalFooter, { children: [_jsx(Button, { color: 'danger', variant: 'light', onPress: props.onClose, children: "\uCDE8\uC18C" }), _jsx(Button, { type: 'submit', form: 'authorization', color: 'primary', onPress: () => {
                                    if (props.mode === 'add')
                                        postUniversitiesHook.mutate({
                                            name: input,
                                            simulatedApplicationId: selectedKeys,
                                            admissionCapacity,
                                            high,
                                            middle,
                                            low,
                                        }, {
                                            onSuccess: () => {
                                                queryClient.invalidateQueries(['getCompetitiveUnits']);
                                                props.onClose();
                                            },
                                        });
                                    else if (props.mode === 'edit')
                                        patchUniversitiesHook.mutate({
                                            competitiveUnitId: props.competitiveUnitId,
                                            name: input,
                                            simulatedApplicationId: selectedKeys,
                                            admissionCapacity,
                                            high,
                                            middle,
                                            low,
                                        }, {
                                            onSuccess: () => {
                                                queryClient.invalidateQueries(['getCompetitiveUnits']);
                                                props.onClose();
                                            },
                                        });
                                }, children: "\uC800\uC7A5" })] })] })) }) }));
}
