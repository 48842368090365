import { jsx as _jsx } from "react/jsx-runtime";
import SwitchCase from '@simppl/component/SwitchCase';
import { ACTForm } from './ACT';
import { ALevelForm } from './ALevel';
import { APForm } from './AP';
import { IBForm } from './IB';
import { SATForm } from './SAT';
export function StandardForm({ testName }) {
    const content = 'edit';
    return (_jsx(SwitchCase, { value: testName, caseBy: {
            ACT: _jsx(ACTForm, { content: content }),
            'A-LEVEL': _jsx(ALevelForm, { content: content }),
            AP: _jsx(APForm, { content: content }),
            SAT: _jsx(SATForm, { content: content }),
            IB: _jsx(IBForm, { content: content }),
        } }));
}
