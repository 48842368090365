export function Etc(data) {
    return data.map((item) => [
        {
            title: '업로드 url',
            type: 'link',
            content: item.transcript,
        },
        {
            title: '응시일자',
            content: item.examDate,
        },
        {
            title: '발행기관',
            content: item.publishingOrganization,
        },
        {
            title: '과목명',
            content: item.name,
        },
        {
            title: '점수',
            content: item.score,
        },
        {
            title: '총점',
            content: item.totalScore,
        },
    ]);
}
