import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/no-array-index-key */
import useTestByUserId from '@hook/useTestByUserId';
import { Accordion, AccordionItem, Button, Spacer, Tab, Tabs, useDisclosure, } from '@nextui-org/react';
import { UserIdContext } from '@page/user/UserDetailPage';
import { Pencil } from 'lucide-react';
import { useContext } from 'react';
import { EditScoreModal } from './modal/EditScoreModal';
import DownloadButton from './score/DownloadPdfButton';
import { activityAward } from './score/activityAward';
import { HighSchool } from './score/highSchool';
import { language } from './score/language';
import { standarized } from './score/standarized';
export const UserScoreTab = () => {
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    return (_jsxs("div", { className: 'flex flex-col', children: [isOpen && (_jsx(EditScoreModal, { isOpen: isOpen, onOpenChange: onOpenChange, onClose: onOpenChange })), _jsx(Button, { onPress: onOpen, isIconOnly: true, children: _jsx(Pencil, {}) }), _jsx(Spacer, { y: 1 }), _jsxs(Tabs, { children: [_jsx(Tab, { title: '\uACE0\uAD50 \uC131\uC801', children: _jsx(HighSchoolTest, {}) }, 'report-cards'), _jsx(Tab, { title: '\uC5B4\uD559 \uC131\uC801', children: _jsx(LanguageTest, {}) }, 'language-tests'), _jsx(Tab, { title: '\uD45C\uC900\uD654 \uC131\uC801', children: _jsx(StandardizedTest, {}) }, 'standarized-tests'), _jsx(Tab, { title: '\uD65C\uB3D9 \uC131\uC801', children: _jsx(ActivitiesAwardTest, {}) }, 'activities-awards')] })] }));
};
const HighSchoolTest = () => {
    const { userId } = useContext(UserIdContext);
    const highSchoolTest = useTestByUserId('report-cards', userId);
    return (_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx(DownloadButton, { pdfUrl: highSchoolTest.transcript, pdfName: '\uACE0\uAD50\uC131\uC801' }), highSchoolTest.reportCardList.map((semesterData) => (_jsx(Accordion, { variant: 'bordered', children: _jsxs(AccordionItem, { title: `${semesterData.grade}학년 ${semesterData.semester}학기`, children: [_jsxs("tr", { className: 'h-10', children: [_jsx("th", { className: 'w-20', children: "\uAD6D\uAC00" }), _jsx("td", { children: semesterData.countryName })] }), _jsxs("tr", { className: 'h-10', children: [_jsx("th", { className: 'w-20', children: "\uACE0\uB4F1\uD559\uAD50" }), _jsx("td", { children: semesterData.highschoolName })] }), _jsxs("tr", { className: 'h-10', children: [_jsx("th", { className: 'w-20', children: "\uD3C9\uADE0\uD3C9\uC810" }), _jsxs("td", { children: [semesterData.gpa, " / ", semesterData.perfectScore] })] }), _jsxs("tr", { className: 'h-10', children: [_jsxs("th", { className: 'flex w-20 flex-col', children: ["\uD3C9\uADE0\uD3C9\uC810", _jsx("span", { className: 'text-[10px]', children: "(100\uC810 \uD658\uC0B0)" })] }), _jsxs("td", { children: [Math.ceil((semesterData.gpa / (semesterData.perfectScore ?? 100)) * 100 * 100) /
                                            100, "/ 100"] })] }), _jsx(Accordion, { children: _jsx(AccordionItem, { title: semesterData.curriculum, children: _jsx(HighSchool, { testName: semesterData.curriculum, data: semesterData.reportCardDetailList }) }) })] }) }, semesterData.reportCardId)))] }));
};
const LanguageTest = () => {
    const { userId } = useContext(UserIdContext);
    const languageTest = useTestByUserId('language-tests', userId);
    return (_jsx("div", { className: 'flex flex-col gap-4', children: Object.entries(languageTest).map(([key, value]) => (_jsx(Accordion, { variant: 'bordered', children: _jsx(AccordionItem, { title: key, children: key === 'ETC'
                    ? language[key](value).map((subject, index) => (_jsx("div", { className: 'pl-8', children: _jsx(Accordion, { children: _jsx(AccordionItem, { title: `과목${index + 1}`, children: subject.map(({ title, content, type }) => type === 'link' ? (_jsx("tr", { className: 'w-full', children: _jsx(DownloadButton, { pdfUrl: content, pdfName: key }) }, `${key}-${title}`)) : (_jsxs("tr", { className: 'h-10', children: [_jsx("th", { className: 'w-20', children: title }), _jsx("td", { children: content })] }, `${key}-${title}`))) }) }) }, `${subject}-${index}`)))
                    : language[key](value).map(({ title, content, type }) => type === 'link' ? (_jsx("tr", { className: 'w-full', children: _jsx(DownloadButton, { pdfUrl: content, pdfName: key }) }, `${key}-${title}`)) : (_jsxs("tr", { className: 'h-10', children: [_jsx("th", { className: 'w-20', children: title }), _jsx("td", { children: content })] }, `${key}-${title}`))) }) }, key))) }));
};
const StandardizedTest = () => {
    const { userId } = useContext(UserIdContext);
    const standarizedTest = useTestByUserId('standarized-tests', userId);
    return (_jsx("div", { className: 'flex flex-col gap-4', children: Object.entries(standarizedTest)
            .filter(([_, value]) => !!value)
            .map(([key, value]) => (_jsx(Accordion, { variant: 'bordered', children: _jsx(AccordionItem, { title: key, children: key === 'ETC'
                    ? standarized[key](value).map((subject, index) => (_jsx("div", { className: 'pl-8', children: _jsx(Accordion, { children: _jsx(AccordionItem, { title: `과목${index + 1}`, children: subject.map(({ title, content, type }) => type === 'link' ? (_jsx("tr", { className: 'w-full', children: _jsx(DownloadButton, { pdfUrl: content, pdfName: key }) }, `${key}-${title}`)) : (_jsxs("tr", { className: 'h-10', children: [_jsx("th", { className: 'w-20', children: title }), _jsx("td", { children: content })] }, `${key}-${title}`))) }) }) }, `${subject}-${index}`)))
                    : standarized[key](value).map((field) => {
                        if (field.type === 'array') {
                            return field.children.map((subject) => (_jsx("div", { className: 'pl-8', children: _jsx(Accordion, { children: _jsx(AccordionItem, { title: subject.subjectName, children: subject.content.map(({ title, content }, index) => (_jsxs("tr", { className: 'h-10', children: [_jsx("th", { className: 'w-20', children: title }), _jsx("td", { children: content })] }, `${key}-${index}-${title}`))) }) }) }, subject.subjectName)));
                        }
                        const { title, content, type } = field;
                        return type === 'link' ? (_jsx("tr", { className: 'w-full', children: _jsx(DownloadButton, { pdfUrl: content, pdfName: key }) }, `${key}-${title}`)) : (_jsxs("tr", { className: 'h-10', children: [_jsx("th", { className: 'w-20', children: title }), _jsx("td", { children: content })] }, `${key}-${title}`));
                    }) }) }, key))) }));
};
const ActivitiesAwardTest = () => {
    const { userId } = useContext(UserIdContext);
    const activitiesAwardTest = useTestByUserId('activities-awards', userId);
    const activitiesAwardTestData = activitiesAwardTest.activityAwardList.reduce((acc, item) => {
        if ('awardDate' in item) {
            acc.AWARD.push({
                awardDate: item.awardDate,
                content: item.content,
                division: item.division,
                type: item.type,
                publishingOrganization: item.publishingOrganization,
                transcript: item.transcript,
            });
        }
        else {
            acc.ACTIVITY.push({
                endDate: item.endDate,
                startDate: item.startDate,
                content: item.content,
                division: item.division,
                type: item.type,
                publishingOrganization: item.publishingOrganization,
                transcript: item.transcript,
            });
        }
        return acc;
    }, { AWARD: [], ACTIVITY: [] });
    return (_jsx("div", { className: 'flex flex-col gap-4', children: Object.entries(activitiesAwardTestData).map(([key, value]) => (_jsx(Accordion, { variant: 'bordered', children: _jsx(AccordionItem, { title: key === 'AWARD' ? '수상' : '활동', children: value.map((subject, index) => (_jsx("div", { className: 'pl-8', children: _jsx(Accordion, { children: _jsx(AccordionItem, { title: `${key === 'AWARD' ? '수상' : '활동'}${index + 1}`, children: activityAward[key](subject).map(({ title, content, type }) => type === 'link' ? (_jsx("tr", { className: 'w-full', children: _jsx(DownloadButton, { pdfUrl: content, pdfName: key }) }, `${key}-${title}`)) : (_jsxs("tr", { className: 'h-10', children: [_jsx("th", { className: 'w-20', children: title }), _jsx("td", { children: content })] }, `${key}-${title}`))) }) }) }, `${key}-${index}`))) }) }, key))) }));
};
