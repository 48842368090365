import axios from '..';
import type {
  DeleteAdmissionReqType,
  GetAdmissionReqType,
  GetAdmissionsMajorsWithCountReqType,
  GetUserAdmissionsReqType,
  PatchAdmissionReqType,
  PatchUserAdmissionsReqType,
  PostAdmissionCompetitiveUnitReqType,
  PostAdmissionReqType,
  PostUserAdmissionsReqType,
  PutAdmissionsMajorsWithCountReqType,
} from './req';
import type {
  DeleteAdmissionResType,
  GetAdmissionResType,
  GetAdmissionsMajorsWithCountResType,
  GetUserAdmissionsAdmissionsResType,
  GetUserAdmissionsResType,
  PatchAdmissionResType,
  PostAdmissionResType,
  PostUserAdmissionsResType,
  PutAdmissionsMajorsWithCountResType,
} from './res';

export const getUserAdmissions = (body: GetUserAdmissionsReqType) => {
  const url = new URL(`${axios.getUri()}/user-admissions`);
  Object.entries(body).forEach(([key, value]) => url.searchParams.append(key, String(value)));
  return axios.get<GetUserAdmissionsResType>(url.href).then((res) => res.data);
};

// 모의지원
export const postUserAdmissions = (body: PostUserAdmissionsReqType) =>
  axios.post<PostUserAdmissionsResType>('/user-admissions', body).then((res) => res.data);

export const deleteUserAdmissions = (userAdmissionId: string) =>
  axios.delete(`/user-admissions/${userAdmissionId}`).then((res) => res.data);

export const patchUserAdmissions = (userAdmissionId: string, body: PatchUserAdmissionsReqType) =>
  axios.patch(`/user-admissions/${userAdmissionId}`, body).then((res) => res.data);

export const getUserAdmissionsAdmissions = () =>
  axios
    .get<GetUserAdmissionsAdmissionsResType>(`/user-admissions/admissions`)
    .then((res) => res.data);

export const getAdmissionsMajorsWithCount = (body: GetAdmissionsMajorsWithCountReqType) => {
  const url = new URL(`${axios.getUri()}/admissions/major`);
  Object.entries(body).forEach(([key, value]) => url.searchParams.append(key, String(value)));
  return axios.get<GetAdmissionsMajorsWithCountResType>(url.href).then((res) => res.data);
};

export const putAdmissionsMajorsWithCount = (
  admissionId: string,
  body: PutAdmissionsMajorsWithCountReqType,
) => {
  const url = new URL(`${axios.getUri()}/admissions/${admissionId}`);
  Object.entries(body).forEach(([key, value]) => url.searchParams.append(key, String(value)));
  return axios.patch<PutAdmissionsMajorsWithCountResType>(url.href).then((res) => res.data);
};

export const admission = {
  get: (params?: GetAdmissionReqType) =>
    axios.get<GetAdmissionResType>('/admissions', { params }).then((res) => res.data),
  post: (body: PostAdmissionReqType) =>
    axios.post<PostAdmissionResType>('/admissions', body).then((res) => res.data),
  patch: ({ admissionId, ...body }: PatchAdmissionReqType) =>
    axios.patch<PatchAdmissionResType>(`/admissions/${admissionId}`, body).then((res) => res.data),
  delete: ({ admissionId }: DeleteAdmissionReqType) =>
    axios.delete<DeleteAdmissionResType>(`/admissions/${admissionId}`).then((res) => res.data),
};

export const admissionCompetitiveUnit = {
  patch: ({ admissionId, ...body }: PostAdmissionCompetitiveUnitReqType) =>
    axios.patch(`/admissions/${admissionId}/competitive-units`, body).then((res) => res.data),
};
