import * as yup from 'yup';
import { ETC_schema } from '../etc';
import { ALEVEL_schema } from './aLevel';
import { ACT_schema } from './act';
import { AP_schema } from './ap';
import { IB_schema } from './ib';
import { SAT_schema } from './sat';
export const standardizedTestSchemaMap = yup.object().shape({
    IB: IB_schema,
    AP: AP_schema,
    ACT: ACT_schema,
    'A-LEVEL': ALEVEL_schema,
    SAT: SAT_schema,
    ETC: ETC_schema,
});
