const c = (...args: (string | undefined | boolean | Record<string, boolean>)[]) =>
  args
    .map((arg) => (arg instanceof Object ? filterObj(arg) : arg))
    .filter((arg) => !!arg)
    .join(' ');

export default c;

const filterObj = (arg: Object) =>
  Object.entries(arg)
    .filter(([k, v]) => !!v)
    .map(([k, v]) => k);
