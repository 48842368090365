import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CheckBoxComponent from '@simppl/component/CheckBox';
import Text from '@simppl/component/Text';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
export function CheckBox({ name, title }) {
    const methods = useFormContext();
    useEffect(() => {
        methods.register(name, { value: false });
    }, [methods]);
    return (_jsxs("div", { className: 'flex w-[122px] items-center gap-[16px]', children: [_jsx("button", { type: 'button', className: 'h-[24px] w-[24px]', onClick: () => methods.setValue(name, !methods.watch(name)), children: _jsx(CheckBoxComponent, { checked: methods.watch(name) }) }), title && (_jsx(Text, { className: ' inline-block overflow-hidden text-ellipsis whitespace-nowrap', color: 'text-White', weight: 'Regular', as: 'Label', size: 1, children: title }))] }));
}
