import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/destructuring-assignment */
import { useNoticeId } from '@hook/useNoticeId';
import { Button, Checkbox, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, } from '@nextui-org/react';
import Editor from '@simppl/component/Editor';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { notice } from '@simppl/repository/notice';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
export function NoticeModal(props) {
    const [content, setContent] = useState('');
    const [isFixed, setIsFixed] = useState(false);
    const item = props.mode === 'edit' ? useNoticeId({ id: parseInt(props.id, 10) }).data : null;
    if (props.mode === 'edit' && !item)
        return null;
    const queryClient = useQueryClient();
    const postItemHook = useMutation(notice.post);
    const patchItemHook = useMutation(notice.patch);
    const methods = useForm({
        mode: 'all',
        reValidateMode: 'onSubmit',
    });
    const { control, handleSubmit } = methods;
    const [title, 
    // content,
    // isFixed,
    viewCount,] = [
        'title',
        // 'content',
        // 'isFixed',
        'viewCount',
    ].map((t) => methods.watch(t));
    useEffect(() => {
        if (props.mode === 'edit' && item) {
            methods.setValue('title', item.title);
            setIsFixed(item.isFixed);
            setContent(item.content);
            methods.setValue('viewCount', item.viewCount);
        }
    }, [props, item]);
    return (_jsx(Modal, { isOpen: props.isOpen, onOpenChange: props.onOpenChange, children: _jsx(ModalContent, { children: () => (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { className: 'flex flex-col gap-1', children: props.mode === 'add' ? '공지사항 추가' : '공지사항 수정' }), _jsxs(ModalBody, { children: [_jsx(Controller, { name: 'title', control: control, defaultValue: '', render: ({ field }) => (_jsx(Input, { labelPlacement: 'outside', placeholder: '\uC81C\uBAA9', ...field })) }), _jsx(Editor, { initialData: item?.content || '', onChange: setContent }), _jsx(Checkbox, { isSelected: isFixed, onValueChange: setIsFixed, children: "\uD544\uB3C5 \uC5EC\uBD80" }), _jsx(Controller, { name: 'viewCount', control: control, defaultValue: '', render: ({ field }) => (_jsx(Input, { type: 'number', labelPlacement: 'outside', placeholder: '\uC870\uD68C\uC218', ...field })) })] }), _jsxs(ModalFooter, { children: [_jsx(Button, { color: 'danger', variant: 'light', onPress: props.onClose, children: "\uCDE8\uC18C" }), _jsx(Button, { type: 'submit', form: 'authorization', color: 'primary', onPress: () => {
                                    if (props.mode === 'add')
                                        postItemHook.mutate({
                                            title,
                                            content,
                                            isFixed,
                                            viewCount: parseInt(viewCount, 10),
                                        }, {
                                            onSuccess: () => {
                                                queryClient.invalidateQueries(['getNotices']);
                                                props.onClose();
                                            },
                                        });
                                    else if (props.mode === 'edit' && item?.id)
                                        patchItemHook.mutate({
                                            id: item.id.toString(),
                                            title,
                                            content,
                                            isFixed,
                                            viewCount: parseInt(viewCount, 10),
                                        }, {
                                            onSuccess: () => {
                                                queryClient.invalidateQueries(['getNotices']);
                                                props.onClose();
                                            },
                                        });
                                }, children: "\uC800\uC7A5" })] })] })) }) }));
}
