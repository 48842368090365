export const convertToDivideActivityAwardData = (data) => data.reduce((acc, item) => {
    if (item.subject === 'ACTIVITY') {
        acc.activityList.push({
            endDate: item.endDate,
            startDate: item.startDate,
            content: item.content,
            division: item.division,
            type: item.type,
            publishingOrganization: item.publishingOrganization,
            transcript: item.transcript,
        });
    }
    else {
        acc.awardList.push({
            awardDate: item.awardDate,
            content: item.content,
            division: item.division,
            type: item.type,
            publishingOrganization: item.publishingOrganization,
            transcript: item.transcript,
        });
    }
    return acc;
}, { awardList: [], activityList: [] });
