import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/destructuring-assignment */
import ShadowDOM from '@component/ShadowDOM';
import { useInquiriesAnswers } from '@hook/useInquiriesAnswers';
import { Button, Card, CardBody, CardHeader, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, } from '@nextui-org/react';
import Editor from '@simppl/component/Editor';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { inquiryAnswer } from '@simppl/repository/inquiries';
import { useEffect, useState } from 'react';
export function InquiryAnswerModal(props) {
    const [content, setContent] = useState('');
    const answer = props.id ? useInquiriesAnswers({ id: props.id }) : undefined;
    const queryClient = useQueryClient();
    const postItemHook = useMutation(inquiryAnswer.post);
    const patchItemHook = useMutation(inquiryAnswer.patch);
    useEffect(() => {
        if (answer && content === '' && answer.data.answer) {
            setContent(answer.data.answer.content);
        }
    }, [props, answer]);
    return (_jsx(Modal, { isOpen: props.isOpen, onOpenChange: props.onOpenChange, children: _jsx(ModalContent, { children: () => (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { className: 'flex flex-col gap-1', children: "\uB2F5\uBCC0 \uB2EC\uAE30" }), _jsxs(ModalBody, { children: [_jsxs(Card, { children: [_jsx(CardHeader, { children: _jsx("h4", { children: "\uBB38\uC758 \uB0B4\uC6A9" }) }), _jsx(CardBody, { children: _jsx(ShadowDOM, { html: answer?.data.content ?? '' }) })] }), _jsx(Editor, { initialData: content, onChange: setContent })] }), _jsxs(ModalFooter, { children: [_jsx(Button, { color: 'danger', variant: 'light', onPress: props.onClose, children: "\uCDE8\uC18C" }), _jsx(Button, { type: 'submit', form: 'authorization', color: 'primary', onPress: () => {
                                    if (answer && answer.data.answer)
                                        patchItemHook.mutate({
                                            id: props.id,
                                            answer: content,
                                        }, {
                                            onSuccess: () => {
                                                queryClient.invalidateQueries(['getInquiriesAnswers', props.id]);
                                                props.onClose();
                                            },
                                        });
                                    else
                                        postItemHook.mutate({
                                            id: props.id,
                                            answer: content,
                                        }, {
                                            onSuccess: () => {
                                                queryClient.invalidateQueries(['getInquiriesAnswers', props.id]);
                                                props.onClose();
                                            },
                                        });
                                }, children: "\uC800\uC7A5" })] })] })) }) }));
}
