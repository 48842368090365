import * as yup from 'yup';
import { SCHEMA_MESSAGE } from '../message';
export const SAT_format = [
    {
        subject: 'ebrw',
        property: [
            {
                content: 'Reading/Writing',
                size: 'long',
                type: 'title',
            },
            {
                content: '점수',
                maxValue: 800,
                size: 'short',
                type: 'input',
                defaultValue: 0,
            },
        ],
    },
    {
        subject: 'math',
        property: [
            {
                content: 'Math',
                size: 'long',
                type: 'title',
            },
            {
                content: '점수',
                maxValue: 800,
                size: 'short',
                type: 'input',
                defaultValue: 0,
            },
        ],
    },
    {
        subject: 'total',
        property: [
            {
                content: 'total',
                alterText: '총점',
                size: 'long',
                type: 'title',
            },
            {
                content: '점수',
                maxValue: 1600,
                size: 'short',
                type: 'input',
                defaultValue: 0,
            },
        ],
    },
];
export const SAT_schema = yup
    .object()
    .shape({
    transcript: yup
        .string()
        .required(SCHEMA_MESSAGE.scoreMessage('SAT 시험', '파일을').customMessage('업로드 해주세요.')),
    examDate: yup
        .string()
        .required(SCHEMA_MESSAGE.scoreMessage('SAT 시험', '시험일자를').requiredMessage())
        .typeError(SCHEMA_MESSAGE.scoreMessage('SAT 시험', '시험일자를').requiredMessage()),
    publishingOrganization: yup
        .string()
        .required(SCHEMA_MESSAGE.scoreMessage('SAT 시험', '발급기관을').requiredMessage()),
    ebrw: yup
        .number()
        .min(0, SCHEMA_MESSAGE.scoreMessage('SAT 시험', 'Reading/Writing 점수는').minMessage(0))
        .max(800, SCHEMA_MESSAGE.scoreMessage('SAT 시험', 'Reading/Writing 점수는').maxMessage(800))
        .required(SCHEMA_MESSAGE.scoreMessage('SAT 시험', 'Reading/Writing 점수를').requiredMessage())
        .typeError(SCHEMA_MESSAGE.scoreMessage('SAT 시험', 'Reading/Writing 점수를').requiredMessage()),
    math: yup
        .number()
        .min(0, SCHEMA_MESSAGE.scoreMessage('SAT 시험', 'math 점수는').minMessage(0))
        .max(800, SCHEMA_MESSAGE.scoreMessage('SAT 시험', 'math 점수는').maxMessage(800))
        .required(SCHEMA_MESSAGE.scoreMessage('SAT 시험', 'math 점수를').requiredMessage())
        .typeError(SCHEMA_MESSAGE.scoreMessage('SAT 시험', 'math 점수를').requiredMessage()),
    total: yup
        .number()
        .min(0, SCHEMA_MESSAGE.scoreMessage('SAT 시험', '총 점수는').minMessage(0))
        .max(1600, SCHEMA_MESSAGE.scoreMessage('SAT 시험', '총 점수는').maxMessage(1600))
        .required(SCHEMA_MESSAGE.scoreMessage('SAT 시험', '총 점수를').requiredMessage())
        .typeError(SCHEMA_MESSAGE.scoreMessage('SAT 시험', '총 점수를').requiredMessage()),
})
    .default(null)
    .nullable();
