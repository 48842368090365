export function Teps(data) {
    return [
        {
            title: '업로드 url',
            type: 'link',
            content: data.transcript,
        },
        {
            title: '응시일자',
            content: data.examDate,
        },
        {
            title: '발행기관',
            content: data.publishingOrganization,
        },
        {
            title: 'grammer',
            content: data.grammer,
        },
        {
            title: 'voca',
            content: data.voca,
        },
        {
            title: 'listening',
            content: data.listening,
        },
        {
            title: 'reading',
            content: data.reading,
        },
    ];
}
