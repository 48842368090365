import axios from '..';
import type {
  DeleteReportCardSubjectsReqType,
  PatchReportCardSubjectsReqType,
  PostReportCardSubjectsReqType,
} from './req';
import type {
  DeleteReportCardSubjectsResType,
  GetReportCardSubjectsResType,
  PatchReportCardSubjectsResType,
  PostReportCardSubjectsResType,
} from './res';

export const getReportCardSubjects = () =>
  axios.get<GetReportCardSubjectsResType>('/report-card-subjects').then((res) => res.data.data);
export const postReportCardSubjects = (body: PostReportCardSubjectsReqType) =>
  axios
    .post<PostReportCardSubjectsResType>('/report-card-subjects', body)
    .then((res) => res.data.data);

export const reportCardSubjects = {
  get: getReportCardSubjects,
  post: postReportCardSubjects,
  patch: ({ reportCardSubjectId, ...body }: PatchReportCardSubjectsReqType) =>
    axios
      .patch<PatchReportCardSubjectsResType>(`/report-card-subjects/${reportCardSubjectId}`, body)
      .then((res) => res.data.data),
  delete: ({ reportCardSubjectId }: DeleteReportCardSubjectsReqType) =>
    axios
      .delete<DeleteReportCardSubjectsResType>(`/report-card-subjects/${reportCardSubjectId}`)
      .then((res) => res.data.data),
};
