import * as yup from 'yup';
import { SCHEMA_MESSAGE } from '../message';
export const IBSubject = [
    'Environmental Systems and Societies',
    'Chinese A Literature',
    'Chinese A Language and Literature',
    'English A Literature',
    'English A Language and Literature',
    'French A Literature',
    'French A Language and Literature',
    'German A Literature',
    'German A Language and Literature',
    'Italian A Literature',
    'Italian A Language and Literature',
    'Japanese A Literature',
    'Japanese A Language and Literature',
    'Korean A Literature',
    'Korean A Language and Literature',
    'Spanish A Literature',
    'Spanish A Language and Literature',
    'Chinese B',
    'English AB SL',
    'English B',
    'French AB SL',
    'French B',
    'German AB SL',
    'German B',
    'Italian AB SL',
    'Italian B',
    'Japanese AB SL',
    'Japanese B ',
    'Korean B',
    'Spanish AB SL',
    'Spanish B',
    'Art History SL',
    'Business Management ',
    'Economics',
    'Geography',
    'Global Politics',
    'History',
    'Philosophy',
    'Psychology',
    'Biology',
    'Chemistry',
    'Computer Science',
    'Physics ',
    'Math AA',
    'Math AI',
    'Dance ',
    'Film',
    'Music',
    'Theatre',
    'Visual Arts',
    'TOK/EE',
];
export const IB_schema = yup
    .object()
    .shape({
    transcript: yup
        .string()
        .required(SCHEMA_MESSAGE.scoreMessage('IB 시험', '파일을').customMessage('업로드 해주세요.')),
    examDate: yup
        .string()
        .required(SCHEMA_MESSAGE.scoreMessage('IB 시험', '시험일자를').requiredMessage())
        .typeError(SCHEMA_MESSAGE.scoreMessage('IB 시험', '시험일자를').requiredMessage()),
    publishingOrganization: yup
        .string()
        .required(SCHEMA_MESSAGE.scoreMessage('IB 시험', '발급기관을').requiredMessage()),
    subjectList: yup
        .array()
        .min(1, SCHEMA_MESSAGE.scoreMessage('IB 시험', '과목을').customMessage('최소 하나 이상 선택해주세요.'))
        .max(7, SCHEMA_MESSAGE.scoreMessage('IB 시험', '과목은').customMessage('최대 6개까지 선택 가능합니다.'))
        .of(yup.object().shape({
        name: yup
            .mixed()
            .oneOf(IBSubject, '')
            .required(SCHEMA_MESSAGE.scoreMessage('IB 시험', '과목의 이름을').requiredMessage('선택')),
        type: yup.mixed().when('name', ([name], schema) => {
            if (name === 'TOK/EE') {
                return schema.nullable();
            }
            return schema
                .oneOf(['HL', 'SL'], '')
                .required(SCHEMA_MESSAGE.scoreMessage('IB 시험', '등급을').requiredMessage('선택'));
        }),
        score: yup
            .number()
            .when('name', ([name], schema) => {
            if (name === 'TOK/EE') {
                return schema
                    .min(0, SCHEMA_MESSAGE.scoreMessage('IB 시험', '점수는').minMessage(0))
                    .max(3, SCHEMA_MESSAGE.scoreMessage('IB 시험', '점수는').maxMessage(3))
                    .required(SCHEMA_MESSAGE.scoreMessage('IB 시험', '점수를').requiredMessage())
                    .typeError(SCHEMA_MESSAGE.scoreMessage('IB 시험', '점수를').requiredMessage());
            }
            return schema;
        })
            .min(0, SCHEMA_MESSAGE.scoreMessage('IB 시험', '점수는').minMessage(0))
            .max(7, SCHEMA_MESSAGE.scoreMessage('IB 시험', '점수는').maxMessage(7))
            .required(SCHEMA_MESSAGE.scoreMessage('IB 시험', '점수를').requiredMessage())
            .typeError(SCHEMA_MESSAGE.scoreMessage('IB 시험', '점수를').requiredMessage()),
    })),
})
    .default(null)
    .nullable();
