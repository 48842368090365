import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AddUserPaymentModal } from '@component/database/userPayment/AddUserPaymentModal';
import { UpdateUserPaymentModal } from '@component/database/userPayment/UpdateUserPaymentModal';
import { VerticalDotsIcon } from '@component/icon/VerticalDotsIcon';
import { useSearchAndPagination } from '@hook/useSearchAndPagination';
import { useUserPayments } from '@hook/useUserPayment';
import { Button, Card, CardBody, CardFooter, CardHeader, Divider, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Input, Pagination, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, useDisclosure, } from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { paymentsHistories } from '@simppl/repository/paymentHistories';
import { Search } from 'lucide-react';
import { useState } from 'react';
export default function UserPaymentPage() {
    const { data } = useUserPayments();
    const { filterValue, filteredItems, items, page, setPage, onSearchChange, onClear } = useSearchAndPagination(data, (userPayment, value) => userPayment.userName.includes(value) || userPayment.userEmail.includes(value));
    const [modalState, setModalState] = useState(null);
    const { isOpen, onOpenChange, onOpen, onClose } = useDisclosure();
    const { isOpen: isAddUserPaymentModalOpen, onOpenChange: onAddUserPaymentModalOpenChange, onOpen: onAddUserPaymentModalOpen, onClose: onAddUserPaymentModalClose, } = useDisclosure();
    const deleteUserPaymentsHook = useMutation(paymentsHistories.delete);
    const queryClient = useQueryClient();
    return (_jsx("div", { className: 'flex min-h-[80%] flex-col', children: _jsxs(Card, { className: 'grow', children: [isAddUserPaymentModalOpen && (_jsx(AddUserPaymentModal, { isOpen: isAddUserPaymentModalOpen, onOpenChange: onAddUserPaymentModalOpenChange, onClose: onAddUserPaymentModalClose })), isOpen && modalState !== null && modalState.mode === 'edit' && (_jsx(UpdateUserPaymentModal, { isOpen: isOpen, onOpenChange: onOpenChange, paymentHistoryId: modalState.paymentHistoryId, onClose: () => {
                        onClose();
                        setModalState(null);
                    } })), _jsx(CardHeader, { children: _jsx("h1", { className: 'text-2xl', children: "\uC720\uC800\uC0C1\uD488 \uAD00\uB9AC" }) }), _jsx(Divider, {}), _jsx(CardBody, { className: 'flex flex-col gap-4', children: _jsxs(Table, { layout: 'auto', topContent: _jsx("div", { className: 'flex flex-col gap-4', children: _jsxs("div", { className: 'flex w-full flex-row justify-between', children: [_jsx("div", { className: 'flex max-w-sm flex-1 flex-row items-center', children: _jsx(Input, { labelPlacement: 'outside', startContent: _jsx(Search, {}), value: filterValue, onClear: () => onClear(), onValueChange: onSearchChange }) }), _jsx(Button, { color: 'primary', onPress: () => {
                                            onAddUserPaymentModalOpen();
                                        }, children: "\uC720\uC800\uC0C1\uD488 \uCD94\uAC00" })] }) }), topContentPlacement: 'outside', children: [_jsxs(TableHeader, { children: [_jsx(TableColumn, { children: "\uC720\uC800 \uC774\uBA54\uC77C" }, 'userName'), _jsx(TableColumn, { children: "\uC720\uC800 \uC774\uB984" }, 'userName'), _jsx(TableColumn, { children: "\uC0C1\uD488\uBA85" }, 'itemName'), _jsx(TableColumn, { children: "\uC0C1\uD488 \uAC00\uACA9" }, 'itemPrice'), _jsx(TableColumn, { children: "\uC9C0\uBD88 \uBC29\uBC95" }, 'price'), _jsx(TableColumn, { children: "\uAD6C\uB9E4 \uD69F\uC218" }, 'count'), _jsx(TableColumn, { children: "\uC0C1\uD0DC" }, 'status'), _jsx(TableColumn, { children: "\uBE44\uACE0" }, 'setting')] }), _jsx(TableBody, { items: items, children: (item) => (_jsxs(TableRow, { className: 'relative cursor-pointer', children: [_jsx(TableCell, { children: item.userEmail }), _jsx(TableCell, { children: item.userName }), _jsx(TableCell, { children: item.itemName }), _jsx(TableCell, { children: item.itemPrice }), _jsx(TableCell, { children: item.payMethod }), _jsx(TableCell, { children: item.count }), _jsx(TableCell, { children: item.status }), _jsx(TableCell, { children: _jsxs(Dropdown, { children: [_jsx(DropdownTrigger, { children: _jsx(Button, { isIconOnly: true, size: 'sm', variant: 'light', children: _jsx(VerticalDotsIcon, { className: 'text-default-300' }) }) }), _jsxs(DropdownMenu, { children: [_jsx(DropdownItem, { onPress: () => {
                                                                    setModalState({
                                                                        mode: 'edit',
                                                                        paymentHistoryId: item.paymentHistoryId,
                                                                    });
                                                                    onOpen();
                                                                }, children: "\uC218\uC815" }), _jsx(DropdownItem, { className: 'text-red-500', onPress: () => {
                                                                    deleteUserPaymentsHook.mutate({ paymentHistoryId: item.paymentHistoryId }, {
                                                                        onSuccess: () => {
                                                                            queryClient.invalidateQueries(['userPayments']);
                                                                        },
                                                                    });
                                                                }, children: "\uC0AD\uC81C" })] })] }) })] }, item.itemId)) })] }) }), _jsx(CardFooter, { children: _jsx("div", { className: 'flex w-full flex-col items-center', children: _jsx(Pagination, { isCompact: true, showControls: true, showShadow: true, page: page, onChange: setPage, total: Math.max(Math.ceil(filteredItems.length / 10), 1) }) }) })] }) }));
}
