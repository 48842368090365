/* eslint-disable no-nested-ternary */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-globals */
import useSuspenseQuery from '@simppl/react-query/useSuspenseQuery';
import { getHighschools } from '@simppl/repository/highschools';
export const useHighschools = (body) => {
    const { data, refetch } = useSuspenseQuery({
        queryKey: [`highschools${body.countryId}`],
        queryFn: () => body.hasOwnProperty('countryId')
            ? body.countryId
                ? getHighschools(body)
                : []
            : getHighschools(body),
    });
    return {
        highSchools: data ?? [],
        highschoolNames: data?.map((highSchool) => highSchool.name) ?? [],
        highschoolNameIdMap: data?.reduce((acc, cur) => ({ ...acc, [cur.name]: cur.id }), {}),
        refetch,
    };
};
