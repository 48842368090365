import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Combobox } from '@headlessui/react';
import c from '@simppl/util/c';
import { useFormContext } from 'react-hook-form';
export function ComboBox({ name, content, category, placeholder = '', onBlur, onChange, disabled, }) {
    const methods = useFormContext();
    const filteredCategory = category.filter((option) => option?.toLowerCase().includes(methods.watch(name)?.toLowerCase()));
    return (_jsx("div", { className: c('relative flex h-[40px] w-[252px] items-center justify-between rounded-[4px] border-[1px] border-blue-600'), children: _jsxs(Combobox, { as: 'div', value: content, onChange: (value) => {
                methods.setValue(name, value);
                onChange?.(value);
            }, children: [_jsx(Combobox.Input, { className: 'h-full w-full text-ellipsis rounded-[4px] pl-[16px] text-blue-600 outline-none', placeholder: placeholder, autoComplete: 'off', onChange: (event) => methods.setValue(name, event.target.value), onBlur: (event) => {
                        if (onBlur) {
                            onBlur();
                        }
                        return event;
                    } }), _jsx(Combobox.Options, { className: 'absolute left-0 top-[40px] z-[999] flex max-h-[300px] w-full cursor-pointer flex-col gap-[8px] overflow-auto rounded-[4px] border-[0.5px] border-[#D6E0FD] bg-[#FFFFFF]', children: filteredCategory.map((option) => (_jsx(Combobox.Option, { className: 'font-Pretendard-Regular text-Base-Black p-[8px] hover:bg-[#D6E0FD]', value: option, onClick: () => {
                            methods.setValue(name, option);
                        }, children: option }, option))) })] }) }));
}
