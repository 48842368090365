import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useUniversitiesMajors } from '@hook/useUniversitiesMajors';
import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, } from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { universitiesMajors } from '@simppl/repository/admins';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
export function UniversityMajorModal(props) {
    const { universityId } = useParams();
    if (!universityId)
        return null;
    const [input, setInput] = useState('');
    const university = props.mode === 'edit'
        ? useUniversitiesMajors({
            universityId,
        }).data?.find((university) => university.universityMajorId === props.universityMajorId)
        : null;
    const queryClient = useQueryClient();
    const postUniversitiesHook = useMutation(universitiesMajors.post);
    const patchUniversitiesHook = useMutation(universitiesMajors.patch);
    useEffect(() => {
        if (props.mode === 'edit' && university) {
            setInput(university.name);
        }
    }, [props, university]);
    return (_jsx(Modal, { isOpen: props.isOpen, onOpenChange: props.onOpenChange, children: _jsx(ModalContent, { children: () => (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { className: 'flex flex-col gap-1', children: props.mode === 'add' ? '학과 추가' : '학과 수정' }), _jsx(ModalBody, { children: _jsx(Input, { value: input, onChange: (e) => setInput(e.target.value), label: '\uD559\uACFC \uC774\uB984', labelPlacement: 'outside' }) }), _jsxs(ModalFooter, { children: [_jsx(Button, { color: 'danger', variant: 'light', onPress: props.onClose, children: "\uCDE8\uC18C" }), _jsx(Button, { type: 'submit', form: 'authorization', color: 'primary', onPress: () => {
                                    if (props.mode === 'add')
                                        postUniversitiesHook.mutate({
                                            universityId,
                                            name: input,
                                        }, {
                                            onSuccess: () => {
                                                queryClient.invalidateQueries(['unversitiesMajors']);
                                                props.onClose();
                                            },
                                        });
                                    else if (props.mode === 'edit' && university?.universityMajorId)
                                        patchUniversitiesHook.mutate({
                                            universityId,
                                            universityMajorId: university.universityMajorId,
                                            name: input,
                                        }, {
                                            onSuccess: () => {
                                                queryClient.invalidateQueries(['unversitiesMajors']);
                                                props.onClose();
                                            },
                                        });
                                }, children: "\uC800\uC7A5" })] })] })) }) }));
}
