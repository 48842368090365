import { jsx as _jsx } from "react/jsx-runtime";
import SwitchCase from '@simppl/component/SwitchCase';
import { Ap } from './AP';
import { Alevel } from './Alevel';
import { Etc } from './Etc';
import { Ib } from './Ib';
export function HighSchool({ testName, data }) {
    return (_jsx(SwitchCase, { value: testName, caseBy: {
            'A-LEVEL': _jsx(Alevel, { ...data }),
            AP: _jsx(Ap, { ...data }),
            IB: _jsx(Ib, { ...data }),
            ETC: _jsx(Etc, { ...data }),
        } }));
}
