import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
import { Input } from '@component/score/common/form';
import { Minus } from '@component/score/common/icon/Minus';
import { Plus } from '@component/score/common/icon/Plus';
import { useReportCardSubjectsIds } from '@hook/useReportCardSubjectsIds';
import { ErrorMessage } from '@hookform/error-message';
import useMutation from '@simppl/react-query/useMutation';
import { deleteTestById, postReportCardSubjects, reportCardDetailAdmin, } from '@simppl/repository/test';
import { useEffect } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
export function KOREAForm({ content }) {
    const methods = useFormContext();
    const { fields, append, remove } = useFieldArray({
        control: methods.control,
        name: `${content}.reportCardDetailList`,
    });
    return (_jsxs(_Fragment, { children: [_jsx(ErrorMessage, { errors: methods.formState.errors, name: `${content}.reportCardDetailList`, render: ({ message }) => _jsx("p", { className: 'text-[12px] text-red-600', children: message }) }), _jsx("div", { className: 'flex w-full flex-col gap-2', children: fields.map(({ id }, index) => (_jsx(Form, { content: content, index: index, remove: remove }, id))) }), _jsx("div", { className: 'pl-[148px]', children: _jsxs("button", { type: 'button', className: 'flex w-[513px] items-center justify-center gap-[8px] rounded-md border-[1px] border-blue-500 bg-blue-500 px-2 py-1 text-[white]', onClick: () => append({
                        reportCardSubjectId: null,
                        totalScore: null,
                        etcScore: null,
                    }), children: [_jsx(Plus, { color: 'white' }), "\uACFC\uBAA9\uCD94\uAC00 (\uC120\uD0DD)"] }) })] }));
}
function Form({ content, index, remove }) {
    const methods = useFormContext();
    const subjectIds = useReportCardSubjectsIds();
    const postReportCardSubjectsHook = useMutation((name) => postReportCardSubjects(name));
    const deleteUserReportCardDetailHook = useMutation(reportCardDetailAdmin.delete);
    useEffect(() => {
        const reportCardSubjectId = methods.watch(`${content}.reportCardDetailList.${index}.reportCardSubjectId`) ?? null;
        const name = methods.watch(`${content}.reportCardDetailList.${index}.name`) ?? null;
        if (reportCardSubjectId === null && name !== null) {
            methods.setValue(`${content}.reportCardDetailList.${index}.reportCardSubjectId`, subjectIds[name] ?? '');
        }
        if (reportCardSubjectId !== null && name === null) {
            methods.setValue(`${content}.reportCardDetailList.${index}.name`, subjectIds[reportCardSubjectId]);
        }
    }, []);
    return (_jsx("div", { className: 'mr-[82px] flex justify-end gap-[24px]', children: _jsx(Controller, { name: `${content}.reportCardDetailList.${index}`, control: methods.control, render: ({ field }) => (_jsxs(_Fragment, { children: [_jsx("div", { className: 'flex justify-end gap-[24px]', children: _jsxs(_Fragment, { children: [_jsx(Input, { type: 'input', ...field, content: 'name', alterText: '\uACFC\uBAA9\uBA85', inputType: 'stringInput', size: 155, name: `${field.name}.name`, onBlur: () => {
                                        setTimeout(() => {
                                            const reportCardSubjectId = methods.watch(`${field.name}.reportCardSubjectId`);
                                            if (reportCardSubjectId) {
                                                deleteTestById('report-cards', reportCardSubjectId);
                                            }
                                            else {
                                                const name = methods.watch(`${field.name}.name`);
                                                postReportCardSubjectsHook.mutate(name, {
                                                    onSuccess: ({ reportCardSubjectId }) => {
                                                        methods.setValue(`${field.name}.reportCardSubjectId`, reportCardSubjectId);
                                                    },
                                                });
                                            }
                                        }, 100);
                                    } }), _jsx(Input, { type: 'input', ...field, content: 'etcScore', alterText: '\uC810\uC218', size: 155, defaultValue: '0', name: `${field.name}.etcScore`, inputType: 'stringInput', onBlur: () => {
                                        methods.setValue(`${field.name}.etcScore`, methods.watch(`${field.name}.etcScore`).toString(10));
                                    } }), _jsx(Input, { type: 'input', ...field, content: 'totalScore', alterText: '\uB9CC\uC810', size: 155, defaultValue: '0', name: `${field.name}.totalScore`, inputType: 'stringInput', onBlur: () => {
                                        methods.setValue(`${field.name}.totalScore`, methods.watch(`${field.name}.totalScore`).toString(10));
                                    } })] }) }), _jsx("button", { className: ' flex   h-[40px]   min-w-[40px] items-center justify-center whitespace-nowrap rounded-[4px] border-[1px] border-blue-700 text-blue-700', type: 'button', onClick: () => {
                            const reportCardDetailId = methods.watch(`${content}.reportCardDetailList.${index}.reportCardDetailId`);
                            if (reportCardDetailId)
                                deleteUserReportCardDetailHook.mutate({ reportCardDetailId }, {
                                    onSuccess: () => remove(index),
                                });
                            else {
                                remove(index);
                            }
                        }, children: _jsx(Minus, {}) })] })) }) }));
}
