import c from '@simppl/util/c';

const GovermentIcon = ({
  className = '',
  isError = false,
}: {
  className?: string;
  isError?: boolean;
}) => {
  return (
    <svg
      width='17'
      height='16'
      viewBox='0 0 17 16'
      className={c(`${isError ? 'fill-Others-Red-2' : 'fill-Blue-1'}`, className)}
    >
      <path
        d='M2.5 13.6V6.4H4.9V13.6H2.5ZM7.3 13.6V6.4H9.7V13.6H7.3ZM1 16C0.723858 16 0.5 15.7761 0.5 15.5V14.9C0.5 14.6239 0.723858 14.4 1 14.4H16C16.2761 14.4 16.5 14.6239 16.5 14.9V15.5C16.5 15.7761 16.2761 16 16 16H1ZM12.1 13.6V6.4H14.5V13.6H12.1ZM1 5.6C0.723858 5.6 0.5 5.37614 0.5 5.1V4L8.5 0L16.5 4V5.1C16.5 5.37614 16.2761 5.6 16 5.6H1ZM4.06 4H12.94L8.5 1.8L4.06 4Z'
        // fill='#1F64DC'
      />
    </svg>
  );
};

export default GovermentIcon;
