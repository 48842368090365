import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Text from '@simppl/component/Text';
import { useFormContext } from 'react-hook-form';
export function TepsView({ content }) {
    const methods = useFormContext();
    const data = methods.watch(`${content}`);
    return (_jsx("div", { className: 'flex flex-col justify-between', children: Object.entries({
            listening: data.listening,
            reading: data.reading,
            Vocabulary: data.voca,
            Grammar: data.grammer,
        }).map(([key, value]) => (_jsxs("div", { className: 'flex justify-between', children: [_jsx("div", { className: 'w-[240px] px-[16px] py-[8px]', children: _jsx(Text, { color: 'text-blue-700', weight: 'Medium', as: 'Label', size: 1, children: key }) }), _jsxs("div", { className: 'flex w-[112px] justify-between px-[16px] py-[8px]', children: [_jsx(Text, { color: 'text-blue-500', weight: 'Regular', as: 'Label', size: 1, children: "\uC810\uC218" }), _jsx(Text, { color: 'text-Blue-5', weight: 'Regular', as: 'Label', size: 1, children: value })] })] }, key))) }));
}
