import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/no-children-prop */
import { VerticalDotsIcon } from '@component/icon/VerticalDotsIcon';
import { useCommunityCategories } from '@hook/useCommunityCategories';
import { usePostsByBoards } from '@hook/usePostsByBoardIds';
import { useSearchAndPagination } from '@hook/useSearchAndPagination';
import boardApi from '@simppl/repository/board';
import { Button, Card, CardBody, CardFooter, CardHeader, Divider, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Input, Pagination, Select, SelectItem, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, useDisclosure, } from '@nextui-org/react';
import { Search } from 'lucide-react';
import { useState } from 'react';
import { getTime } from '@simppl/util/date';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { PostModal } from '@component/community/post/PostModal';
import ShadowDOM from '@component/ShadowDOM';
function CategorySelectBox({ categories, setCategoryId, selectedCategories, }) {
    return (_jsxs(_Fragment, { children: [_jsx(Select, { isRequired: true, showScrollIndicators: false, selectionMode: 'multiple', className: 'max-w-xs overflow-hidden text-ellipsis', selectedKeys: selectedCategories, onSelectionChange: setCategoryId, children: categories.map((category) => (_jsx(SelectItem, { className: 'capitalize', children: category.title }, category.id))) }), _jsx(Button, { onPress: () => setCategoryId(new Set(categories.map((category) => category.id))), children: "\uC804\uCCB4 \uC120\uD0DD" }), _jsx(Button, { onPress: () => setCategoryId(new Set()), children: "\uC804\uCCB4 \uC120\uD0DD \uCDE8\uC18C" })] }));
}
const columns = [
    { key: 'category', label: '카테고리' },
    { key: 'title', label: '주제' },
    { key: 'createdAt', label: '생성일' },
    { key: 'viewCount', label: '조회수' },
    { key: 'commentCount', label: '댓글수' },
    { key: 'likeCount', label: '좋아요수' },
    { key: 'isAnonymous', label: '작성자' },
    { key: 'settings', label: '액션' },
];
export default function PostPage() {
    const [selectedBoardContent, setSelectedBoardContent] = useState(null);
    const { categories } = useCommunityCategories();
    const [modalState, setModalState] = useState(null);
    const { isOpen, onOpenChange, onOpen, onClose } = useDisclosure();
    const flattenedCategories = categories.reduce((acc, category) => {
        acc.push(category);
        category.children.forEach((childCategory) => {
            acc.push(childCategory);
        });
        return acc;
    }, []);
    const [selectedCategories, setSelectedCategories] = useState(new Set(flattenedCategories.map((category) => category.id)));
    const boards = usePostsByBoards(Array.from(selectedCategories));
    const flattenedBoards = boards.map((board) => board.posts).flat().sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });
    const { filterValue, filteredItems, items, page, setPage, onSearchChange, onClear } = useSearchAndPagination(flattenedBoards, (category, searchValue) => category.title.includes(searchValue));
    const queryClient = useQueryClient();
    const deletePostHook = useMutation(boardApi.boardId.posts.postId.delete);
    return (_jsx("div", { className: 'flex min-h-[80%] flex-col', children: _jsxs(Card, { className: 'grow', children: [isOpen && modalState !== null && (_jsx(PostModal, { isOpen: isOpen, onOpenChange: onOpenChange, ...modalState, onClose: () => {
                        onClose();
                        setModalState(null);
                    } })), _jsx(CardHeader, { children: _jsx("h1", { className: 'text-2xl', children: "\uAC8C\uC2DC\uAE00 \uAD00\uB9AC" }) }), _jsx(Divider, {}), _jsxs(CardBody, { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'flex flex-col gap-4', children: _jsxs("div", { className: 'flex w-full flex-row justify-between', children: [_jsxs("div", { className: 'flex max-w-sm flex-1 flex-row items-center', children: [_jsx(Input, { labelPlacement: 'outside', startContent: _jsx(Search, {}), value: filterValue, onClear: () => onClear(), onValueChange: onSearchChange }), _jsx("div", { className: 'flex gap-2', children: _jsx(CategorySelectBox, { categories: flattenedCategories, selectedCategories: selectedCategories, setCategoryId: setSelectedCategories }) })] }), _jsx(Button, { color: 'primary', onPress: () => {
                                            setModalState({ mode: 'add' });
                                            onOpen();
                                        }, children: "\uAC8C\uC2DC\uAE00 \uCD94\uAC00" })] }) }), _jsx("div", { className: 'flex gap-4', children: _jsxs(Table, { layout: 'auto', children: [_jsx(TableHeader, { columns: columns, children: (column) => _jsx(TableColumn, { children: column.label }, column.key) }), _jsxs(TableBody, { children: [...items.map((post) => {
                                                const elements = [_jsxs(TableRow, { onClick: () => setSelectedBoardContent((prev) => prev === post.id ? null : post.id), className: 'cursor-pointer hover:opacity-30', children: [_jsx(TableCell, { children: post.category.title }), _jsx(TableCell, { children: post.title }), _jsx(TableCell, { children: getTime({ createdAt: post.createdAt }).fullTime }), _jsx(TableCell, { children: post.viewCount }), _jsx(TableCell, { children: post.commentCount }), _jsx(TableCell, { children: post.likeCount }), _jsx(TableCell, { children: post.isAnonymous ? '익명' : post.user.nickname }), _jsx(TableCell, { children: _jsxs(Dropdown, { children: [_jsx(DropdownTrigger, { children: _jsx(Button, { isIconOnly: true, size: 'sm', variant: 'light', children: _jsx(VerticalDotsIcon, { className: 'text-default-300' }) }) }), _jsxs(DropdownMenu, { children: [_jsx(DropdownItem, { onPress: () => {
                                                                                        setModalState({
                                                                                            mode: 'edit',
                                                                                            boardId: post.id.toString(),
                                                                                            categoryId: post.category.id,
                                                                                        });
                                                                                        onOpen();
                                                                                    }, children: "\uC218\uC815" }), _jsx(DropdownItem, { className: 'text-red-500', onPress: () => {
                                                                                        // eslint-disable-next-line no-restricted-globals
                                                                                        if (!confirm('정말 삭제하시겠습니까?'))
                                                                                            return;
                                                                                        deletePostHook.mutate({
                                                                                            postId: post.id.toString()
                                                                                        }, {
                                                                                            onSuccess: () => queryClient.invalidateQueries(['posts']),
                                                                                        });
                                                                                    }, children: "\uC0AD\uC81C" })] })] }) })] }, post.id)];
                                                if (selectedBoardContent === post.id) {
                                                    elements.push(_jsxs(TableRow, { children: [_jsx(TableCell, { colSpan: 8, children: _jsx(ShadowDOM, { className: 'w-full p-4', html: post.content, css: `
                        img {
                          max-width: 100%;
                        }
                      ` }) }), _jsx(TableCell, { className: 'hidden', children: undefined }), _jsx(TableCell, { className: 'hidden', children: undefined }), _jsx(TableCell, { className: 'hidden', children: undefined }), _jsx(TableCell, { className: 'hidden', children: undefined }), _jsx(TableCell, { className: 'hidden', children: undefined }), _jsx(TableCell, { className: 'hidden', children: undefined }), _jsx(TableCell, { className: 'hidden', children: undefined })] }, `${post.id}content`));
                                                }
                                                return elements;
                                            }).flat()] })] }) })] }), _jsx(CardFooter, { children: _jsx("div", { className: 'flex w-full flex-col items-center', children: _jsx(Pagination, { isCompact: true, showControls: true, showShadow: true, page: page, onChange: setPage, total: Math.max(Math.ceil(filteredItems.length / 10), 1) }) }) })] }) }));
}
