import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/destructuring-assignment */
import { useCategories } from '@hook/useCategories';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, } from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { category } from '@simppl/repository/categories';
import { ChevronDownIcon } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
const categoryMap = {
    NOTICE: '공지사항',
    FAQ: '자주 묻는 질문',
    INQUIRY: '1:1문의',
};
const categoryIdMap = {
    NOTICE: 1,
    FAQ: 2,
    INQUIRY: 3,
};
export function CategoryModal(props) {
    const [selectedKeys, setSelectedKeys] = useState(new Set(['NOTICE']));
    const selectedValue = useMemo(() => Array.from(selectedKeys).join(', ').replace(/_/g, ' '), [selectedKeys]);
    const item = props.mode === 'edit'
        ? useCategories({
            type: props.type,
        }).categories.children.find((category) => category.id.toString() === props.id)
        : null;
    const queryClient = useQueryClient();
    const postItemHook = useMutation(category.post);
    const patchItemHook = useMutation(category.patch);
    const methods = useForm({
        mode: 'all',
        reValidateMode: 'onSubmit',
    });
    const { control, handleSubmit } = methods;
    const [title] = ['title'].map((t) => methods.watch(t));
    useEffect(() => {
        if (props.mode === 'edit' && item) {
            methods.setValue('title', item.title);
            setSelectedKeys(new Set([props.type]));
        }
    }, [props, item]);
    return (_jsx(Modal, { isOpen: props.isOpen, onOpenChange: props.onOpenChange, children: _jsx(ModalContent, { children: () => (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { className: 'flex flex-col gap-1', children: props.mode === 'add' ? '공지사항 추가' : '공지사항 수정' }), _jsxs(ModalBody, { children: [_jsx(Controller, { name: 'title', control: control, defaultValue: '', render: ({ field }) => (_jsx(Input, { labelPlacement: 'outside', placeholder: '\uC81C\uBAA9', ...field })) }), _jsxs(Dropdown, { children: [_jsx(DropdownTrigger, { className: 'hidden sm:flex', children: _jsx(Button, { endContent: _jsx(ChevronDownIcon, { className: 'text-small' }), children: categoryMap[selectedValue] }) }), _jsx(DropdownMenu, { disallowEmptySelection: true, "aria-label": 'Table Columns', closeOnSelect: false, selectionMode: 'single', selectedKeys: selectedKeys, onSelectionChange: setSelectedKeys, children: ['NOTICE', 'FAQ', 'INQUIRY'].map((categoryItem) => (_jsx(DropdownItem, { className: 'capitalize', children: categoryMap[categoryItem] }, categoryItem))) })] })] }), _jsxs(ModalFooter, { children: [_jsx(Button, { color: 'danger', variant: 'light', onPress: props.onClose, children: "\uCDE8\uC18C" }), _jsx(Button, { type: 'submit', form: 'authorization', color: 'primary', onPress: () => {
                                    if (props.mode === 'add')
                                        postItemHook.mutate({
                                            title,
                                            parentCategoryId: categoryIdMap[Array.from(selectedKeys)[0]].toString(),
                                        }, {
                                            onSuccess: () => {
                                                queryClient.invalidateQueries(['getCategories']);
                                                props.onClose();
                                            },
                                        });
                                    else if (props.mode === 'edit' && item?.id)
                                        patchItemHook.mutate({
                                            id: item.id.toString(),
                                            title,
                                            parentCategoryId: categoryIdMap[Array.from(selectedKeys)[0]].toString(),
                                        }, {
                                            onSuccess: () => {
                                                queryClient.invalidateQueries(['getCategories']);
                                                props.onClose();
                                            },
                                        });
                                }, children: "\uC800\uC7A5" })] })] })) }) }));
}
