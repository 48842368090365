export function Act(data) {
    return [
        {
            title: '업로드 url',
            type: 'link',
            content: data.transcript,
        },
        {
            title: '응시일자',
            content: data.examDate,
        },
        {
            title: '발행기관',
            content: data.publishingOrganization,
        },
        {
            title: 'reading',
            content: data.reading,
        },
        {
            title: 'scienceReading',
            content: data.scienceReading,
        },
        {
            title: 'math',
            content: data.math,
        },
        {
            title: 'reading',
            content: data.reading,
        },
        {
            title: '총점',
            content: data.totalScore,
        },
    ];
}
