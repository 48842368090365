import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Select } from '@component/score/common/form';
import { Minus } from '@component/score/common/icon/Minus';
import { ErrorMessage } from '@hookform/error-message';
import { activityAwardTestMap } from '@schema/activityAward';
import Text from '@simppl/component/Text';
import getImgUrl from '@simppl/util/getImgUrl';
import { useFormContext } from 'react-hook-form';
import { UploaderInput } from '../../common/uploader/UploaderInput';
const activityAwardTest = {
    ACTIVITY: '활동',
    AWARD: '수상',
};
export function ActivityAwardInputForm({ name, removeSemester }) {
    const methods = useFormContext();
    return (_jsxs("div", { className: 'flex w-[890px] flex-col items-center justify-center gap-2  px-[30px] py-[48px]', children: [_jsxs("div", { className: 'flex w-full items-center gap-[24px]', children: [_jsx(Select, { className: 'w-[124px]', placeholder: '\uC218\uC0C1/\uD65C\uB3D9', defaultValue: 'ACTIVITY', content: methods.watch(`${name}.subject`), type: 'select', category: activityAwardTest, name: `${name}.subject`, onChange: () => {
                            methods.resetField(`${name}.division`);
                            methods.resetField(`${name}.type`);
                        } }), _jsx(Select, { className: 'w-[155px]', placeholder: '\uAD50\uB0B4/\uAD50\uC678', content: methods.watch(`${name}.division`), type: 'select', category: activityAwardTestMap[methods.watch(`${name}.subject`)]
                            ?.defaultFormat?.division ?? {}, name: `${name}.division` }), _jsx(Select, { className: 'w-[155px]', placeholder: '\uC131\uACA9', content: methods.watch(`${name}.type`), type: 'select', category: activityAwardTestMap[methods.watch(`${name}.subject`)]
                            ?.defaultFormat?.type ?? {}, name: `${name}.type` }), _jsx(UploaderInput, { name: name, testName: methods.watch(`${name}.subject`), testType: 'activities-awards', alterText: '\uC99D\uBE59\uC790\uB8CC.pdf' }), _jsx(DeleteExamButton, { onClick: removeSemester })] }), _jsxs("div", { className: 'flex w-full flex-col items-start justify-center gap-[24px] pl-[146px]', children: [_jsxs("div", { className: 'flex items-center gap-[10px] w-[515px]', children: [methods.watch(`${name}.subject`) === 'ACTIVITY' ? (_jsx(ActivityDateRange, { name: name })) : (_jsx(AwardDateInput, { name: `${name}.awardDate` })), _jsx(PublishingOrganizationInput, { name: `${name}.publishingOrganization` })] }), _jsx(TextArea, { name: `${name}.content` })] })] }));
}
function PublishingOrganizationInput({ name }) {
    const methods = useFormContext();
    return (_jsxs("div", { className: 'relative h-[40px] w-[254px] rounded-[4px]', children: [_jsx("input", { placeholder: '\uBC1C\uAE09\uAE30\uAD00', className: 'h-[40px] w-full rounded-[4px] border-[1px] border-blue-600 p-1 placeholder:text-blue-600', ...methods.register(name) }), _jsx("img", { className: 'absolute right-[8px] top-[50%] translate-y-[-50%]', src: getImgUrl('/asset/Button/PublishingOrganization.svg'), alt: '\uBC1C\uAE09\uAE30\uAD00' }), _jsx(ErrorMessage, { errors: methods.formState.errors, name: name, render: ({ message }) => (_jsx("p", { className: 'absolute bottom-[-20px] text-[12px] text-red-600', children: message })) })] }));
}
function TextArea({ name }) {
    const methods = useFormContext();
    return (_jsxs("div", { className: 'relative h-[149px] w-[515px]', children: [_jsx("textarea", { className: 'font-Pretendard-Regular h-[149px] w-[515px] rounded-[4px] border-[0.5px] border-blue-600 p-[16px_24px_32px_16px]', maxLength: 300, ...methods.register(name) }), _jsxs("div", { className: 'absolute bottom-[8px] right-[8px] flex items-center', children: [_jsx(Text, { color: 'text-blue-500', weight: 'Regular', as: 'Label', size: 2, children: methods.watch(name)?.length ?? 0 }), _jsx(Text, { color: 'text-gray-400', className: 'whitespace-pre', weight: 'Regular', as: 'Label', size: 2, children: ` / 300` })] }), _jsx(ErrorMessage, { errors: methods.formState.errors, name: name, render: ({ message }) => (_jsx("p", { className: 'absolute bottom-[-20px] text-[12px] text-red-600', children: message })) })] }));
}
function AwardDateInput({ name }) {
    const methods = useFormContext();
    return (_jsxs("div", { className: 'relative', children: [_jsx("input", { type: 'date', placeholder: '\uC218\uC0C1\uC77C', className: 'h-[40px] w-[252px] rounded-[4px] border-[1px] border-blue-600 p-1', ...methods.register(name) }), _jsx(ErrorMessage, { errors: methods.formState.errors, name: name, render: ({ message }) => (_jsx("p", { className: 'absolute bottom-[-20px] text-[12px] text-red-600', children: message })) })] }));
}
function ActivityDateRange({ name }) {
    const methods = useFormContext();
    return (_jsxs("div", { className: 'relative', children: [_jsxs("div", { className: 'flex h-[40px] w-[252px] items-center gap-1 rounded-[4px] border-[1px] border-blue-600 p-1', children: [_jsx("input", { type: 'date', placeholder: '\uD65C\uB3D9\uC2DC\uC791\uC77C', ...methods.register(`${name}.startDate`) }), _jsx(Text, { color: 'text-blue-600', weight: 'Regular', as: 'Label', size: 1, children: "~" }), _jsx("input", { type: 'date', placeholder: '\uD65C\uB3D9\uC885\uB8CC\uC77C', ...methods.register(`${name}.endDate`) })] }), _jsx(ErrorMessage, { errors: methods.formState.errors, name: `${name}.startDate`, render: ({ message }) => (_jsx("p", { className: 'absolute bottom-[-20px] text-[12px] text-red-600', children: message })) }), _jsx(ErrorMessage, { errors: methods.formState.errors, name: `${name}.endDate`, render: ({ message }) => (_jsx("p", { className: 'absolute bottom-[-20px] right-0 text-[12px] text-red-600', children: message })) })] }));
}
function DeleteExamButton({ onClick }) {
    return (_jsxs("button", { type: 'button', className: ' flex   h-[40px]   w-[124px] items-center justify-center gap-[8px] whitespace-nowrap rounded-[4px] border-[1px] border-blue-700 px-[22px] py-[8px] text-blue-700', onClick: onClick, children: [_jsx(Minus, {}), "\uC131\uC801 \uC0AD\uC81C"] }));
}
