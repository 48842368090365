/* eslint-disable @typescript-eslint/ban-ts-comment */

/* eslint-disable no-param-reassign */

/* eslint-disable no-shadow */
import CustomCKEditor from './EditorConfig'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import useMutation from '@simppl/react-query/useMutation';
import useSuspenseQuery from '@simppl/react-query/useSuspenseQuery';
import { postFiles } from '@simppl/repository/files';
import type { PostFilesReqType } from '@simppl/repository/files/req';
import { getUserInfo } from '@simppl/repository/user/index';
import type { FileLoader, UploadResponse, ClassicEditor } from 'ckeditor5';

import 'ckeditor5/ckeditor5.css';
import './editor.css';

type EditorProps = {
  editor?: ClassicEditor | null;
  setEditor?: (editor: ClassicEditor | null) => void;
  initialData: string;
  onChange: (data: string) => void;
  height?: number;
};

const useUserInfo = () => {
  const { data } = useSuspenseQuery({
    queryKey: ['userInfo'],
    queryFn: () => getUserInfo(),
  });

  return data;
};

export default function Editor({ initialData, editor: commentEditor, setEditor: setCommentEditor, onChange, height = 400 }: EditorProps) {
  const postFilesHook = useMutation((props: PostFilesReqType) => postFiles(props));
  const userInfo = useUserInfo();

  const customUploadAdapter = (loader: FileLoader) => {
    return {
      upload(): Promise<UploadResponse> {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          loader.file.then((file) => {
            if (!file) return;
            formData.append('file', file);
            formData.append('userId', userInfo?.id || '');
            formData.append('testType', 'editor');
            formData.append('testName', 'upload');
            formData.append('fileName', file.name);

            postFilesHook.mutate(formData, {
              onSuccess: (data) => {
                resolve({
                  default: `${data.fileUri}`,
                });
              },
            });
          });
        });
      },
    };
  };

  function uploadPlugin(editor: CustomCKEditor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader: FileLoader) => {
      return customUploadAdapter(loader);
    };
  }

  return (
    <div className='w-full'>
      <CKEditor
        editor={CustomCKEditor}
        data='<p> </p>'
        config={{
          // @ts-ignore
          extraPlugins: [uploadPlugin],
          removePlugins: ['MediaEmbed'],
          language: 'ko',
        }}
        onReady={(editor) => {
          setCommentEditor?.(editor);
          editor.setData(initialData);
          editor.editing.view.change((writer) => {
            writer.setStyle('height', `${height}px`, editor.editing.view.document.getRoot()!);
          });
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          onChange(data);
        }}
      />
    </div>
  );
}
