import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useMemo, useState } from 'react';
export const HighSchoolModeContext = createContext({
    mode: 'noData',
    modeState: null,
    changeMode: () => null,
});
export const HighSchoolModeProvider = ({ children, mode, setMode, }) => {
    const [modeState, setModeState] = useState();
    const changeMode = (mode, state) => {
        setMode(mode);
        setModeState(state);
    };
    const value = useMemo(() => ({
        mode,
        modeState,
        changeMode,
    }), [mode, modeState, changeMode]);
    return _jsx(HighSchoolModeContext.Provider, { value: value, children: children });
};
