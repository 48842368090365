import transUtcToKst from './transUtcToKst';

export function extractDateFromTimestamp(timestampString: string) {
  // ISO 8601 형식의 날짜와 시간 문자열을 년-월-일로 추출하는 정규식
  const isoTimestampPattern = /^(\d{4})-(\d{2})-(\d{2})T/;

  const match = timestampString.match(isoTimestampPattern);

  if (match) {
    const [, year, month, day] = match;
    const result = `${year}-${month}-${day}`;
    return result;
  }
  return timestampString;
}

export const getTime = ({ createdAt }: { createdAt: string }) => {
  const time = transUtcToKst({ time: createdAt });
  const year = time.getFullYear();
  const month = time.getMonth();
  const date = time.getDate();
  const hour = time.getHours();
  const minute = time.getMinutes();
  return {
    date: `${year}-${String(month + 1).padStart(2, '0')}-${String(date).padStart(2, '0')}`,
    time: `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`,
    fullTime: `${year}-${String(month + 1).padStart(2, '0')}-${String(date).padStart(
      2,
      '0',
    )} ${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`,
  };
};
