import axios from '..';
import type { GetCountriesReq, PatchCountriesReq, PostCountriesReq } from './req';
import type { GetCountriesRes, PostCountriesRes } from './res';

export const getCountries = (body: GetCountriesReq) => {
  const url = new URL(`${axios.getUri()}/countries`);
  Object.entries(body).forEach(([key, value]) => url.searchParams.append(key, String(value)));
  return axios.get<GetCountriesRes>(url.href).then((res) => res.data);
};

export const postCountries = (body: PostCountriesReq) =>
  axios.post<PostCountriesRes>('/countries', body).then((res) => res.data);

export const deleteCountries = (countryId: string) =>
  axios.delete(`/countries/${countryId}`).then((res) => res.data);

export const patchCountries = (countryId: string, body: PatchCountriesReq) =>
  axios.patch(`/countries/${countryId}`, body).then((res) => res.data);
