import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useUserAdmissions } from '@hook/useUserAdmissions';
import { Accordion, AccordionItem, Card, CardBody, CardHeader, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, } from '@nextui-org/react';
import { UserIdContext } from '@page/user/UserDetailPage';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { deleteUserAdmissions } from '@simppl/repository/admission';
import { useContext } from 'react';
export const UserAdmissionTab = () => {
    const { userId } = useContext(UserIdContext);
    const userAdmissions = useUserAdmissions({ userId });
    const admissions = userAdmissions.resultList.reduce((acc, cur) => {
        if (acc[cur.userId] === undefined)
            acc[cur.userId] = { userName: cur.userName, data: {} };
        if (acc[cur.userId] !== undefined && acc[cur.userId].data[cur.createdAt] === undefined)
            acc[cur.userId].data[cur.createdAt] = [];
        acc[cur.userId].data[cur.createdAt].push(cur);
        return acc;
    }, {});
    const queryClient = useQueryClient();
    const deleteUserAdmissionHook = useMutation((userAdmissionId) => deleteUserAdmissions(userAdmissionId));
    return (_jsxs(Table, { layout: 'auto', children: [_jsxs(TableHeader, { children: [_jsx(TableColumn, { className: 'w-[150px]', children: "\uC774\uB984" }, 'name'), _jsx(TableColumn, { className: 'w-[220px]', allowsSorting: true, children: "\uC9C0\uC6D0\uC77C\uC790" }, 'appliedAt'), _jsx(TableColumn, { className: 'w-[220px]', allowsSorting: true, children: "\uC2B9\uC778\uC5EC\uBD80" }, 'isApproved'), _jsx(TableColumn, { className: 'w-[230px]', children: "\uBC18\uB824\uC0AC\uC720" }, 'rejectionReason'), _jsx(TableColumn, { className: '', children: "\uBE44\uACE0" }, 'setting')] }), _jsx(TableBody, { items: Object.values(admissions), children: (admission) => (_jsxs(TableRow, { children: [_jsx(TableCell, { colSpan: 5, "aria-colspan": 5, className: 'p-0', children: _jsx(Accordion, { className: 'p-0', children: _jsx(AccordionItem, { indicator: _jsx(_Fragment, {}), startContent: _jsxs("div", { className: 'relative flex text-start', children: [_jsx("div", { className: 'w-[150px] px-3', children: admission.userName }), _jsx("div", { className: 'w-[220px] px-3', children: new Date(Object.keys(admission.data)[0]).toLocaleDateString('ko-KR', {
                                                    year: 'numeric',
                                                    month: 'long',
                                                    day: 'numeric',
                                                    hour: 'numeric',
                                                    minute: 'numeric',
                                                    second: 'numeric',
                                                }) }), _jsx("div", { className: 'w-[220px] px-3', children: Object.values(admission.data).reduce((acc, value) => acc + value.filter(({ isApproved }) => !isApproved).length, 0) > 0
                                                    ? '반려'
                                                    : '승인' })] }), children: Object.entries(admission.data).map(([key, value]) => (_jsxs(Card, { className: 'm-2 flex flex-col gap-4', children: [_jsx(CardHeader, { className: 'px-6 py-4', children: `지원일자: ${new Date(key).toLocaleDateString('ko-KR', {
                                                    year: 'numeric',
                                                    month: 'long',
                                                    day: 'numeric',
                                                    hour: 'numeric',
                                                    minute: 'numeric',
                                                    second: 'numeric',
                                                })}` }), _jsx(CardBody, { className: 'flex flex-col gap-8', children: value.map((admissionData) => (_jsxs("div", { children: [_jsxs(Table, { className: 'w-[900px] p-2', children: [_jsxs(TableHeader, { children: [_jsx(TableColumn, { children: "\uC9C0\uC6D0\uB300\uD559" }, 'university'), _jsx(TableColumn, { children: "\uC9C0\uC6D0\uD559\uACFC" }, 'subject'), _jsx(TableColumn, { children: "\uC2B9\uC778 \uC5EC\uBD80" }, 'subject'), _jsx(TableColumn, { children: "\uBC18\uB824 \uC0AC\uC720" }, 'subject')] }), _jsx(TableBody, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: admissionData.universityName }), _jsx(TableCell, { children: admissionData.admissionName }), _jsx(TableCell, { children: admissionData.isApproved ? '승인' : '반려' }), _jsx(TableCell, { children: admissionData.rejectionReason || '없음' })] }, admissionData.universityId) })] }), _jsxs(Table, { className: 'w-[900px] p-2', children: [_jsx(TableHeader, { children: [
                                                                        'score',
                                                                        ...Object.keys(admissionData.serializedScore),
                                                                    ].map((column) => (_jsx(TableColumn, { children: column }, column))) }), _jsx(TableBody, { children: _jsx(TableRow, { className: 'relative', children: [
                                                                            admissionData.score,
                                                                            ...Object.values(admissionData.serializedScore),
                                                                        ].map((data) => (_jsx(TableCell, { className: 'text-center', children: data }, data))) }, admissionData.userId) })] })] }, admissionData.admissionId))) })] }, key))) }) }) }), _jsx(TableCell, { className: 'hidden', children: " " }), _jsx(TableCell, { className: 'hidden', children: " " }), _jsx(TableCell, { className: 'hidden', children: " " }), _jsx(TableCell, { className: 'hidden', children: " " })] }, 'expanded')) })] }));
};
