import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/destructuring-assignment */
import { useSimulatedApplication } from '@hook/useSimulatedApplication';
import { useUniversities } from '@hook/useUniversities';
import { parseDate } from '@internationalized/date';
import { Autocomplete, AutocompleteItem, Button, DatePicker, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Radio, RadioGroup, } from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { simulatedApplications } from '@simppl/repository/simulatedApplications';
import { extractDateFromTimestamp } from '@simppl/util/date';
import { generateStorage } from '@simppl/util/storage';
import { useEffect, useState } from 'react';
export function SimulatedApplicationModal(props) {
    const storage = generateStorage();
    const { data } = useUniversities({
        // isMain null 전체 대학 조회
        name: '',
        page: 1,
        size: 100000,
    });
    const [input, setInput] = useState('');
    const [startDate, setStartDate] = useState(parseDate(extractDateFromTimestamp(new Date().toISOString())));
    const [endDate, setEndDate] = useState(parseDate(extractDateFromTimestamp(new Date().toISOString())));
    const [sort, setSort] = useState('3Y');
    const [examSort, setExamSort] = useState('exam');
    const [universityId, setUniversityId] = useState('');
    const simulatedApplication = props.mode === 'edit'
        ? useSimulatedApplication().data?.find((university) => university.simulatedApplicationId === props.simulatedApplicationId)
        : null;
    const queryClient = useQueryClient();
    const postUniversitiesHook = useMutation(simulatedApplications.post);
    const patchUniversitiesHook = useMutation(simulatedApplications.patch);
    useEffect(() => {
        const simulatedApplicationDate = JSON.parse(storage.get('simulatedApplicationDate') ?? '{}');
        if ('startDate' in simulatedApplicationDate && 'endDate' in simulatedApplicationDate) {
            setStartDate(parseDate(extractDateFromTimestamp(simulatedApplicationDate.startDate)));
            setEndDate(parseDate(extractDateFromTimestamp(simulatedApplicationDate.endDate)));
        }
        if (props.mode === 'edit' && simulatedApplication) {
            setInput(simulatedApplication.name);
            setStartDate(parseDate(extractDateFromTimestamp(simulatedApplication.startDate)));
            setEndDate(parseDate(extractDateFromTimestamp(simulatedApplication.endDate)));
            setSort(simulatedApplication.sort);
            setUniversityId(simulatedApplication.universityId);
            setExamSort(simulatedApplication.examSort);
        }
    }, [props, simulatedApplication]);
    return (_jsx(Modal, { isOpen: props.isOpen, onOpenChange: props.onOpenChange, children: _jsx(ModalContent, { children: () => (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { className: 'flex flex-col gap-1', children: props.mode === 'add' ? '전형 추가' : '전형 수정' }), _jsxs(ModalBody, { children: [_jsx(Input, { value: input, onChange: (e) => setInput(e.target.value), label: '\uC804\uD615 \uC774\uB984', labelPlacement: 'outside' }), _jsx(Autocomplete, { label: '\uB300\uD559\uC744 \uC120\uD0DD\uD574\uC8FC\uC138\uC694.', className: 'max-w-xs', onSelectionChange: (value) => {
                                    setUniversityId(value);
                                }, children: data.map((university) => (_jsx(AutocompleteItem, { value: university.universityId, children: university.name }, university.universityId))) }), _jsx(DatePicker, { label: '\uC2DC\uC791\uC77C', labelPlacement: 'outside', value: startDate, onChange: setStartDate }), _jsx(DatePicker, { label: '\uB9C8\uAC10\uC77C', labelPlacement: 'outside', value: endDate, onChange: setEndDate }), _jsxs(RadioGroup, { label: '\uC804\uD615 \uAD6C\uBD84', value: sort, onValueChange: setSort, children: [_jsx(Radio, { value: '3Y', children: "3Y" }), _jsx(Radio, { value: '12Y', children: "12Y" })] }), _jsxs(RadioGroup, { label: '\uC2DC\uD5D8 \uAD6C\uBD84', value: examSort, onValueChange: setExamSort, children: [_jsx(Radio, { value: 'exam', children: "\uC9D1\uD544" }), _jsx(Radio, { value: 'document', children: "\uC11C\uB958" })] })] }), _jsxs(ModalFooter, { children: [_jsx(Button, { color: 'danger', variant: 'light', onPress: props.onClose, children: "\uCDE8\uC18C" }), _jsx(Button, { type: 'submit', form: 'authorization', color: 'primary', onPress: () => {
                                    if (props.mode === 'add')
                                        postUniversitiesHook.mutate({
                                            name: input,
                                            startDate: startDate.toString(),
                                            endDate: endDate.toString(),
                                            sort,
                                            universityId,
                                            examSort,
                                        }, {
                                            onSuccess: () => {
                                                queryClient.invalidateQueries(['getSimulatedApplications']);
                                                props.onClose();
                                            },
                                        });
                                    else if (props.mode === 'edit')
                                        patchUniversitiesHook.mutate({
                                            name: input,
                                            startDate: startDate.toString(),
                                            endDate: endDate.toString(),
                                            sort,
                                            simulatedApplicationId: props.simulatedApplicationId,
                                            universityId,
                                            examSort,
                                        }, {
                                            onSuccess: () => {
                                                queryClient.invalidateQueries(['getSimulatedApplications']);
                                                props.onClose();
                                            },
                                        });
                                }, children: "\uC800\uC7A5" })] })] })) }) }));
}
