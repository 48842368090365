import { jsx as _jsx } from "react/jsx-runtime";
import SwitchCase from '@simppl/component/SwitchCase';
import { memo } from 'react';
import { DELEForm } from './DELE';
import { DELFForm } from './DELF';
import { HSKForm } from './HSK';
import { IELTSForm } from './IELTS';
import { TEPSForm } from './TEPS';
import { TOEFLForm } from './TOEFL';
import { TOEICForm } from './TOEIC';
export const LanguageForm = memo(({ testName }) => {
    const content = 'edit';
    return (_jsx(SwitchCase, { value: testName, caseBy: {
            TEPS: _jsx(TEPSForm, { content: content }),
            TOEFL: _jsx(TOEFLForm, { content: content }),
            TOEIC: _jsx(TOEICForm, { content: content }),
            HSK: _jsx(HSKForm, { content: content }),
            DELE: _jsx(DELEForm, { content: content }),
            DELF: _jsx(DELFForm, { content: content }),
            IELTS: _jsx(IELTSForm, { content: content }),
        } }));
});
