import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/destructuring-assignment */
import { useCategories } from '@hook/useCategories';
import { useFaqs } from '@hook/useFaqs';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, } from '@nextui-org/react';
import Editor from '@simppl/component/Editor';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { faq } from '@simppl/repository/faq';
import { ChevronDownIcon } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
export function FaqModal(props) {
    const [selectedKeys, setSelectedKeys] = useState(new Set(['']));
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const selectedValue = useMemo(() => Array.from(selectedKeys).join(', ').replace(/_/g, ' '), [selectedKeys]);
    const item = props.mode === 'edit'
        ? useFaqs({
            searchType: 'title',
            search: '',
            page: props.page,
            size: 10,
        }).posts.find((category) => category.id.toString() === props.id)
        : null;
    if (props.mode === 'edit' && !item)
        return null;
    const categories = useCategories({
        type: 'FAQ',
    });
    const queryClient = useQueryClient();
    const postItemHook = useMutation(faq.post);
    const patchItemHook = useMutation(faq.patch);
    useEffect(() => {
        if (props.mode === 'edit' && item) {
            setQuestion(item.question);
            setAnswer(item.answer);
            setSelectedKeys(new Set([item.category.id]));
        }
    }, [props, item]);
    return (_jsx(Modal, { isOpen: props.isOpen, onOpenChange: props.onOpenChange, children: _jsx(ModalContent, { children: () => (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { className: 'flex flex-col gap-1', children: props.mode === 'add' ? 'FaQ 추가' : 'FaQ 수정' }), _jsxs(ModalBody, { className: 'flex flex-col', children: [_jsxs("div", { className: 'flex flex-col', children: [_jsx("h4", { children: "\uC9C8\uBB38" }), _jsx(Editor, { initialData: item?.question || '', onChange: setQuestion, height: 100 })] }), _jsxs("div", { className: 'flex flex-col', children: [_jsx("h4", { children: "\uB2F5\uBCC0" }), _jsx(Editor, { initialData: item?.answer || '', onChange: setAnswer, height: 100 })] }), _jsxs(Dropdown, { children: [_jsx(DropdownTrigger, { className: 'hidden sm:flex', children: _jsx(Button, { endContent: _jsx(ChevronDownIcon, { className: 'text-small' }), children: selectedValue }) }), _jsx(DropdownMenu, { disallowEmptySelection: true, "aria-label": 'Table Columns', closeOnSelect: false, selectionMode: 'single', selectedKeys: selectedKeys, onSelectionChange: setSelectedKeys, children: categories.categories.children.map((categoryItem) => (_jsx(DropdownItem, { className: 'capitalize', children: categoryItem.title }, categoryItem.id))) })] })] }), _jsxs(ModalFooter, { children: [_jsx(Button, { color: 'danger', variant: 'light', onPress: props.onClose, children: "\uCDE8\uC18C" }), _jsx(Button, { type: 'submit', form: 'authorization', color: 'primary', onPress: () => {
                                    if (props.mode === 'add')
                                        postItemHook.mutate({
                                            categoryId: Array.from(selectedKeys)[0],
                                            question,
                                            answer,
                                        }, {
                                            onSuccess: () => {
                                                queryClient.invalidateQueries(['getFaqs']);
                                                props.onClose();
                                            },
                                        });
                                    else if (props.mode === 'edit' && item?.id)
                                        patchItemHook.mutate({
                                            id: item.id.toString(),
                                            question,
                                            answer,
                                            categoryId: Array.from(selectedKeys)[0],
                                        }, {
                                            onSuccess: () => {
                                                queryClient.invalidateQueries(['getFaqs']);
                                                props.onClose();
                                            },
                                        });
                                }, children: "\uC800\uC7A5" })] })] })) }) }));
}
