import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-alert */
import { BackButton } from '@component/score/common';
import { ActivityAwardInputForm, convertToDivideActivityAwardData } from '@component/score/test';
import { activityAwardTestSchemaMap } from '@schema/activityAward';
import Spacing from '@simppl/component/Spacing';
import Text from '@simppl/component/Text';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { deleteTestById, postTest } from '@simppl/repository/test';
import { useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { ActivityAwardScoreModeContext } from './Provider';
export function EditScore() {
    const { id } = useParams();
    const methods = useFormContext();
    const queryClient = useQueryClient();
    const { modeState, changeMode } = useContext(ActivityAwardScoreModeContext);
    const postTestHook = useMutation((props) => postTest('activities-awards', props));
    const deleteTestHook = useMutation((testId) => deleteTestById('activities-awards', testId));
    useEffect(() => {
        if (modeState.formName) {
            methods.setValue('edit', methods.watch(modeState.formName));
        }
    }, []);
    const onSave = async () => {
        const data = methods.getValues();
        const edit = methods.watch('edit');
        try {
            await activityAwardTestSchemaMap.validateSync({
                activityAward: [edit],
            });
            const prevData = modeState.formName
                ? data.activityAward.filter((_, index) => index !== parseInt(modeState.formName.split('.').at(-1), 10))
                : data.activityAward;
            const { awardList, activityList } = convertToDivideActivityAwardData([...prevData, edit]);
            const body = {
                userId: id,
                awardList,
                activityList,
            };
            if (awardList.length === 0 && activityList.length === 0) {
                // 성적을 입력하지 않으면 다음으로 이동하도록 허용해야 합니다.
            }
            else if (Object.keys(data).length > 0) {
                postTestHook.mutate(body, {
                    onSuccess: () => {
                        queryClient.invalidateQueries({ queryKey: ['activities-awards-testByUserId'] });
                        changeMode('viewTest');
                    },
                    onError: (error) => {
                        alert('저장중에 에러가 발생했습니다. 성적을 다시한번 확인해주세요.');
                        if (error?.response?.data?.message.length > 0) {
                            console.log(error?.response?.data?.message);
                        }
                    },
                });
            }
        }
        catch (e) {
            alert(e.message);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: 'border-Blue-Ct-2 relative flex w-full flex-col rounded-[16px] border-[1px] px-[40px] py-[32px] shadow-[0px_1px_2px_0px_rgba(20,_25,_97,_0.10),_2px_2px_3px_0px_rgba(20,_25,_97,_0.09),_4px_5px_4px_0px_rgba(20,_25,_97,_0.05),_8px_9px_5px_0px_rgba(20,_25,_97,_0.01),_12px_14px_5px_0px_rgba(20,_25,_97,_0.00)]', children: [_jsx("div", { className: 'absolute left-[42px] top-[32px]', children: _jsx(BackButton, { onClick: () => {
                                changeMode('viewTest');
                                methods.resetField('edit');
                            } }) }), _jsxs("div", { className: 'flex w-full flex-col items-center gap-[48px]', children: [_jsx(Text, { color: 'text-blue-500', weight: 'Bold', as: 'Title', size: 1, children: "\uC218\uC0C1 / \uD65C\uB3D9 \uC785\uB825" }), _jsx(ActivityAwardInputForm, { name: 'edit', removeSemester: () => {
                                    const testId = methods.watch(`activityAward.${parseInt(modeState.formName.split('.').at(-1), 10)}.id`);
                                    if (testId) {
                                        deleteTestHook.mutate(testId, {
                                            onSuccess: () => {
                                                queryClient.invalidateQueries(['activities-awards-testByUserId']);
                                                changeMode('viewTest');
                                            },
                                        });
                                    }
                                    else {
                                        queryClient.invalidateQueries(['activities-awards-testByUserId']);
                                        changeMode('viewTest');
                                    }
                                } })] })] }), _jsx(Spacing, { height: 80 }), _jsx("button", { type: 'button', className: 'h-[40px] w-full rounded-md border-[1px] border-blue-500 bg-blue-500 text-[white]', onClick: () => {
                    onSave();
                }, children: "\uC800\uC7A5" })] }));
}
