import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Spacing from '@simppl/component/Spacing';
import Text from '@simppl/component/Text';
import { useEffect, useRef } from 'react';
export function DeleteHighSchoolConfirm({ open, onClose, onConfirm, }) {
    const dialogRef = useRef(null);
    useEffect(() => {
        if (dialogRef.current === null)
            return;
        if (open) {
            dialogRef.current.showModal();
        }
        else {
            dialogRef.current.close();
        }
    }, [open]);
    useEffect(() => {
        const handler = (event) => {
            if (!dialogRef.current) {
                return;
            }
            const rect = dialogRef.current.getBoundingClientRect();
            const isInDialog = rect.top <= event.clientY &&
                event.clientY <= rect.top + rect.height &&
                rect.left <= event.clientX &&
                event.clientX <= rect.left + rect.width;
            if (!isInDialog) {
                onClose();
            }
        };
        document.addEventListener('click', handler, true);
        return () => {
            document.removeEventListener('click', handler);
        };
    }, []);
    return (open && (_jsxs("dialog", { ref: dialogRef, className: 'border-Blue-Ct-2 flex h-[204px] w-[368px] flex-col items-center justify-center rounded-[16px] border-[0.5px] px-[24px] py-[32px] shadow-[0px_1px_2px_0px_rgba(20,_25,_97,_0.10),_2px_2px_3px_0px_rgba(20,_25,_97,_0.09),_4px_5px_4px_0px_rgba(20,_25,_97,_0.05),_8px_9px_5px_0px_rgba(20,_25,_97,_0.01),_12px_14px_5px_0px_rgba(20,_25,_97,_0.00)] outline-none', children: [_jsx("svg", { width: '36', height: '36', viewBox: '0 0 36 36', fill: 'none', xmlns: 'http://www.w3.org/2000/svg', children: _jsx("path", { d: 'M16.2 23.4H19.8V27H16.2V23.4ZM16.2 9H19.8V19.8H16.2V9ZM17.982 0C8.046 0 0 8.064 0 18C0 27.936 8.046 36 17.982 36C27.936 36 36 27.936 36 18C36 8.064 27.936 0 17.982 0ZM18 32.4C10.044 32.4 3.6 25.956 3.6 18C3.6 10.044 10.044 3.6 18 3.6C25.956 3.6 32.4 10.044 32.4 18C32.4 25.956 25.956 32.4 18 32.4Z', fill: '#1F64DC' }) }), _jsx(Spacing, { height: 16 }), _jsx(Text, { color: 'text-blue-500', weight: 'Medium', as: 'Body', size: 2, className: 'whitespace-pre text-center', children: "\uC815\uB9D0 \uD574\uB2F9 \uD559\uAD50\uB97C \uC0AD\uC81C\uD558\uC2DC\uACA0\uC2B5\uB2C8\uAE4C?" }), _jsx(Spacing, { height: 24 }), _jsxs("div", { className: 'flex gap-[32px]', children: [_jsx("button", { type: 'button', onClick: onClose, className: 'h-[40px] w-[80px] rounded-[6px] border-[1px] border-blue-500 bg-blue-500 text-[white]', children: _jsx(Text, { color: 'text-white', weight: 'Medium', as: 'Label', size: 2, className: 'whitespace-pre text-center', children: "\uC544\uB2C8\uC694" }) }), _jsx("button", { type: 'button', onClick: onConfirm, className: 'h-[40px] w-[80px] rounded-[6px] border-[1px] border-blue-500 text-blue-500', children: _jsx(Text, { color: 'text-blue-500', weight: 'Medium', as: 'Label', size: 2, className: 'whitespace-pre text-center', children: "\uC608" }) })] })] })));
}
