import useSuspenseQuery from '@simppl/react-query/useSuspenseQuery';
import { getUserInfo } from '@simppl/repository/user/index';
const useAdminUserInfo = () => {
    const { data } = useSuspenseQuery({
        queryKey: ['userInfo'],
        queryFn: () => getUserInfo(),
    });
    return data;
};
export default useAdminUserInfo;
