import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ScoreBlock, ScoreForm } from '@component/score/test';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { EditScore } from './EditScore';
import { StandardTestScoreModeProvider } from './Provider';
import { ViewTest } from './ViewTest';
const standardizedTests = {
    ACT: 'ACT',
    AP: 'AP',
    'A-LEVEL': 'A-LEVEL',
    IB: 'IB',
    SAT: 'SAT',
    ETC: 'ETC',
};
const StandardPage = () => {
    const [mode, setMode] = useState('viewTest');
    const methods = useForm({
        // resolver: yupResolver(standardizedTestSchemaMap),
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
    });
    return (_jsx(FormProvider, { ...methods, children: _jsx(ScoreBlock, { list: standardizedTests, children: _jsx(StandardTestScoreModeProvider, { mode: mode, setMode: setMode, children: _jsxs(ScoreForm, { testType: 'standarized-tests', children: [mode === 'viewTest' && (_jsx(ViewTest, {})), mode === 'editScore' && _jsx(EditScore, {})] }) }) }) }));
};
export default StandardPage;
