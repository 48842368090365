import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import { useAdmissionsMajorLists } from '@hook/useAdmissionsMajorLists';
import { useAdmissionsUniversities } from '@hook/useAdmissionsUniversity';
import { BreadcrumbItem, Breadcrumbs, Button, Card, CardBody, CardHeader, Divider, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, useDisclosure, } from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { putAdmissionsMajorsWithCount } from '@simppl/repository/admission';
import { useEffect, useState } from 'react';
export default function AdmissionsPage() {
    const [type, setType] = useState('');
    const [university, setUniversity] = useState();
    const [currentPage, setCurrentPage] = useState('type');
    const selectType = (selectedType) => {
        setType(selectedType);
        setCurrentPage('university');
    };
    const selectUniversity = (selectedUniversity) => {
        setUniversity(selectedUniversity);
        setCurrentPage('subject');
    };
    useEffect(() => {
        if (currentPage === 'type') {
            setType('');
        }
        if (currentPage === 'university') {
            setUniversity(undefined);
        }
    }, [currentPage]);
    return (_jsx("div", { className: 'flex min-h-[80%] flex-col', children: _jsxs(Card, { className: 'grow', children: [_jsx(CardHeader, { children: _jsx("h1", { className: 'text-2xl', children: "\uBAA8\uC758\uC9C0\uC6D0 \uC778\uC6D0 \uAD00\uB9AC" }) }), _jsx(Divider, {}), _jsxs(CardBody, { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'flex flex-col gap-4', children: _jsx("div", { className: 'flex w-full flex-row gap-4', children: _jsx("div", { className: 'flex max-w-sm flex-1 flex-row items-center', children: _jsxs(Breadcrumbs, { onAction: (type) => setCurrentPage(type), children: [_jsx(BreadcrumbItem, { isCurrent: currentPage === 'type', children: type === '' ? '전형' : type === '3Y' ? '3년 특례' : '12년 특례' }, 'type'), type !== '' && (_jsx(BreadcrumbItem, { isCurrent: currentPage === 'university', children: university === undefined ? '대학' : university.universityName }, 'university')), university !== undefined && (_jsx(BreadcrumbItem, { isCurrent: currentPage === 'subject', children: "\uBAA8\uC9D1 \uB2E8\uC704" }, 'university'))] }) }) }) }), type === '' && _jsx(TypeTable, { selectType: selectType }), type !== '' && university === undefined && (_jsx(UniversityTable, { type: type, selectUniversity: selectUniversity })), type !== '' && university !== undefined && (_jsx(SubjectTable, { type: type, university: university }))] })] }) }));
}
function TypeTable({ selectType }) {
    return (_jsxs(Table, { children: [_jsx(TableHeader, { children: _jsx(TableColumn, { className: 'text-center', children: "\uC804\uD615" }) }), _jsxs(TableBody, { children: [_jsx(TableRow, { className: 'relative cursor-pointer', children: _jsx(TableCell, { onClick: () => selectType('12Y'), children: "12\uB144" }) }), _jsx(TableRow, { className: 'relative cursor-pointer', children: _jsx(TableCell, { onClick: () => selectType('3Y'), children: "3\uB144" }) })] })] }));
}
function UniversityTable({ type, selectUniversity }) {
    const { data } = useAdmissionsUniversities({
        // isMain null 전체 대학 조회
        name: '',
        page: 0,
        size: 10000,
    });
    if (!data?.universities)
        return null;
    return (_jsxs(Table, { children: [_jsx(TableHeader, { children: _jsx(TableColumn, { className: 'text-center', children: "\uB300\uD559 \uC774\uB984" }, 'name') }), _jsx(TableBody, { items: data.universities, children: (university) => (_jsx(TableRow, { className: 'relative cursor-pointer', children: _jsx(TableCell, { onClick: () => selectUniversity({
                            universityId: university.universityId,
                            universityName: university.name,
                        }), children: university.name }) }, university.universityId)) })] }));
}
function SubjectTable({ type, university }) {
    /**
     *  NOTE: 기획에서 핸들링 하기 쉽게, 입력시에만 총데이터에서 실 데이터를 뺀 나머지 데이터를 핸들링하도록 수정
     */
    const [selectedMajor, setSelectedMajor] = useState(undefined);
    const { isOpen, onOpenChange, onOpen } = useDisclosure();
    const { data, refetch } = useAdmissionsMajorLists({
        type,
        name: '',
        universityId: university.universityId,
        page: 0,
        size: 99999,
        isAvailable: true
    });
    useEffect(() => {
        refetch();
    }, []);
    if (!data)
        return null;
    const [totalAppliedCount, totalRealAppliedCount] = data.reduce((acc, value) => {
        acc[0] += value.appliedCount;
        acc[1] += value.realAppliedCount;
        return acc;
    }, [0, 0]);
    return (_jsxs(_Fragment, { children: [selectedMajor !== undefined && (_jsx(EditSubjectCountModal, { isOpen: isOpen, onOpenChange: onOpenChange, selectedMajor: selectedMajor })), _jsxs(Table, { isHeaderSticky: true, classNames: {
                    base: 'h-[700px] overflow-scroll',
                }, selectionMode: 'single', children: [_jsxs(TableHeader, { children: [_jsx(TableColumn, { className: 'w-[336px] text-center', children: "\uBAA8\uC9D1\uB2E8\uC704" }, 'type'), _jsx(TableColumn, { className: 'text-center', children: "\uC9C0\uC6D0\uC778\uC6D0" }, 'realAppliedCount'), _jsx(TableColumn, { className: 'text-center', children: "\uC785\uB825\uC778\uC6D0" }, 'appliedCount'), _jsx(TableColumn, { className: 'text-center', children: "\uCD5C\uC885\uC778\uC6D0" }, 'totalCount')] }), _jsx(TableBody, { items: data, children: (subject) => (_jsxs(TableRow, { className: 'relative cursor-pointer text-center', onClick: () => {
                                setSelectedMajor({
                                    admissionId: subject.admissionId,
                                    majorName: subject.majorName,
                                    universityMajorId: subject.universityMajorId,
                                    appliedCount: subject.appliedCount,
                                    realAppliedCount: subject.realAppliedCount,
                                });
                                onOpen();
                            }, children: [_jsx(TableCell, { className: 'w-[336px]', children: subject.majorName }), _jsx(TableCell, { className: 'flex-1', children: subject.realAppliedCount }), _jsx(TableCell, { className: 'flex-1', children: subject.appliedCount - subject.realAppliedCount }), _jsx(TableCell, { className: 'flex-1', children: subject.appliedCount })] }, subject.admissionId)) })] }), _jsx("div", { className: 'flex w-full p-4', children: _jsxs("div", { className: 'flex h-[40px] w-full items-center rounded-[0.5rem] bg-[#F4F4F5]', children: [_jsx("div", { className: 'w-[336px] px-3 text-start', children: "\uCD1D \uC778\uC6D0\uC218" }), _jsx("div", { className: 'flex-1 px-3 text-center', children: totalRealAppliedCount }), _jsx("div", { className: 'flex-1 px-3 text-center', children: totalAppliedCount - totalRealAppliedCount }), _jsx("div", { className: 'flex-1 px-3 text-center', children: totalAppliedCount })] }) })] }));
}
function EditSubjectCountModal({ isOpen, onOpenChange, selectedMajor, }) {
    const [input, setInput] = useState(selectedMajor.appliedCount - selectedMajor.realAppliedCount);
    const queryClient = useQueryClient();
    const putAdmissionsMajorsWithCountHook = useMutation((body) => putAdmissionsMajorsWithCount(selectedMajor.admissionId, body));
    return (_jsx(Modal, { isOpen: isOpen, onOpenChange: onOpenChange, children: _jsx(ModalContent, { children: (onClose) => (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { className: 'flex flex-col gap-1', children: selectedMajor.majorName }), _jsx(ModalBody, { children: _jsx(Input, { type: 'number', value: input, onChange: (e) => setInput(e.target.value) }) }), _jsxs(ModalFooter, { children: [_jsx(Button, { color: 'danger', variant: 'light', onPress: onClose, children: "\uCDE8\uC18C" }), _jsx(Button, { type: 'submit', form: 'authorization', color: 'primary', onPress: () => {
                                    putAdmissionsMajorsWithCountHook.mutate({
                                        count: Number(input) + selectedMajor.realAppliedCount,
                                        universityMajorId: selectedMajor.universityMajorId,
                                    }, {
                                        onSuccess: () => {
                                            queryClient.invalidateQueries(['useAdmissionsMajorLists']);
                                            onClose();
                                        },
                                    });
                                }, children: "\uC800\uC7A5" })] })] })) }) }));
}
