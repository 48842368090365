import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
const ShadowDOM = ({ html, css = '', className, bodyClassName, }) => {
    const containerRef = useRef(null);
    useEffect(() => {
        if (containerRef.current && !containerRef.current.shadowRoot) {
            // Create a shadow DOM
            const shadowRoot = containerRef.current.attachShadow({ mode: 'open' });
            // Create a style element for reset CSS
            const style = createDomElement('style', { textContent: `${css}` });
            // Append the style to the shadow DOM
            shadowRoot.appendChild(style);
            // Create a body for the content
            const content = createDomElement('body', { innerHTML: html, className: bodyClassName });
            // Append the content to the shadow DOM
            shadowRoot.appendChild(content);
        }
    }, [html, css, bodyClassName]);
    return (_jsx("div", { ref: containerRef, className: className }));
};
export default ShadowDOM;
const createDomElement = (tag, attributes) => {
    const element = document.createElement(tag);
    if (attributes)
        Object.entries(attributes).forEach(([key, value]) => {
            if (key in element)
                element[key] = value;
            else
                element.setAttribute(key, value);
        });
    return element;
};
