import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/destructuring-assignment */
import { useStandarizedSubjects } from '@hook/useStandarizedSubjects';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, } from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { standarizedSubjects } from '@simppl/repository/test';
import { ChevronDownIcon } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
const subjectMap = {
    AP: 'AP',
    'A-LEVEL': 'A-LEVEL',
    IB: 'IB',
};
export function StandarizedSubjectModal(props) {
    const [selectedKeys, setSelectedKeys] = useState(new Set(['NOTICE']));
    const [name, setName] = useState('');
    const selectedValue = useMemo(() => Array.from(selectedKeys).join(', ').replace(/_/g, ' '), [selectedKeys]);
    const item = props.mode === 'edit'
        ? useStandarizedSubjects()[props.type].find((v) => v.id === props.subjectId)
        : null;
    const queryClient = useQueryClient();
    const postItemHook = useMutation(standarizedSubjects.post);
    const patchItemHook = useMutation(standarizedSubjects.patch);
    useEffect(() => {
        if (props.mode === 'edit' && item) {
            setName(item.name);
            setSelectedKeys(new Set([props.type]));
        }
    }, [props]);
    return (_jsx(Modal, { isOpen: props.isOpen, onOpenChange: props.onOpenChange, children: _jsx(ModalContent, { children: () => (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { className: 'flex flex-col gap-1', children: props.mode === 'add' ? '표준화 과목 추가' : '표준화 과목 수정' }), _jsxs(ModalBody, { children: [_jsx(Input, { value: name, onChange: (e) => setName(e.target.value), label: '\uACFC\uBAA9\uBA85', labelPlacement: 'outside' }), _jsxs(Dropdown, { children: [_jsx(DropdownTrigger, { className: 'hidden sm:flex', children: _jsx(Button, { endContent: _jsx(ChevronDownIcon, { className: 'text-small' }), children: subjectMap[selectedValue] }) }), _jsx(DropdownMenu, { disallowEmptySelection: true, "aria-label": 'Table Columns', closeOnSelect: false, selectionMode: 'single', selectedKeys: selectedKeys, onSelectionChange: setSelectedKeys, children: ['AP', 'A-LEVEL', 'IB'].map((categoryItem) => (_jsx(DropdownItem, { className: 'capitalize', children: subjectMap[categoryItem] }, categoryItem))) })] })] }), _jsxs(ModalFooter, { children: [_jsx(Button, { color: 'danger', variant: 'light', onPress: props.onClose, children: "\uCDE8\uC18C" }), _jsx(Button, { type: 'submit', form: 'authorization', color: 'primary', onPress: () => {
                                    if (props.mode === 'add')
                                        postItemHook.mutate({
                                            name,
                                            subject: Array.from(selectedKeys)[0],
                                        }, {
                                            onSuccess: () => {
                                                queryClient.invalidateQueries(['standarizedSubjects']);
                                                props.onClose();
                                            },
                                        });
                                    else if (props.mode === 'edit' && item?.id)
                                        patchItemHook.mutate({
                                            subjectId: item.id.toString(),
                                            name,
                                            subject: Array.from(selectedKeys)[0],
                                        }, {
                                            onSuccess: () => {
                                                queryClient.invalidateQueries(['standarizedSubjects']);
                                                props.onClose();
                                            },
                                        });
                                }, children: "\uC800\uC7A5" })] })] })) }) }));
}
