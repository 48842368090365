import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AddAdmissionCompetitiveUnitModal } from '@component/admission/AddAdmissionCompetitiveUnitModal';
import { VerticalDotsIcon } from '@component/icon/VerticalDotsIcon';
import { useAdmissions } from '@hook/useAdmissions';
import { useCompetitiveUnits } from '@hook/useCompetitiveUnits';
import { useSearchAndPagination } from '@hook/useSearchAndPagination';
import { Button, Card, CardBody, CardFooter, CardHeader, Divider, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Input, Pagination, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, useDisclosure, } from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { admissionCompetitiveUnit } from '@simppl/repository/admission';
import { Search } from 'lucide-react';
import { useParams } from 'react-router-dom';
export function CompetitiveUnitAdmissionPage() {
    const { competitiveUnitId } = useParams();
    if (!competitiveUnitId)
        throw new Error('competitiveUnitId is required');
    const competitiveUnit = useCompetitiveUnits().data.find((item) => item.competitiveUnitId === competitiveUnitId);
    const admissions = useAdmissions().data.admissionList.filter((item) => item.competitiveUnitId === competitiveUnitId);
    const { isOpen, onOpenChange, onOpen, onClose } = useDisclosure();
    const { filterValue, filteredItems, items, page, setPage, onSearchChange, onClear } = useSearchAndPagination(admissions, (userPayment, value) => userPayment.admissionName?.includes(value));
    const removeFromCompetitiveUnitHook = useMutation(admissionCompetitiveUnit.patch);
    const queryClient = useQueryClient();
    return (_jsxs("div", { className: 'flex min-h-[80%] flex-col', children: [isOpen && (_jsx(AddAdmissionCompetitiveUnitModal, { isOpen: isOpen, onOpenChange: onOpenChange, onClose: onClose, competitiveUnitId: competitiveUnitId })), _jsxs(Card, { className: 'grow', children: [_jsx(CardHeader, { children: _jsx("h1", { className: 'text-2xl', children: competitiveUnit?.name }) }), _jsx(Divider, {}), _jsx(CardBody, { className: 'flex flex-col gap-4', children: _jsxs(Table, { layout: 'auto', topContent: _jsx("div", { className: 'flex flex-col gap-4', children: _jsxs("div", { className: 'flex w-full flex-row justify-between', children: [_jsx("div", { className: 'flex max-w-sm flex-1 flex-row items-center', children: _jsx(Input, { labelPlacement: 'outside', startContent: _jsx(Search, {}), value: filterValue, onClear: () => onClear(), onValueChange: onSearchChange }) }), _jsx(Button, { color: 'primary', onPress: () => {
                                                onOpen();
                                            }, children: "\uACBD\uC7C1\uB2E8\uC704\uC5D0 \uBAA8\uC9D1\uB2E8\uC704 \uCD94\uAC00" })] }) }), topContentPlacement: 'outside', children: [_jsxs(TableHeader, { children: [_jsx(TableColumn, { children: "\uC804\uD615 \uC774\uB984" }, 'userName'), _jsx(TableColumn, { children: "\uACBD\uC7C1\uB2E8\uC704 \uC774\uB984" }, 'userName'), _jsx(TableColumn, { children: "\uB300\uD559 \uC774\uB984" }, 'userName'), _jsx(TableColumn, { children: "\uD559\uACFC \uC774\uB984" }, 'userName'), _jsx(TableColumn, { children: "\uC9C0\uC6D0 \uC778\uC6D0\uC218" }, 'count'), _jsx(TableColumn, { children: "\uBE44\uACE0" }, 'setting')] }), _jsx(TableBody, { items: items, children: (item) => (_jsxs(TableRow, { className: 'relative cursor-pointer', children: [_jsx(TableCell, { children: item.simulatedApplicationName }), _jsx(TableCell, { children: item.competitiveUnitName }), _jsx(TableCell, { children: item.universityName }), _jsx(TableCell, { children: item.admissionName }), _jsx(TableCell, { children: item.appliedCount }), _jsx(TableCell, { children: _jsxs(Dropdown, { children: [_jsx(DropdownTrigger, { children: _jsx(Button, { isIconOnly: true, size: 'sm', variant: 'light', children: _jsx(VerticalDotsIcon, { className: 'text-default-300' }) }) }), _jsx(DropdownMenu, { children: _jsx(DropdownItem, { className: 'text-red-500', onPress: () => {
                                                                    removeFromCompetitiveUnitHook.mutate({ admissionId: item.admissionId, competitiveUnitId: null }, {
                                                                        onSuccess: () => {
                                                                            queryClient.invalidateQueries(['getAdmissions']);
                                                                        },
                                                                    });
                                                                }, children: "\uACBD\uC7C1\uB2E8\uC704\uC5D0\uC11C \uC0AD\uC81C" }) })] }) })] }, item.competitiveUnitId)) })] }) }), _jsx(CardFooter, { children: _jsx("div", { className: 'flex w-full flex-col items-center', children: _jsx(Pagination, { isCompact: true, showControls: true, showShadow: true, page: page, onChange: setPage, total: Math.max(Math.ceil(filteredItems.length / 10), 1) }) }) })] })] }));
}
