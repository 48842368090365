import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/destructuring-assignment */
import { useUsers } from '@hook/useUsers';
import { Autocomplete, AutocompleteItem, Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, } from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import { paymentsHistories } from '@simppl/repository/paymentHistories';
import { useState } from 'react';
export function AddItemModal(props) {
    const users = useUsers();
    const [user, setUser] = useState('');
    const postUserPaymentHook = useMutation(paymentsHistories.post);
    return (_jsx(Modal, { isOpen: props.isOpen, onOpenChange: props.onOpenChange, children: _jsx(ModalContent, { children: () => (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { className: 'flex flex-col gap-1', children: "\uC720\uC800\uC5D0\uAC8C \uC0C1\uD488 \uCD94\uAC00" }), _jsx(ModalBody, { children: _jsx(Autocomplete, { label: '\uC720\uC800\uB97C \uC120\uD0DD\uD574\uC8FC\uC138\uC694.', className: 'max-w-xs', onSelectionChange: (value) => {
                                setUser(value);
                            }, children: users.map((user) => (_jsx(AutocompleteItem, { value: user.id, children: user.name }, user.id))) }) }), _jsxs(ModalFooter, { children: [_jsx(Button, { color: 'danger', variant: 'light', onPress: props.onClose, children: "\uCDE8\uC18C" }), _jsx(Button, { type: 'submit', form: 'authorization', color: 'primary', onPress: () => {
                                    postUserPaymentHook.mutate({
                                        userId: user,
                                        itemId: props.itemId,
                                    }, {
                                        onSuccess: () => {
                                            props.onClose();
                                        },
                                    });
                                }, children: "\uC800\uC7A5" })] })] })) }) }));
}
