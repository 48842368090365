import React from 'react';

interface SwitchCaseProps<T extends string | number | symbol> {
  caseBy: Partial<Record<T, JSX.Element | null>>;
  value?: T;
  defaultComponent?: JSX.Element | null;
}
const SwitchMultiCase = <T extends string | number | symbol>({
  value,
  caseBy,
  defaultComponent = null,
}: SwitchCaseProps<T>) => {
  if (value == null) return defaultComponent;
  return caseBy[value] ?? defaultComponent;
};

type SwitchTwoCaseProps = {
  value: boolean;
  caseBy: Partial<Record<'true' | 'false', JSX.Element | null>>;
};

const SwitchTwoCase = ({ value, caseBy }: SwitchTwoCaseProps) => {
  if (value) return caseBy.true;
  return caseBy.false ?? null;
};

/**
 *
 * @param value : 값
 * @param caseBy : value가 가능한 값
 * @returns caseBy[value]
 */
const SwitchCase = <T extends string | number | symbol>({
  value,
  caseBy,
  ...rest
}: SwitchTwoCaseProps | SwitchCaseProps<T>) => {
  if (typeof value === 'boolean') {
    return (
      <SwitchTwoCase
        value={value}
        caseBy={caseBy}
      />
    );
  }
  return (
    <SwitchMultiCase
      value={value}
      caseBy={caseBy as SwitchCaseProps<T>['caseBy']}
      {...rest}
    />
  );
};

export default SwitchCase;
