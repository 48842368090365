import { jsx as _jsx } from "react/jsx-runtime";
import SwitchCase from '@simppl/component/SwitchCase';
import { ActView } from './ACT';
import { ALevelView } from './ALevel';
import { ApView } from './AP';
import { EtcView } from './ETC';
import { IbView } from './IB';
import { SatView } from './SAT';
export function StandardrizedListView({ testName, content }) {
    return (_jsx(SwitchCase, { value: testName, caseBy: {
            'A-LEVEL': _jsx(ALevelView, { content: content }),
            AP: _jsx(ApView, { content: content }),
            IB: _jsx(IbView, { content: content }),
            ETC: _jsx(EtcView, { content: content }),
            SAT: _jsx(SatView, { content: content }),
            ACT: _jsx(ActView, { content: content }),
        } }));
}
