import { NextUIProvider } from '@nextui-org/react';
import QueryClient from '@simppl/react-query/QueryClient';
import QueryClientProvider from '@simppl/react-query/QueryClientProvider';
import ReactQueryDevtools from '@simppl/react-query/ReactQueryDevtools';
import '@simppl/tailwind-config/css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { router } from './page/router';

const queryClient = new QueryClient({ defaultOptions: { queries: { staleTime: Infinity } } });
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider queryClient={queryClient}>
      <NextUIProvider>
        <RouterProvider router={createBrowserRouter(router)} />
        <ReactQueryDevtools initialIsOpen={false} />
      </NextUIProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
