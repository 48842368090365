import axios from '..';
import type {
  DeleteCategoriesReqType,
  GetCategoriesReqType,
  PatchCategoriesReqType,
  PostCategoriesReqType,
} from './req';
import type {
  DeleteCategoriesResType,
  GetCategoriesResType,
  GetCommunityCategoriesResType,
  PatchCategoriesResType,
  PostCategoriesResType,
} from './res';

export const getCategories = async (params: GetCategoriesReqType) =>
  (await axios.get<GetCategoriesResType>('/categories', { params })).data;

export const category = {
  get: (params: GetCategoriesReqType) =>
    axios.get<GetCategoriesResType>('/post-categories', { params }).then((res) => res.data),
  post: (body: PostCategoriesReqType) =>
    axios.post<PostCategoriesResType>('/categories', body).then((res) => res.data),
  patch: ({ id, ...body }: PatchCategoriesReqType) =>
    axios.patch<PatchCategoriesResType>(`/categories/${id}`, body).then((res) => res.data),
  delete: ({ id }: DeleteCategoriesReqType) =>
    axios.delete<DeleteCategoriesResType>(`/categories/${id}`).then((res) => res.data),
  community: {
    get: () =>
      axios.get<GetCommunityCategoriesResType>('/community-categories').then((res) => res.data),
  },
};
