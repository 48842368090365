import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/destructuring-assignment */
import { useUserPayments } from '@hook/useUserPayment';
import { useUsers } from '@hook/useUsers';
import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, } from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { paymentsHistories } from '@simppl/repository/paymentHistories';
import { useEffect, useState } from 'react';
export function UpdateUserPaymentModal(props) {
    const users = useUsers();
    const [count, setCount] = useState(0);
    const userPayment = useUserPayments().data.find((payment) => payment.paymentHistoryId === props.paymentHistoryId);
    useEffect(() => {
        setCount(userPayment?.count ?? 0);
    }, [userPayment]);
    const postUserPaymentHook = useMutation(paymentsHistories.patch);
    const queryClient = useQueryClient();
    return (_jsx(Modal, { isOpen: props.isOpen, onOpenChange: props.onOpenChange, children: _jsx(ModalContent, { children: () => (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { className: 'flex flex-col gap-1', children: "\uC720\uC800\uC5D0\uAC8C \uC0C1\uD488 \uCD94\uAC00" }), _jsx(ModalBody, { children: _jsx(Input, { type: 'number', labelPlacement: 'outside', placeholder: '\uC0C1\uD488\uAC1C\uC218', value: count.toString(), onChange: (e) => setCount(Number(e.target.value)) }) }), _jsxs(ModalFooter, { children: [_jsx(Button, { color: 'danger', variant: 'light', onPress: props.onClose, children: "\uCDE8\uC18C" }), _jsx(Button, { type: 'submit', form: 'authorization', color: 'primary', onPress: () => {
                                    postUserPaymentHook.mutate({
                                        paymentHistoryId: props.paymentHistoryId,
                                        count,
                                    }, {
                                        onSuccess: () => {
                                            queryClient.invalidateQueries(['userPayments']);
                                            props.onClose();
                                        },
                                    });
                                }, children: "\uC800\uC7A5" })] })] })) }) }));
}
