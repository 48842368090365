import c from '@simppl/util/c';

type CheckBoxProps = {
  checked: boolean;
  disabled?: boolean;
  width?: WidthType;
  height?: HeightType;
};

export default function CheckBox({
  checked,
  disabled,
  width = 'w-[24px]',
  height = 'h-[24px]',
}: CheckBoxProps) {
  return (
    <input
      type='checkbox'
      checked={checked}
      disabled={disabled}
      className={c(width, height)}
    />
  );
}
