import { jsx as _jsx } from "react/jsx-runtime";
import SwitchCase from '@simppl/component/SwitchCase';
import { DeleView } from './DELE';
import { DelfView } from './DELF';
import { EtcView } from './ETC';
import { HskView } from './HSK';
import { IeltsView } from './IELTS';
import { TepsView } from './TEPS';
import { ToeflView } from './TOEFL';
import { ToeicView } from './TOEIC';
export function LanguageListView({ testName, content }) {
    return (_jsx(SwitchCase, { value: testName, caseBy: {
            ETC: _jsx(EtcView, { content: content }),
            TOEIC: _jsx(ToeicView, { content: content }),
            TOEFL: _jsx(ToeflView, { content: content }),
            TEPS: _jsx(TepsView, { content: content }),
            DELE: _jsx(DeleView, { content: content }),
            DELF: _jsx(DelfView, { content: content }),
            HSK: _jsx(HskView, { content: content }),
            IELTS: _jsx(IeltsView, { content: content }),
        } }));
}
