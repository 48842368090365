import useSuspenseQuery from '@simppl/react-query/useSuspenseQuery';
import { getCountries } from '@simppl/repository/countries';
export const useCountries = (body = {}) => {
    const { data } = useSuspenseQuery({
        queryKey: ['countries'],
        queryFn: () => getCountries(body),
    });
    return {
        countries: data ?? [],
        countryNames: data?.map((country) => country.name) ?? [],
        countryNameIdMap: data?.reduce((acc, cur) => ({ ...acc, [cur.name]: cur.id }), {}),
        countryIdNameMap: data?.reduce((acc, cur) => ({ ...acc, [cur.id]: cur.name }), {}),
    };
};
