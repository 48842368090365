import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import SwitchCase from '@simppl/component/SwitchCase';
import LoginStorage from '@simppl/repository/user/LoginStorage';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
const LoginRequired = ({ children, to = '/login', replace, state, relative, }) => {
    const render = useCheckCleanToken();
    if (!render)
        return null;
    return (_jsx(SwitchCase, { value: LoginStorage.isLogin(), caseBy: {
            true: _jsx(_Fragment, { children: children }),
            false: (_jsx(Navigate, { to: to, replace: replace, state: state, relative: relative })),
        } }));
};
export default LoginRequired;
const useCheckCleanToken = () => {
    const [render, setRender] = useState(false);
    useEffect(() => {
        setRender(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return render;
};
