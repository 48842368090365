import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable jsx-a11y/no-autofocus */
import { ErrorMessage } from '@hookform/error-message';
import Text from '@simppl/component/Text';
import c from '@simppl/util/c';
import { AlertCircle, Check } from 'lucide-react';
import { useFormContext } from 'react-hook-form';
const iconType = {
    error: _jsx(AlertCircle, {}),
    success: _jsx(Check, {}),
};
export function Input({ content, name, size = 'long', inputType = 'numberInput', alterText, allowFloat = false, onBlur, }) {
    const methods = useFormContext();
    return (_jsx(_Fragment, { children: _jsxs("div", { className: c('relative flex h-[40px] items-center justify-between gap-[16px] whitespace-nowrap rounded-[4px] border-[1px] px-[16px] py-[8px]', methods.getFieldState(name).error
                ? 'border-red-600 focus:border-red-600 focus:outline-none'
                : 'border-blue-600', 
            // eslint-disable-next-line no-nested-ternary
            size === 'short' ? 'w-[252px]' : typeof size === 'number' ? `w-[${size}]` : 'w-[334px]'), children: [_jsx(Text, { color: 'text-blue-600', weight: 'Regular', as: 'Label', size: 1, children: alterText || content }), _jsx("div", { className: 'flex items-center', children: _jsx("input", { step: allowFloat ? '0.01' : '1', className: 'line-[24px] w-full text-end text-[16px] outline-none', type: inputType === 'stringInput' ? 'text' : 'number', ...methods.register(name, {
                            valueAsNumber: inputType === 'numberInput',
                        }), onBlur: () => {
                            if (inputType === 'numberInput' && !allowFloat) {
                                methods.setValue(name, parseInt(methods.getValues(name), 10));
                            }
                            onBlur?.();
                        } }) }), _jsx(ErrorMessage, { errors: methods.formState.errors, name: name, render: ({ message }) => (_jsx("p", { className: 'absolute bottom-[-20px] text-[12px] text-red-600', children: message })) })] }) }));
}
