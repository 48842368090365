import * as yup from 'yup';
import { SCHEMA_MESSAGE } from '../message';
export const ACT_format = [
    {
        subject: 'english',
        property: [
            {
                content: 'English',
                size: 'long',
                type: 'title',
            },
            {
                content: '점수',
                maxValue: 9,
                size: 'short',
                type: 'input',
                defaultValue: 0,
            },
        ],
    },
    {
        subject: 'math',
        property: [
            {
                content: 'Math',
                size: 'long',
                type: 'title',
            },
            {
                content: '점수',
                maxValue: 9,
                size: 'short',
                type: 'input',
                defaultValue: 0,
            },
        ],
    },
    {
        subject: 'reading',
        property: [
            {
                content: 'Reading',
                size: 'long',
                type: 'title',
            },
            {
                content: '점수',
                maxValue: 9,
                size: 'short',
                type: 'input',
                defaultValue: 0,
            },
        ],
    },
    {
        subject: 'scienceReading',
        property: [
            {
                content: 'ScienceReasoning',
                size: 'long',
                type: 'title',
            },
            {
                content: '점수',
                maxValue: 9,
                size: 'short',
                type: 'input',
                defaultValue: 0,
            },
        ],
    },
    {
        subject: 'totalScore',
        property: [
            {
                content: 'totalScore',
                alterText: '총점',
                size: 'long',
                type: 'title',
            },
            {
                content: '점수',
                maxValue: 36,
                size: 'short',
                type: 'input',
                defaultValue: 0,
            },
        ],
    },
];
export const ACT_schema = yup
    .object()
    .shape({
    transcript: yup.string().required('act시험의 파일을 업로드 해주세요.'),
    examDate: yup
        .string()
        .required(SCHEMA_MESSAGE.scoreMessage('act시험', '시험일자를').requiredMessage())
        .typeError(SCHEMA_MESSAGE.scoreMessage('act시험', '시험일자를').requiredMessage()),
    publishingOrganization: yup
        .string()
        .required(SCHEMA_MESSAGE.scoreMessage('act시험', '발급기관을').requiredMessage()),
    english: yup
        .number()
        .min(0, SCHEMA_MESSAGE.scoreMessage('act시험', 'english점수는').minMessage(0))
        .max(9, SCHEMA_MESSAGE.scoreMessage('act시험', 'english점수는').maxMessage(9))
        .required(SCHEMA_MESSAGE.scoreMessage('act시험', 'english점수를').requiredMessage())
        .typeError(SCHEMA_MESSAGE.scoreMessage('act시험', 'english점수를').requiredMessage()),
    math: yup
        .number()
        .min(0, SCHEMA_MESSAGE.scoreMessage('act시험', 'math점수는').minMessage(0))
        .max(9, SCHEMA_MESSAGE.scoreMessage('act시험', 'math점수는').maxMessage(9))
        .required(SCHEMA_MESSAGE.scoreMessage('act시험', 'math점수를').requiredMessage())
        .typeError(SCHEMA_MESSAGE.scoreMessage('act시험', 'math점수를').requiredMessage()),
    reading: yup
        .number()
        .min(0, SCHEMA_MESSAGE.scoreMessage('act시험', 'reading점수는').minMessage(0))
        .max(9, SCHEMA_MESSAGE.scoreMessage('act시험', 'reading점수는').maxMessage(9))
        .required(SCHEMA_MESSAGE.scoreMessage('act시험', 'reading점수를').requiredMessage())
        .typeError(SCHEMA_MESSAGE.scoreMessage('act시험', 'reading점수를').requiredMessage()),
    scienceReading: yup
        .number()
        .min(0, SCHEMA_MESSAGE.scoreMessage('act시험', 'scienceReading점수는').minMessage(0))
        .max(9, SCHEMA_MESSAGE.scoreMessage('act시험', 'scienceReading점수는').maxMessage(9))
        .required(SCHEMA_MESSAGE.scoreMessage('act시험', 'scienceReading점수를').requiredMessage())
        .typeError(SCHEMA_MESSAGE.scoreMessage('act시험', 'scienceReading점수를').requiredMessage()),
    totalScore: yup
        .number()
        .min(0, SCHEMA_MESSAGE.scoreMessage('act시험', '총 점수는').minMessage(0))
        .max(36, SCHEMA_MESSAGE.scoreMessage('act시험', '총 점수는').maxMessage(36))
        .required(SCHEMA_MESSAGE.scoreMessage('act시험', '총 점수를').requiredMessage())
        .typeError(SCHEMA_MESSAGE.scoreMessage('act시험', '총 점수를').requiredMessage()),
})
    .default(null)
    .nullable();
