import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable react/no-array-index-key */
import { Edit } from '@component/score/common';
import { AddSubjectView } from '@component/score/common/AddSubjectView';
import { ACTIVITY_format } from '@schema/activityAward/activity';
import { AWARD_format } from '@schema/activityAward/award';
import Spacing from '@simppl/component/Spacing';
import Text from '@simppl/component/Text';
import c from '@simppl/util/c';
import { useContext, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ActivityAwardScoreModeContext } from './Provider';
export function ViewTest() {
    const { changeMode } = useContext(ActivityAwardScoreModeContext);
    const [mode] = useState('list');
    const methods = useFormContext();
    return (_jsx(_Fragment, { children: _jsxs("div", { className: 'flex flex-col gap-[32px]', children: [_jsxs("div", { className: 'border-Blue-Ct-2 flex w-[886px] flex-col rounded-[16px] border-[1px] px-[51px] py-[32px] shadow-[0px_1px_2px_0px_rgba(20,_25,_97,_0.10),_2px_2px_3px_0px_rgba(20,_25,_97,_0.09),_4px_5px_4px_0px_rgba(20,_25,_97,_0.05),_8px_9px_5px_0px_rgba(20,_25,_97,_0.01),_12px_14px_5px_0px_rgba(20,_25,_97,_0.00)]', children: [_jsx("div", { className: 'flex w-full items-center justify-center gap-[15px]', children: _jsx(Text, { color: 'text-Blue-4', weight: 'Bold', as: 'Label', size: 1, children: "\uC218\uC0C1 / \uD65C\uB3D9" }) }), _jsx(Spacing, { height: '32px' }), _jsxs("div", { className: c('flex w-[784px] flex-wrap justify-between gap-[32px]', mode === 'list' && 'flex-col justify-center'), children: [methods.watch('activityAward')?.map((_, index) => {
                                    const data = methods.watch(`activityAward.${index}`);
                                    const format = 'awardDate' in data ? AWARD_format : ACTIVITY_format;
                                    return (_jsxs("div", { className: 'relative flex min-h-[248px] w-[784px] items-center justify-between rounded-[8px] border-[0.5px] border-[#B0C4E4] px-[23px] py-[24px]', onClick: () => {
                                            // 학기 추가시 마지막 커리큘럼을 추가하기 위한 로직
                                            changeMode('editScore', {
                                                formName: `activityAward.${index}`,
                                            });
                                        }, children: [_jsx("button", { className: 'absolute right-[23px] top-[24px] flex h-[40px] items-center justify-center', children: _jsx(Edit, {}) }), _jsxs("div", { className: 'flex h-full w-full items-center gap-[104px] px-[26px]', children: [_jsxs("div", { className: 'flex h-[176px] w-[176px] flex-col items-center justify-between gap-[16px] py-[26px] text-center', children: [_jsx(Text, { color: 'text-blue-600', weight: 0, as: 'Title', size: 2, children: 'awardDate' in data ? '수상' : '활동' }), _jsxs("div", { className: 'flex w-full justify-center gap-[32px]', children: [_jsxs("div", { className: 'flex flex-col items-center gap-[8px]', children: [_jsx(Text, { className: 'whitespace-nowrap', color: 'text-Blue-4', weight: 0, as: 'Body', size: 1, children: "\uAD50\uB0B4\uC678 \uAD6C\uBD84" }), _jsx("div", { className: 'h-[2px] w-[46px] bg-[#E6ECF6]' }), _jsx(Text, { color: 'text-blue-600', weight: 0, as: 'Title', size: 2, children: format.division[data.division] })] }), _jsxs("div", { className: 'flex flex-col items-center gap-[8px]', children: [_jsx(Text, { color: 'text-Blue-4', weight: 0, as: 'Body', size: 1, children: "\uAD50\uACFC \uAD6C\uBD84" }), _jsx("div", { className: 'h-[2px] w-[46px] bg-[#E6ECF6]' }), _jsx(Text, { color: 'text-blue-600', weight: 0, as: 'Title', size: 2, children: format.type[data.type] })] })] })] }), _jsx(TextArea, { content: data.content })] })] }, index));
                                }), _jsx(AddSubjectView, { title: '\uC218\uC0C1 / \uD65C\uB3D9 \uCD94\uAC00', mode: mode, onClick: () => {
                                        // 학기 추가시 마지막 커리큘럼을 추가하기 위한 로직
                                        changeMode('editScore', {
                                            formName: `test.${methods.watch(`test`)?.length}`,
                                        });
                                    } })] })] }), _jsx(Spacing, { height: '32px' })] }) }));
}
function TextArea({ content }) {
    return (_jsx("div", { className: 'relative h-[176px] w-[360px]', children: _jsx("textarea", { className: 'font-Pretendard-Regular h-[176px] w-full resize-none rounded-[4px] border-[0.5px] border-blue-600 bg-white p-[16px_24px_32px_16px]', maxLength: 300, value: content, disabled: true }) }));
}
